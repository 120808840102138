export const defaultPlotline = {
    color: 'black',
    dashStyle: 'dash',
    width: 1
  };
  
  export const chartColors = [
    '#0d4ea6',
    '#e86427',
    '#279c9c',
    '#d9a514',
    '#ac71f0',
    '#23254d',
    '#52a31d',
    '#cc1d92',
    '#48b8f0',
    '#077d55',
    '#7a909e',
    '#bf4815',
    '#54341f',
    '#4ebfb9',
    '#067a91',
    '#545fe8',
    '#102e2d',
    '#2e5c0e'
  ];
  
  export const CHART_MESSAGES = {
    METRIC_UNAVAILABLE: 'METRIC_UNAVAILABLE',
    METRICS_UNAVAILABLE: 'METRICS_UNAVAILABLE',
    SOME_METRIC_UNAVAILABLE: 'SOME_METRIC_UNAVAILABLE'
  };