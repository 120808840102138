import React from 'react';
import useUserMaintenance from '../../../context';
import ProfileGroup from './ProfileGroup';
import { profileConfig } from './config';
import translator from '../../../../../../services/translator';
import './index.scss';
import SsoEntitlementsGroup from './SsoEntitlementsGroup';

const { translate: t } = translator;

const Profile = () => {
  const {
    storeValues: { isInternalUser },
  } = useUserMaintenance();

  const {
    thirdPartyPartnerSsoEntitlementsConfig,
    identifyingInformationConfig,
    contactInformationConfig,
    setupConfig,
    functionalityConfig,
    accessConfig,
    changeManagementDetails,
  } = profileConfig;


  return (
    <React.Fragment>
      <div className='main-header'>{t('tkUserProfile')}</div>
      <div className='profile-main-container'>
        <div className='profile-main-container__left'>
          <ProfileGroup data={identifyingInformationConfig} groupTitle='tkIdentifyingInformation' testId='identifying-information' />
          <ProfileGroup data={contactInformationConfig} groupTitle='tkContactInformation' testId='contact-information' />
          <SsoEntitlementsGroup data={thirdPartyPartnerSsoEntitlementsConfig} testId='sso-entitlement'/>
        </div>
        <div className='profile-main-container__right'>
          <ProfileGroup data={setupConfig} groupTitle='tkSetup' testId='setup' />
          {isInternalUser && <ProfileGroup data={functionalityConfig} groupTitle='tkFunctionality' testId='functionality' />}
          <ProfileGroup data={accessConfig} groupTitle='tkAccess' testId='access' />
          <ProfileGroup data={changeManagementDetails} groupTitle='tkChangeManagementDetails' testId='change-management-details' />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Profile;
