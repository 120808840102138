import { useCallback } from 'react';
import useFetch from '../useFetch';
import useUserMaintenance from '../../context';
import { UPDATE_CREATE_USER_DETAILS_FUNDS } from '../../actions';
import endPointsMapper from '../../../../../configs/endPointsMapper';
import useLoader from '../useLoader';

export default function useGetOrganizationAllFunds() {
  const { dispatch: ctxDispatch } = useUserMaintenance();

  const organizationAllFundsEndpoint = structuredClone(endPointsMapper.FETCH_ORGANIZATION_ALL_AVAILABLE_FUNDS);

  const onSuccess = useCallback((response) => {
    ctxDispatch({ type: UPDATE_CREATE_USER_DETAILS_FUNDS, payload: { funds: response } });
  }, []);

  const { doFetch, loading } = useFetch(organizationAllFundsEndpoint, onSuccess);

  useLoader(loading);

  async function fetchOrganizationAllFunds({ organizationId }) {
    organizationAllFundsEndpoint.path = `${organizationAllFundsEndpoint.path}/${organizationId}/allAvailableFunds`;
    await doFetch();
  }

  return { fetchOrganizationAllFunds };
}
