import React, {useCallback, useEffect, useMemo} from 'react';
import { connect, useDispatch } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import DOMPurify from 'dompurify';
import PropTypes from 'prop-types';
import translator from '../../../../services/translator';
import history from '../../../../utils/history';
import { getAppRoute } from '../../../../utils/commonUtils';
import AppConstants, { SnackbarTypes } from '../../../../constants/appConstants';
import errorTypes from '../../../../error/errorType';
import {CHART_MIN_START_DATE, DATA_TYPE, FUND_TRACKER, REBATE_ELIGIBLE_METRICS} from '../../constants';
import { SHOW_ERROR_ON } from '../../../../constants/pageConstants';
import {getAsOfDate, getChartZoomLevel, getShareclassesAndBenchmarks, updateChartOptionsItems, verifyThresholds} from '../../utils';
import { useAsync } from '../../utils/hooks';
import { fundTrackerStateProps } from '../../store/helpers';
import { getUTCDateFromMillis } from '../../../../utils/dateFormatter';
import { setGlobalError } from '../../../../actions/app';
import { fetchAllPerformanceData } from '../../services';
import StockChart from '../../../../components/core/Charts/Stockchart';
import { CHART_MESSAGES } from '../../../../components/core/Charts/constants';
import { getChartMessage, getMinMaxDates, getTodayMidnight } from '../../../../components/core/Charts/helpers';
import { setSnackbarMessages, updateChartExtremes } from '../../store/fund-tracker-slice';

const { translate: t } = translator;

const HighStock = ({
  allShareClassData, chartOptions, metrics = [], primaryProduct, selection, thresholdList = [],
  metrics: selectedMetrics, benchmarks: selectedFundsOrBenchMarks = [], isFetchingChartData = false,
  snackbarMessages = [], isApplyRebates, iMoneyAverageSelected, chartExtremes = []
}) => {
  const chartDispatch = useDispatch();
  const { run } = useAsync(chartDispatch);
  const currentMetric = metrics[0]?.label;
  const isBenchMarksCompareMode = selection === FUND_TRACKER.BENCHMARKS;

  /** help fetch performance data for clicked point's date */
  const onPointClicked = ({ selectedDate }) => {
    const asOfDate = selectedDate || getAsOfDate(chartOptions?.series[0]?.data);
    const { benchmarks, shareclasses } = getShareclassesAndBenchmarks(allShareClassData);
    fetchAllPerformanceData({ asOfDate, benchmarks, chartDispatch, run, shareclasses });
  };

  /** fn is a callback that updates snackbar messages */
  const snackbarMessageHandler = snackbarPayload => {
    chartDispatch(setSnackbarMessages(snackbarPayload));
  };

  const isMetricsCompareMode = useMemo(() =>
      selection === FUND_TRACKER.METRICS
    , [selection]);

  const isChartSeriesEmpty = () => (
    chartOptions?.series && chartOptions?.series.every(({data}) => isEmpty(data))
  );

  useEffect(() => {
    const seriesLength = chartOptions?.series?.length;
    const seriesWithNoData = chartOptions?.series?.filter(d => d.noData) || [];

    if (seriesLength === 0 || (seriesLength !== 0 && seriesLength === seriesWithNoData.length)) {
      chartDispatch(updateChartExtremes({ min: Date.parse(CHART_MIN_START_DATE), max: getTodayMidnight() }));
    } else {
      const minMaxDates = chartOptions?.series
        .filter(({ noData }) => !noData)
        .reduce(getMinMaxDates, { minDate: undefined, maxDate: undefined });
      chartDispatch(updateChartExtremes({ min: minMaxDates.minDate, max: minMaxDates.maxDate }));
    }

    // show snackbar where there are series w no data and at least one has data
    if (seriesWithNoData.length > 0 && seriesWithNoData.length < seriesLength) {
      const [metric] = seriesWithNoData.map(({id}) => {
        const idParts = id.split('-');
        return selectedMetrics.find(({value}) => value === idParts[idParts.length - 1]);
      });
      const isSingleMetrics = selectedMetrics.length === 1;
      const message = getChartMessage(CHART_MESSAGES.SOME_METRIC_UNAVAILABLE, metric?.label, isSingleMetrics);
      if (message) {
        snackbarMessageHandler({ messages: [{ type: SnackbarTypes.WARNING, message }] });
      } else {
        snackbarMessageHandler({ messages: [] });
      }
    } else if (snackbarMessages.length > 0) {
      snackbarMessageHandler({ messages: [] });
    }
  }, [chartOptions?.series]);


  /** fn updates the zoom range when any of the rangeSelector buttons are clicked */
  const onChangeZoomRange = event => {
    const selectedZoomOption = chartOptions?.rangeSelector.selected;
    const {
      rangeSelectorButton: { text: zoomPreset }
    } = event;
    const currentZoomOptionSelected = getChartZoomLevel(zoomPreset);
    if (selectedZoomOption !== currentZoomOptionSelected) {
      updateChartOptionsItems(chartDispatch, { rangeSelector: { selected: currentZoomOptionSelected } });
    }
  };

  /** fn updates min and max xAxis bounds given start/end dates for the chart */
  const onChangeDateRange = event => {
    if (chartOptions?.series && chartOptions?.series.length >= 1) {
      const { min: fromDate, max: toDate } = event;
      const fromDateStr = getUTCDateFromMillis(fromDate);

      const isDataAvailable = chartOptions?.series.every(({ data }) =>
        data.some(([seriesDate, dataPoint]) => getUTCDateFromMillis(seriesDate) === fromDateStr && dataPoint)
      );

      // overrides for chart options
      const overrideSelector = { selected: -1 };
      const xAxisNew = { type: 'datetime', min: fromDate, max: toDate };

      updateChartOptionsItems(chartDispatch, {
        xAxis: xAxisNew,
        rangeSelector: overrideSelector
      });

      if (!isDataAvailable) {
        chartDispatch(
          setGlobalError({
            error: { code: errorTypes.BUSINESS_FAILURE },
            isPageLoaded: true,
            showErrorOn: SHOW_ERROR_ON.SNACKBAR,
            errorMessage: 'tkNoDataForSelectedDate'
          })
        );
      }
    }
  };

  const redirectToFundFinder = e => {
    if (e.target && e.target.className === 'link__primary') {
      history.push(getAppRoute(AppConstants.FUND_FINDER_ROUTE));
    }
  };

  const thresholdParam = useMemo(() => {
    if (!iMoneyAverageSelected) {
      const funds = selectedFundsOrBenchMarks.filter(({type}) => (type === DATA_TYPE.FUND));
      const [{value: metric} = {}] = selectedMetrics;
      const applyRebates = REBATE_ELIGIBLE_METRICS.includes(metric) && isApplyRebates;
      return verifyThresholds(
        primaryProduct,
        funds,
        selectedMetrics,
        thresholdList,
        isBenchMarksCompareMode,
        applyRebates
      );
    } else {
      return {isAllThresholdHasSameLimits: false};
    }
  }, [isBenchMarksCompareMode, iMoneyAverageSelected, primaryProduct, selectedFundsOrBenchMarks, selectedMetrics, thresholdList, isApplyRebates]);

  const getPlotLineConfig = useCallback((color, threshold, boundLabel) => ({
    color,
    value: threshold,
    dashStyle: 'shortdash',
    width: 3,
    label: {
      useHTML: true,
      text: `<div style="color: white; background: ${color}; padding: 0 10px 0 10px">${boundLabel}: ${threshold}</div>`,
      x: -2
    }
  }));

  /* in benchmark compare mode, fn helps update plotlines given changes in thresholds */
  const updateThresholdPlotLines = (chartYaxis) => {
    const {isAllThresholdHasSameLimits, baseLowerThreshold, baseUpperThreshold} = thresholdParam;
    const isApplyThreshold = isAllThresholdHasSameLimits && !isMetricsCompareMode && (baseLowerThreshold || baseUpperThreshold);
    /* handle updates to threshold configs on chart options */
    if (Array.isArray(chartYaxis)) {
      const plotLines = [];
      if (isApplyThreshold && !isChartSeriesEmpty()) {
        if (baseLowerThreshold) {
          plotLines.push(getPlotLineConfig('#e86427', baseLowerThreshold, t('tkLowerBound')));
        }
        if (baseUpperThreshold) {
          plotLines.push(getPlotLineConfig('#077d55', baseUpperThreshold, t('tkUpperBound')));
        }
      }
      return chartYaxis.map(item => {
        if (item.opposite) {
          return {...item, plotLines};
        }
        return item;
      });
    }
    return chartYaxis;
  };

  const updatedChartOptions = {
    ...chartOptions,
    yAxis: updateThresholdPlotLines(chartOptions?.yAxis, chartOptions?.series),
    rangeSelector: {
      selected: isChartSeriesEmpty() ? 1 : chartOptions?.rangeSelector.selected
    }
  };
  let missingDataMsg = null;
  if (updatedChartOptions?.series?.every(item => item.noData)) {
    missingDataMsg =
      metrics.length > 1
        ? getChartMessage(CHART_MESSAGES.METRICS_UNAVAILABLE, metrics)
        : getChartMessage(CHART_MESSAGES.METRIC_UNAVAILABLE, currentMetric);
  }

  return (
    <>
      <StockChart
        options={updatedChartOptions}
        onPointClicked={onPointClicked}
        chartMessages={{ missingDataMsg }}
        skipMessage={!primaryProduct?.value}
        isLoading={isFetchingChartData}
        updateZoomRange={onChangeZoomRange}
        updateDateRange={onChangeDateRange}
        isMetricsCompareMode={isMetricsCompareMode}
        thresholdParam={thresholdParam}
        chartExtremes={chartExtremes}
      />
      {!primaryProduct?.value && (
        <div
          onClick={redirectToFundFinder}
          className='fund-selection-missing'
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(t('tkSelectFund')) }}
        />
      )}
    </>
  );
};

HighStock.propTypes = {
  allShareClassData: PropTypes.array,
  benchmarks: PropTypes.array,
  chartOptions: PropTypes.object,
  isFetchingChartData: PropTypes.bool,
  metrics: PropTypes.array,
  primaryProduct: PropTypes.string,
  selection: PropTypes.string,
  snackbarMessages: PropTypes.array,
  thresholdList: PropTypes.array,
  isApplyRebates: PropTypes.bool,
  iMoneyAverageSelected: PropTypes.bool,
  chartExtremes: PropTypes.array
};

export default connect(fundTrackerStateProps)(HighStock);
