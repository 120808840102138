import React from 'react';
import { Text } from '@gs-ux-uitoolkit-react/text';

import { EXTERNAL_LINK_SKIP_ATTRIBUTE } from '../../../../../constants/pageConstants';
import { currencyFormatter } from '../../../../../utils/portfolioFormatter';
import { DATA_TYPE } from '../../../constants';
import { getData, getTimeZoneAbbr, getDecimalRoundOff } from './utils';
import { composeTranslatedLabels } from '../../../../../utils/stringUtils';

const offShoreGSFundsDocumnets = [
  { name: 'Monthly Fund Summary', mandatory: true },
  { name: 'Prospectus', mandatory: true },
  { name: 'Fund Literature', mandatory: true },
  { name: 'KIID', mandatory: false },
  { name: 'Ex-Ante Cost And Charges', mandatory: false }
];

const onShoreGSFundsDocumnets = [
  { name: 'Monthly Fund Summary', mandatory: true },
  { name: 'Summary Prospectus', mandatory: true },
  { name: 'Prospectus', mandatory: true },
  { name: 'Annual Report', mandatory: true },
  { name: 'Semi-Annual Report', mandatory: true },
  { name: 'SAI', mandatory: true },
  { name: 'Weekly Holdings', mandatory: false },
  { name: 'Monthly Holdings', mandatory: false },
  { name: 'Fund Literature', mandatory: false }
];
const notPresent = 'Unavailable';

const getPerformanceCard = isBenchmark => {
  return {
    title: 'tkPerformance',
    id: 'performance',
    asOfDate: isBenchmark ? 'performance.asOfDate' : 'shareclassPerformance.performanceAsOfTime',
    innerConfig: [
      {
        key: 'shareclassPerformance.rate',
        label: 'tkDailyFactor',
        isLabelLink: true
      },
      {
        key: isBenchmark ? 'performance.yield1Day' : 'shareclassPerformance.yield.amount',
        label: 'tkYield',
        isLabelLink: true,
        formatter: (data, key) => {
          return getData(data, key);
        }
      },
      {
        key: isBenchmark ? 'performance.yield7DayCurrent' : 'shareclassPerformance.curYield7Day.amount',
        label: 'tkCurYield7Day',
        isLabelLink: true
      },
      {
        key: isBenchmark ? 'performance.yield7DayEffective' : 'shareclassPerformance.effYield7Day.amount',
        label: 'tkEffYield7Day',
        isLabelLink: true
      },
      {
        key: isBenchmark ? 'performance.yield30Day' : 'shareclassPerformance.yield30Day.amount',
        label: 'tkYield30Day',
        isLabelLink: true
      },
      {
        key: 'shareclassPerformance.adjustedOneDayYield',
        label: 'tk1DayAdjYield',
        isLabelLink: true,
        formatter: (data, key) => {
          return getData(data, key);
        },
        isAdjustedYield: true
      },
      {
        key: 'shareclassPerformance.adjustedSevenDayCurYield',
        label: 'tk7DayAdjCurYield',
        isLabelLink: true,
        isAdjustedYield: true
      },
      {
        key: 'shareclassPerformance.adjustedSevenDayEffYield',
        label: 'tk7DayAdjEffYield',
        isLabelLink: true,
        isAdjustedYield: true
      },
      {
        key: 'shareclassPerformance.adjustedThirtyDayYield',
        label: 'tk30DayAdjYield',
        isLabelLink: true,
        isAdjustedYield: true
      }
    ]
  };
};

const getInformationCard = isBenchmark => {
  return {
    title: 'tkFundInformation',
    id: 'fundInformation',
    innerConfig: [
      {
        subCards: {
          title: 'tkAssets',
          id: 'assets',
          asOfDate: isBenchmark ? 'assets.asOfDate' : 'shareclassPerformance.totalAssetsAsOfTime',
          config: [
            {
              key: 'shareclassPerformance.portfolioAssets.amount',
              label: 'tkTotalPortAssets',
              formatter: data => {
                let portfolioAssetAmount = getData(data, 'shareclassPerformance.portfolioAssets.amount');
                portfolioAssetAmount = getDecimalRoundOff(portfolioAssetAmount, 2);
                const portfolioAssetCurrency = getData(data, 'shareclassPerformance.portfolioAssets.currency');
                const { value } = currencyFormatter({ value: portfolioAssetAmount, fundCcy: portfolioAssetCurrency });
                return portfolioAssetAmount === notPresent ? notPresent : value;
              },
              isLabelLink: true
            }
          ]
        }
      },
      {
        subCards: {
          title: 'tkLiquidity',
          id: 'liquidity',
          asOfDate: !isBenchmark && 'shareclassPerformance.liquidityAsOfDate',
          config: [
            {
              key: 'shareclassPerformance.liqDly.amount',
              label: 'tkLiquidAssetsDaily',
              isLabelLink: true
            },
            {
              key: 'shareclassPerformance.ligWkly.amount',
              label: 'tkLiquidAssetsWeekly',
              isLabelLink: true
            }
          ]
        }
      },
      {
        subCards: {
          title: 'tkDuration',
          id: 'duration',
          asOfDate: isBenchmark ? 'duration.asOfDate' : 'shareclassPerformance.durationAsOfTime',
          config: [
            {
              key: isBenchmark ? 'duration.wam' : 'shareclassPerformance.wam',
              label: 'tkWAMDays',
              isLabelLink: true
            },
            {
              key: isBenchmark ? 'duration.wal' : 'shareclassPerformance.wal',
              label: 'tkWALDays',
              isLabelLink: true
            }
          ]
        }
      }
    ]
  };
};

const getFactCard = () => {
  return {
    title: 'tkFundFacts',
    id: 'fundFacts',
    innerConfig: [
      {
        key: 'shareclass.fundDetails.fundSchedules.schedule.timeOfDay',
        label: 'tkNavPricingSchedule',
        formatter: data => {
          const fundSchedules = getData(data, 'shareclass.fundDetails.fundSchedules');
          if (fundSchedules !== notPresent && fundSchedules.length > 0) {
            return fundSchedules.map(fundSchedule => {
              const { schedule } = fundSchedule;
              if (schedule && schedule.timeOfDay) {
                return <div>{`${schedule.timeOfDay} ${getTimeZoneAbbr(schedule.timeZone)}`}</div>;
              }
              return <div>{notPresent}</div>;
            });
          }
          return <div>{notPresent}</div>;
        }
      },
      {
        key: 'shareclass.cusip',
        label: 'tkCUSIP'
      },
      {
        key: 'shareclass.shareClassDetails.displayCode',
        label: 'tkFund#'
      },
      {
        key: 'shareclass.shareClassDetails.cutOffs.subsCutoff',
        label: 'tkPurchaseCutoff',
        formatter: data => {
          const cutOff = getData(data, 'shareclass.shareClassDetails.cutOffs.subsCutoff');
          const timezone = getData(data, 'shareclass.shareClassDetails.cutOffs.cutoffTimeZone');
          if (cutOff !== notPresent) {
            return `${cutOff} ${timezone ? getTimeZoneAbbr(timezone) : ''}`;
          }
          return notPresent;
        }
      },
      {
        key: 'shareclass.shareClassDetails.cutOffs.redCutoff',
        label: 'tkRedemptionCutoff',
        formatter: data => {
          const cutOff = getData(data, 'shareclass.shareClassDetails.cutOffs.redCutoff');
          const timezone = getData(data, 'shareclass.shareClassDetails.cutOffs.cutoffTimeZone');
          if (cutOff !== notPresent) {
            return `${cutOff} ${timezone ? getTimeZoneAbbr(timezone) : ''}`;
          }
          return notPresent;
        }
      },
      {
        key: 'shareclass.shareClassDetails.settlementCycle',
        label: 'tkSettlementCycle',
        formatter: data => {
          const settlementCycle = getData(data, 'shareclass.shareClassDetails.settlementCycle');
          if (settlementCycle !== notPresent) {
            let value = 'T';
            if (settlementCycle !== 0) {
              value = `${value}${settlementCycle > 0 ? `+${settlementCycle}` : settlementCycle}`;
            }
            return value;
          }
          return notPresent;
        }
      },
      {
        key: 'shareclass.fundDetails.fundType',
        label: 'tkFundType'
      },
      {
        key: 'shareclass.fundDetails.navType',
        label: 'tkNAVType'
      }
    ]
  };
};

/** when displaying a benchmark, the resources card will show no available data */
const getResourcesCard = (isBenchmark) => {
  return {
    title: 'tkResources',
    id: 'resources',
    innerConfig: [
      {
        key: 'shareclass.documentLinks',
        label: 'tkDailyFactor',
        labelFormatter: data => {
          const docLinks = getData(data, 'shareclass.documentLinks');
          const taId = getData(data, 'shareclass.fundDetails.taInfo.gtamTaId');

          let fundsDocLinks = onShoreGSFundsDocumnets;
          if (taId === 2) {
            fundsDocLinks = offShoreGSFundsDocumnets;
          }
          const docNameMap = {};
          if (docLinks !== notPresent) {
            docLinks.forEach(link => {
              const { linkName, path } = link;
              docNameMap[linkName.toLowerCase()] = path;
            });
          }
          return (
            <div>
              {isBenchmark ? (
                <Text>{composeTranslatedLabels(['tkNo', 'tkResource', 'tkAvailable'], { sentenceCase: true })}</Text>
              ) : (
                fundsDocLinks.map(link => {
                  const { name, mandatory } = link;
                  const path = docNameMap[name.toLowerCase()];
                  if (mandatory) {
                    return (
                      <div key={`${name}`}>
                        {path ? (
                          <a
                            style={{ color: '#186ADE' }}
                            href={path}
                            target={'_blank'}
                            {...{ [EXTERNAL_LINK_SKIP_ATTRIBUTE]: true }}>
                            {name}
                          </a>
                        ) : (
                          name
                        )}
                      </div>
                    );
                  } else if (path) {
                    return (
                      <div key={`${name}`}>
                        <a
                          style={{ color: '#186ADE' }}
                          href={path}
                          target={'_blank'}
                          {...{ [EXTERNAL_LINK_SKIP_ATTRIBUTE]: true }}>
                          {name}
                        </a>
                      </div>
                    );
                  }
                  return null;
                })
              )}
            </div>
          );
        },
        formatter: () => {
          return <div />;
        }
      }
    ]
  };
};

export const getCardConfigurations = type => {
  const isBenchmark = type === DATA_TYPE.BENCHMARK;
  return [getPerformanceCard(isBenchmark), getInformationCard(isBenchmark), getFactCard(), getResourcesCard(isBenchmark)];
};
