import { DATA_TYPE } from '../constants';

export const getProductName = (productType, productName, fundDisplay, isCompareMode, compareFundsText) => {
  if (isCompareMode) return compareFundsText;

  if (productType !== DATA_TYPE.BENCHMARK) {
    return (fundDisplay && `${productName} (${fundDisplay})`) || productName;
  }

  return productName;
};

export const getProductDisplayedDetails = product => {
  let name = product.shareclass?.longName;
  const {
    type,
    nav: { value = '--', navChange = '--', asOfDate = '--' } = {},
    shareclass: { fundDetails: { navType } = {}, shareClassDetails: { isInstitutional } = {} } = {}
  } = product;

  if (product.type === DATA_TYPE.BENCHMARK) {
    const { name: benchName } = product;
    name = benchName;
  }

  return {
    type,
    nav: { value, navChange, asOfDate },
    navType,
    name,
    isInstitutional
  };
};

/**
 * determines whether the given id is not the primary product or already selected for compare
 */
export const canItemBeSearched = (id, primaryProductId, selectedBenchmarks) => {
  const idIsPrimary = id === primaryProductId;
  const idNotSelected = !selectedBenchmarks.some(({ value }) => value === id);
  return !idIsPrimary && idNotSelected;
}
