import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Label } from '@gs-ux-uitoolkit-react/label';
import { Select } from '@gs-ux-uitoolkit-react/select';
import cloneDeep from 'lodash/cloneDeep';
import { Conditional } from '../../../../components/core/Conditional';
import './index.scss';

const SearchMetrics = ({ title, onChange, onSelectCompareItem, allMetricOptions }) => {
  const selectedMetrics = useSelector(state => state.modules.fundTracker?.metrics || []);
  const [primaryMetricsItem, compareMetricsItem] = selectedMetrics;
  const initiallySelectedItem = selectedMetrics.length > 1 ? compareMetricsItem : {};
  const [metrics, setMetrics] = useState(initiallySelectedItem);

  useEffect(() => {
    if (Object.keys(initiallySelectedItem).length > 0 && metrics.value !== initiallySelectedItem.value) {
      setMetrics(initiallySelectedItem);
    }
  }, [initiallySelectedItem]);

  const handleSelection = useCallback(({selectedOption: selectedMetric}) => {
    onChange && onChange();
    if (selectedMetric) {
      setMetrics(selectedMetric);
      onSelectCompareItem('metrics', selectedMetric);
    }
  });

  const metricsOptions = useMemo(() => {
    return cloneDeep(allMetricOptions)
      .map(option => {
        option.items.map(item => {
          if (item.label === primaryMetricsItem.label) {
            item.disabled = true;
          }
          return item;
        });
        return {
          label: option.label,
          options: option.items.filter(({isHideFromUI}) => !isHideFromUI)
        };
      });
  }, [allMetricOptions, primaryMetricsItem]);

  return (
    <div className='metrics'>
      <Conditional condition={!!title}>
        <Label>{title}</Label>
      </Conditional>
      <Select
        selectedValue={metrics.value}
        options={metricsOptions}
        className='metrics__items'
        onChange={handleSelection}
        clearable={false}
        data-testid='metrics-select-testid'
      />
    </div>
  );
};

SearchMetrics.propTypes = {
  title: PropTypes.string,
  onChange: PropTypes.func,
  onSelectCompareItem: PropTypes.func,
  allMetricOptions: PropTypes.array
};

export default SearchMetrics;
