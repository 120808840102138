import React from 'react';
import PropTypes from 'prop-types';
import {dateFormatter} from '../../../utils/portfolioFormatter';
import Repeater from '../../core/Repeater';
import {Conditional} from '../../core/Conditional';
import Button from '../../core/Button';
import history from '../../../utils/history';
import {getAppRoute} from '../../../utils/commonUtils';
import AppConstants from '../../../constants/appConstants';
import translator from "../../../services/translator";

const {translate: t} = translator;

const Item = ({item, fundData, labels}) => {
  const {field, headerName, className} = item;
  let fieldData = fundData[field];
  const navAsOfTime = dateFormatter(fundData.navAsOfTime);
  const asOfTime = (navAsOfTime) ? `${labels.tkAsOf}${navAsOfTime}` : '';
  const dateTimeValue = (field === 'recentNavTime' && fieldData) ? `${labels.tkAsOf}${fieldData} ${navAsOfTime}` : asOfTime;
  fieldData = fundData[field] || labels.tkUnavailable;
  if (field === 'navAsOfTime' || field === 'recentNavTime') {
    fieldData = dateTimeValue;
  }

  return (
    <Conditional condition={field !== 'fundName'}>
      <div className={className ? `fundFactView__item-${className}` : 'fundFactView__item'}>
        <span>
          <Conditional condition={headerName !== '' || headerName !== undefined}><label className='fundFactView__label'>{labels[headerName]}</label></Conditional>
          <span> {fieldData}</span>
        </span>
      </div>
    </Conditional>
  );
};

Item.propTypes = {
  labels: PropTypes.object,
  item: PropTypes.object,
  fundData: PropTypes.object
};

const CardHeader = ({
    items, fundData, labels = {}, cardType, clickTrackCTA
}) => {
  const {shareClassId, showFundTrackerButton, fundName: fieldData} = fundData;

  const navigateToFundTrackerWizard = () => {
    const fundTrackerInitShareClassData = {
      fundTrackerInitialShareClassData: {
        primaryShareClassDetails: {
          shareClassId
        },
        additionalShareClassIds: []
      }
    };
    clickTrackCTA(fundTrackerInitShareClassData);

    setTimeout(() => {
      history.push(getAppRoute(AppConstants.FUND_TRACKER_NAV));
    }, 1000);
  };

  return (
    <Conditional condition={cardType === "AccountDetail"}>
      <div>
        <span className="header" > {t('tkAccountDetailHeader')} </span>
        <h2>{fundData.factCardHeader}</h2>
      </div>
      <div>
        <h2>{fieldData}</h2>
        <Conditional condition={showFundTrackerButton}>
          <div className="fundFactView__controlPanel">
            <Button
              label={labels.tkTrack}
              iconClass="tracker-icon"
              clickHandler={navigateToFundTrackerWizard}
            />
          </div>
        </Conditional>
        <div className="fundFactView__subheading">
          <Repeater items={items} props={{labels, fundData}}>
            <Item />
          </Repeater>
        </div>
      </div>
    </Conditional>
  );
};

CardHeader.propTypes = {
  items: PropTypes.array,
  labels: PropTypes.object,
  fundData: PropTypes.object,
  cardType:PropTypes.string,
  clickTrackCTA: PropTypes.func
};

export default CardHeader;
