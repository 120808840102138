import React from 'react';
import translator from '../../../../../../services/translator';

const { translate: t } = translator;

const CapabilityRendererWithIconLabel = params => {

  const {
    capabilities: { canView = '', trade: { canTrade, tradeLevel = '' } = {}, approver: { canApprove, approverLevel = '' } = {} } = {}
  } = params.data;

  const includesLevel = [0, 1, 3].includes(params.node.level);

  return (
    <span>
      {includesLevel && (
        <span className='accounts__iconsContainer'>
          {canView && (
            <span className='accounts__iconsContainer-view'>
              <span className='accounts__iconsContainer-viewIcon' />
              {t('tkView')}
            </span>
          )}
          {canTrade && (
            <span className='accounts__iconsContainer-trade'>
              <span className='accounts__iconsContainer-tradeIcon' />
              {tradeLevel === 'DEFAULT' ? 'L0' : tradeLevel} {t('tkTrade')}
            </span>
          )}
          {canApprove && approverLevel?.length > 0 && (
            <span className='accounts__iconsContainer-approver'>
              <span className='accounts__iconsContainer-approverIcon' />
              {Array.isArray(approverLevel) ? approverLevel.sort().join(',') : approverLevel} {t('tkApprover')}
            </span>
          )}
        </span>
      )}
    </span>
  );
};

export default CapabilityRendererWithIconLabel;
