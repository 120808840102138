import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import appConstants, { FILE_DOWNLOAD_TYPES} from '../../../../constants/appConstants';
import DownloadModal from '../../../../components/app/Download/index';
import { closeDownloadModal, queueExcelDownloadSnackbar } from '../../../../actions/app/reports';
import {fileTypeChange, startExportToExcel, startExportToPdf, changeFitToPageType, editCustomView} from '../../../../actions/page/reports';
import {isOpenFileDownloadModal, selectedSavedReportDataSelector} from '../../../../selectors/app/reports';
import {
  currentReportConfigSelector, currentViewSelector, getFileTypes,
  getFileType,
  getFitToPageType, gridHeader
} from '../../../../selectors/pages/reports/individualReport';
import {
  dispatchAnalytics, dispatchReportAnalytics, getExportViewAnalyticsData
} from '../../analytics';
import { pageIdSelector } from '../../../../selectors/pages';
import {allReportsGridDataSelector} from '../../../../selectors/pages/reports/allReports';
import {STANDARD_REPORT_VIEW_NAMES} from '../../utils';
import { formatFilenameWithDate } from '../../../../utils/formatters';

const {PDF_DOWNLOAD_MAX_COLUMN_WARNING_LIMIT} = appConstants;

export const ReportFileDownloadModal = (props) => {
  const {pageHeaderData=[], pageId='', selectedSavedReportData} = props;
  let reportTitle = '';
  if (selectedSavedReportData && pageId === 'SavedReport') {
    reportTitle = selectedSavedReportData.reportName;
  } else {
    const {reportTitleKey = ''} = pageHeaderData.find(({id}) => id === pageId) || {};
    reportTitle = reportTitleKey;
  }

  const handleClose = useCallback(() => {
    props.dispatchChangeFileType(null);
    props.dispatchFitToPageChangeType(null);
    props.dispatchCloseModal({'modelActiveMode': '', 'isModalLoading': false});
  }, []);

  const handleFileTypeChange = useCallback((fileType) => {
    props.dispatchChangeFileType(fileType);
  }, []);

  const handleFitToPageTypeChange = useCallback((isChecked) => {
    props.dispatchFitToPageChangeType(isChecked);
  }, []);

  const handleDownload = useCallback((fitToPage) => {
    props.dispatchChangeFileType(null);
    props.dispatchFitToPageChangeType(null);
    props.dispatchFileDownload(props, fitToPage);
  }, [props.selectedFileType]);

  const checkMoreThan20Columns = () => {
    return props.currentView && props.currentView.columns &&
      (props.currentView.columns.length - 1 > PDF_DOWNLOAD_MAX_COLUMN_WARNING_LIMIT);
  };

  const modalProps = {
    animation: false,
    suppressScrollOnActive: true,
    customModalStyle: {overflow: 'hidden'},
    isModalOpen: props.open,
    handleClose,
    title: props.currentView.name,
    selectedFileType: props.selectedFileType,
    selectedFitToPageType: props.selectedFitToPageType,
    options: props.options,
    handleFileTypeChange,
    handleFitToPageTypeChange,
    handleDownload,
    isMoreThan20Columns: checkMoreThan20Columns(),
    reportName: reportTitle
  };

  return (
    <DownloadModal {...modalProps} />
  );
};

const handleDispatchFileDownload = (props, fitToPage, dispatch) => {
  const {header, currentView, selectedFileType, currentReportConfig, pageId} = props;
  const fileType = (selectedFileType === FILE_DOWNLOAD_TYPES.EXCEL ? 'Excel': 'PDF');
  if (selectedFileType === FILE_DOWNLOAD_TYPES.EXCEL) {
    const label = currentView && (currentView.name || currentView.label);
    const viewLabel =  label ? label.split(':')[0].trim().replace(/ /g, '_') : '';
    const fileName = formatFilenameWithDate(viewLabel);
    const id = `excel-snackbar-${new Date().getTime()}`;
    dispatch(queueExcelDownloadSnackbar({fileName, id }));
    dispatch(startExportToExcel({fileName, id }));
    if (STANDARD_REPORT_VIEW_NAMES[pageId] !== currentView.name)
      dispatch(editCustomView({...currentView, downloadFileType: selectedFileType, fitPdfToSinglePage: false}));
  } else {
    dispatch(startExportToPdf({fitToPage}));
    if (STANDARD_REPORT_VIEW_NAMES[pageId] !== currentView.name)
      dispatch(editCustomView({...currentView, downloadFileType: selectedFileType, fitPdfToSinglePage: fitToPage}));
  }
  dispatchAnalytics(dispatch, getExportViewAnalyticsData(header, currentView.label, fileType));
  dispatchReportAnalytics(dispatch, {...currentReportConfig, fileType});
};

const mapStateToProps = state => ({
  open: isOpenFileDownloadModal(state),
  options: getFileTypes(state),
  header: gridHeader(state),
  currentView: currentViewSelector(state),
  selectedFileType: getFileType(state),
  selectedFitToPageType: getFitToPageType(state),
  pageId: pageIdSelector(state),
  pageHeaderData: allReportsGridDataSelector(state),
  selectedSavedReportData: selectedSavedReportDataSelector(state),
  currentReportConfig: currentReportConfigSelector(state)
});

export const mapDispatchToProps = dispatch => ({
  dispatchCloseModal: data => dispatch(closeDownloadModal(data)),
  dispatchChangeFileType: data => dispatch(fileTypeChange(data)),
  dispatchFitToPageChangeType: data => dispatch(changeFitToPageType(data)),
  dispatchFileDownload: (props, fitToPage) => handleDispatchFileDownload(props, fitToPage, dispatch)
});

ReportFileDownloadModal.propTypes = {
  open: PropTypes.bool,
  dispatchCloseModal: PropTypes.func,
  errorObject: PropTypes.object,
  options: PropTypes.array,
  dispatchChangeFileType: PropTypes.func,
  dispatchFitToPageChangeType: PropTypes.func,
  selectedFileType: PropTypes.string,
  selectedFitToPageType: PropTypes.bool,
  dispatchFileDownload: PropTypes.func,
  currentView: PropTypes.array,
  pageId: PropTypes.string,
  pageHeaderData: PropTypes.array,
  selectedSavedReportData: PropTypes.object,
  currentReportConfig: PropTypes.object
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportFileDownloadModal);
