import React from "react";
import moment from 'moment';
import PropTypes from "prop-types";
import cn from 'classnames';
import range from 'lodash/range';
import {Select} from '@gs-ux-uitoolkit-react/select';
import {getMonth, getYear} from 'date-fns';
import {REBATE_CONSTANTS} from "../../../../../constants/pageConstants";
import {MONTH_LIST} from '../../../../../constants/appConstants';
import DatePickerComp from '../../../../core/Date';
import './index.scss';

const CustomDateHeader = ({
  date, changeYear, changeMonth, decreaseMonth, increaseMonth,
  prevMonthButtonDisabled, nextMonthButtonDisabled, labels
}) => {
  const {tkPreviousMonth, tkNextMonth} = labels;
  const years = range(2015, getYear(new Date()) + 1, 1);
  const calendarMonths = MONTH_LIST.map(month => (
    {label: month, value: month}
  ));
  const calendarYears = years.map(year => (
    {label: year, value: year}
  ));

  return (
    <div
      style={{
        margin: 10,
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <button
        type="button"
        className={cn(
          'react-datepicker__navigation',
          'react-datepicker__navigation--previous',
          {'react-datepicker__navigation--previous--disabled': prevMonthButtonDisabled}
        )}
        aria-label="Previous Month"
        onClick={decreaseMonth}
        disabled={prevMonthButtonDisabled}
      >
        <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--previous">
          {tkPreviousMonth}
        </span>
      </button>
      <div className="month-select">
        <Select
          options={calendarMonths}
          selectedValue={MONTH_LIST[getMonth(date)]}
          onSelect={({selectedValue}) => {
            changeMonth(MONTH_LIST.indexOf(selectedValue))
          }}
          clearable={false}
        />
      </div>
      <div className='year-select'>
        <Select
          options={calendarYears}
          selectedValue={getYear(date)}
          onSelect={({selectedValue}) => {
            changeYear(selectedValue)
          }}
          clearable={false}
        />
      </div>
      <button
        type="button"
        className={cn(
          'react-datepicker__navigation',
          'react-datepicker__navigation--next',
          {'react-datepicker__navigation--next--disabled': nextMonthButtonDisabled}
        )}
        aria-label="Next Month"
        onClick={increaseMonth}
        disabled={nextMonthButtonDisabled}
      >
        <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--next">
          {tkNextMonth}
        </span>
      </button>
    </div>
  )
}

CustomDateHeader.propTypes = {
  date: PropTypes.string,
  changeYear: PropTypes.func,
  changeMonth: PropTypes.func,
  decreaseMonth: PropTypes.func,
  increaseMonth: PropTypes.func,
  prevMonthButtonDisabled: PropTypes.bool,
  nextMonthButtonDisabled: PropTypes.bool,
  labels: PropTypes.object
}

const DatePicker = ({value, field, handleEditFields, labels}) => {
  const onDateSelect = (selectedDate) => {
    const date = moment(selectedDate).format('YYYY-MM-DD').toUpperCase();
    handleEditFields(date, field, REBATE_CONSTANTS.DATE);
  };

  const selectedDate = moment(value).toDate() || moment().toDate();
  const startDate = moment("2015-01-01", 'YYYY-MM-DD').toDate();
  const endDate = moment().add(29, 'days').toDate();

  return (
    <DatePickerComp
      minDate={startDate}
      maxDate={endDate}
      testId="datePicker"
      disableWeekends={true}
      onSelect={onDateSelect}
      selectedDate={selectedDate}
      selectsRange={false}
      showMonthDropdown={false}
      showYearDropdown={false}
      renderCustomHeader={(props) => (
        <CustomDateHeader
          {...props}
          labels={labels}
        />
      )}
    />
  );
};

DatePicker.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  field: PropTypes.string,
  handleEditFields: PropTypes.func,
  labels: PropTypes.object
};

export default DatePicker;
