import { createSelector } from 'reselect';
import extend from 'lodash/extend';
import uniq from 'lodash/uniq';
import { PAGES } from '../reducers';
import { labelSelector } from '../../../../selectors/app';
import { SORT, PORTFOLIO_FILTER_STATE, VIEW_ACTION, MAX_VIEWS, COLUMN_COLID } from '../../../../constants/pageConstants';
import { allowFeaturesSelector } from '../../../../selectors/user';
import { FILE_DOWNLOAD_TYPES } from '../../../../constants/appConstants';
import { injectKeys } from '../../../../utils/dynamici18nInjection';
import {
  isLoading,
  allViewsSelector,
  gridData,
  gridError,
  columnsConfigs,
  groupByFieldNew,
  columnsMetadata,
  groupColumns,
  groupBy,
  getFilteredDataLength,
  filterVisibility,
  getFilters,
  getFilterInErrorState,
  selectedViewId,
} from '../../../../modules/Grid/selector';
import errorTypes from '../../../../error/errorType';
import { caseInsensitiveSorter } from '../../../../utils/comparators';
import { getCurrentViewSelectorData, getCurrentGridViewData, gridHeaderData } from '../../../../helpers/gridFund';

const defaultState = {
  autoComplete: [],
};

const defaultSelectedView = {};

const defaultUserDetails = {
  profile: {
    entitlements: {},
  },
  contactDetails: {},
  accessTypes: [],
  permissions: {},
  firstName: '',
  lastName: '',
  gtamUserId: '',
  login: '',
  funds: [],
  UserLoginInfo: {},
  ssoClient: [],
  accountDetails: [],
};

const defaultOrganizationDetails = {
  organizationName: '',
  organizationId: '',
  id: '',
  status: '',
  parentOrganizationName: '',
  relationshipType: '',
  rebates: {
    shareclassRebates: '',
    fundFamilyRebates: '',
  },
  tradingSafeguards: {},
  creationDate: '',
  lastModifiedDate: '',
  users: [],
  accountDetails: [],
  funds: {
    fundGroups: [],
    individualShareclasses: {},
  },
};

export const autoCompleteList = state => state.pageContext.autoComplete || defaultState.autoComplete;
export const searchInProgress = state => state.pageContext.searchInProgress;
export const getUserDetailsLoading = state => state.pageContext.isUserDetailsLoading;
export const isUserSearch = state => state.pageContext.isUser;
export const isOrganizationSearch = state => state.pageContext.isOrganization;
export const getCurrentPage = state => state.pageContext.currentPage || PAGES.LANDING_PAGE;
export const isShowDetailsGrid = state => state.pageContext.showDetailsGrid;
export const showInActiveUser = state => state.pageContext.inActiveUser || false;
export const getDestroyFilters = state => state.pageContext.destroyFilters || false;
export const isFirmSelection = state => !!state.pageContext.isFirm;
export const isFreeTextSearch = state => state.pageContext.isFreeText || false;
export const searchTerm = state => state.pageContext.searchTerm || '';
export const getUserDetails = state => state.pageContext.userDetails || defaultUserDetails;
export const getUserDetailsStatus = state => state.pageContext.userDetailsStatus;
export const getUserDetailsAccountStatus = state => state.pageContext.userDetailsAccountStatus;
export const getPreviousSearchTerm = state => {
  if (state.pageContext.isUser) return '';
  if (state.pageContext.searchTerm && state.pageContext.searchTerm.label) {
    return state.pageContext.searchTerm.label;
  }
  return state.pageContext.searchTerm || '';
};

export const getDashboardCardsPermissions = state => state.appData.dashboardCardsPermissions || [];

export const getPageKey = state => state.pageContext.pageKey || '';

export const getOrganizationDetails = state => state.pageContext.organizationDetails || defaultOrganizationDetails;

export const getOrganizationId = state => state.user?.organizationId;

export const getUserDetailsAccounts = state =>
  (state.pageContext.userDetails && state.pageContext.userDetails.accountDetails) || defaultUserDetails.accountDetails;

export const isSsaAdminSelector = createSelector(allowFeaturesSelector, allowedFeatures => allowedFeatures.includes('SELFSERVICEADMIN'));

export const dashboardCardsError = state => state.appData.dashboardCardsPermissionsError;

export const userAndOrgRolesSelector = createSelector(allowFeaturesSelector, allowedFeatures => ({
  userAdminView: allowedFeatures.includes('SELFSERVICEADMINUSERVIEW'),
  userAdminEdit: allowedFeatures.includes('SELFSERVICEADMINUSEREDIT'),
  orgAdminView: allowedFeatures.includes('SELFSERVICEADMINORGVIEW'),
  orgAdminEdit: allowedFeatures.includes('SELFSERVICEADMINORGEDIT'),
}));

export const getSearchGroups = createSelector(userAndOrgRolesSelector, userAndOrgRoles => {
  const searchGroup = []
  if (userAndOrgRoles.userAdminView || userAndOrgRoles.userAdminEdit) {
    searchGroup.push(...["user", "firm"])
  }
  if (userAndOrgRoles.orgAdminView || userAndOrgRoles.orgAdminEdit) {
    searchGroup.push("organization")
  }
  return searchGroup
});

export const fundsTabGridHeader = createSelector(columnsConfigs, labelSelector, (metadata, labels) => {
  const { fundsColumns = [] } = metadata;
  return fundsColumns.map(column => ({ ...column, headerName: labels[column.headerName] }));
});

export const orgFundsTabGridHeader = createSelector(fundsTabGridHeader, metadata => metadata);
export const profileChgMgmtGridHeader = createSelector(columnsConfigs, labelSelector, (metadata, labels) => {
  const { profileChgMgmtColumns = [] } = metadata;
  return profileChgMgmtColumns.map(column => ({ ...column, headerName: labels[column.headerName] }));
});

export const accountsChgMgmtGridHeader = createSelector(columnsConfigs, labelSelector, (metadata, labels) => {
  const { accountsChgMgmtColumns = [] } = metadata;
  return accountsChgMgmtColumns.map(column => ({ ...column, headerName: labels[column.headerName] }));
});

export const userTabGridHeader = createSelector(columnsConfigs, labelSelector, (metadata, labels) => {
  const { orgUsers = [] } = metadata;
  return orgUsers.map(column => ({ ...column, headerName: labels[column.headerName] }));
});

export const getDropdownData = createSelector(columnsConfigs, metadata => metadata.editModeDropdownValues);

export const getUserAccountStatus = state => state.pageContext.userAccountStatus && state.pageContext.userAccountStatus;

export const getSearchTerm = createSelector(isFreeTextSearch, searchTerm, (isFreeTextSearch, searchTerm) => {
  return isFreeTextSearch ? searchTerm : '';
});

export const showDetailsGrid = createSelector(
  isShowDetailsGrid,
  isUserSearch,
  isFreeTextSearch,
  isOrganizationSearch,
  (showDetailsGrid, isUser, isFreeTextSearch, isOrganization) => {
    if (isUser || isOrganization) return false;
    return showDetailsGrid || isFreeTextSearch;
  }
);

const defaultCurrentView = {};
const defaultErrorObject = {};
export const getErrorObject = state => state.pageContext.errorObject || defaultErrorObject;
export const selectCollapseState = ({ preferences = {} }) => (preferences.userSearch ? preferences.userSearch.isCollapsedAll : false);
export const getSelectedFileType = state => state.modules.userSearch.transactionsFileDownloadType || FILE_DOWNLOAD_TYPES.EXCEL;
export const exportExcel = state => state.modules.userSearch.exportExcel || {};

export const groupByField = createSelector(groupBy, groupByField => (groupByField === '' ? 'none' : groupByField || 'none'));
export const getDataInlineError = ({ modules }, id) => modules[id].dataError;

export const mapViewDataSelector = createSelector(allViewsSelector, allViews => {
  return allViews.map(view => {
    return { value: view.id, label: view.name, selected: view.isActive, nodeId: view.id, isCustom: view.isCustom };
  });
});

export const customViewsSelector = createSelector(mapViewDataSelector, allViews => {
  return allViews.filter(view => view.isCustom);
});

export const presetViewsSelector = createSelector(mapViewDataSelector, allViews => {
  return allViews.filter(view => !view.isCustom);
});

export const getCreateCustomViewCTALabel = createSelector(
  customViewsSelector,
  presetViewsSelector,
  labelSelector,
  (customViews, presetViews, labels) => {
    return labels[customViews.length + presetViews.length >= 10 ? MAX_VIEWS.name : VIEW_ACTION.name];
  }
);

export const dropdownViewNamesSelector = createSelector(
  customViewsSelector,
  presetViewsSelector,
  labelSelector,
  (customViews, presetViews, labels) => {
    const totalViews = customViews.length + presetViews.length;
    const extendObj = totalViews >= 10 ? {} : { type: 'add', nodeId: 'usersearch-view' };
    const customView = extend(extendObj, totalViews >= 10 ? MAX_VIEWS : VIEW_ACTION); // button to create a new view
    customView.label = labels[totalViews >= 10 ? MAX_VIEWS.name : VIEW_ACTION.name];
    let views = [];
    if (customViews.length > 0) {
      views = [...customViews.sort(caseInsensitiveSorter)];
      if (presetViews.length > 0) {
        views.push('-');
      }
      return [...views, ...presetViews.sort(caseInsensitiveSorter)];
    } else {
      return [...presetViews.sort(caseInsensitiveSorter)];
    }
  }
);
export const currentView = createSelector(allViewsSelector, allViews => {
  const view = allViews.find(view => view.isActive);
  if (!view) return defaultCurrentView;
  return view;
});

export const groupByColumns = createSelector(groupColumns, labelSelector, groupByFieldNew, (groupByColumns, labels, groupByField) => {
  return (
    groupByColumns &&
    groupByColumns.map(item => {
      return {
        value: item.value,
        label: labels[item.label].toUpperCase(),
        selected: groupByField === item.value,
        nodeId: item.value,
      };
    })
  );
});

export const selectedGroup = createSelector(groupByField, groupByColumns, (selectedGroupValue, groupByColumns) => {
  const selectedGroupLabelData = groupByColumns && groupByColumns.length && groupByColumns.filter(group => group.value === selectedGroupValue);
  const selectedGroupLabel = (selectedGroupLabelData && selectedGroupLabelData.length && selectedGroupLabelData[0].label) || '';
  return {
    value: selectedGroupValue,
    label: selectedGroupLabel,
  };
});

export const currentViewSelector = createSelector(currentView, currentView =>
  getCurrentViewSelectorData({
    currentView,
    canTrade: false,
    isTradingDisabled: true,
  })
);

export const getCurrentGridView = createSelector(
  currentViewSelector,
  columnsMetadata,
  groupByColumns,
  labelSelector,
  (viewColumns, metadata, groupByColumns = [], labels) =>
    getCurrentGridViewData({
      viewColumns,
      metadata,
      groupByColumns,
      labels,
    })
);

export const preDefinedSortingState = createSelector(
  getCurrentGridView,
  currentViewColumns =>
    currentViewColumns.filter(column => column.colId === COLUMN_COLID.GROUP || column.sort === SORT.ASC || column.sort === SORT.DESC) || []
);
// Grid Columns
export const gridHeader = createSelector(
  getCurrentGridView,
  groupByFieldNew,
  preDefinedSortingState,
  columnsMetadata,
  getSearchTerm,
  (headerData = [], groupByField, sortedColums, metadata, searchTerm) => {
    return gridHeaderData({
      headerData,
      groupByField,
      sortedColums,
      metadata,
      searchTerm,
    });
  }
);

export const getGridData = createSelector(gridData, gridData => {
  return gridData;
});

export const disableExcelExport = createSelector(
  gridData,
  getFilteredDataLength,
  filterVisibility,
  getFilters,
  getFilterInErrorState,
  (data, filteredData, filterVisibility, filters = [], filterInErrorState) => {
    if (filterInErrorState) {
      return true;
    } else if (filterVisibility === PORTFOLIO_FILTER_STATE.HIDDEN) {
      return !data.length;
    } else {
      return filters.length ? !filteredData : !data.length;
    }
  }
);

export const getCurrentViewsKeys = createSelector(getCurrentGridView, columns => {
  const result = {};
  columns.forEach(col => {
    result[col.colId] = ' ';
  });
  return result;
});

export const noRowDataSelector = createSelector(
  getGridData,
  gridError,
  isLoading,
  labelSelector,
  getFilteredDataLength,
  (data, error, loading, labels, filteredData) => {
    if (filteredData === 0) {
      return labels.tkCopyPort04;
    } else if (loading === undefined || (data && data.length)) {
      return undefined;
    } else if (error && error.code === errorTypes.TOO_MUCH_DATA) {
      return labels.tkNoResultsFound;
    }
    return loading ? '' : labels.tkNoUserSearchDataMsg;
  }
);

export const metaDataSelector = createSelector(columnsMetadata, labelSelector, (metaData, labels) => {
  return metaData.map(column => {
    const columnData = { ...column };
    columnData.headerTooltip = labels[column.headerName];
    columnData.headerName = labels[column.headerName];
    if (column.category) {
      columnData.category = labels[column.category];
    }
    return columnData;
  });
});

export const selectedModelView = createSelector(allViewsSelector, selectedViewId, (allview, id) => {
  if (VIEW_ACTION.id === id) {
    return allview.find(view => view.isActive);
  }
  return allview.find(view => view.id === id) || defaultSelectedView;
});

export const uniqueColumnsSelector = createSelector(metaDataSelector, columns => {
  const headers = [];
  columns.forEach(column => {
    if (column.hasOwnProperty('category')) {
      headers.push(column.category);
    }
  });
  const uniqueHeaders = uniq(headers).sort();
  if (uniqueHeaders.indexOf('Required') !== -1) {
    uniqueHeaders.splice(uniqueHeaders.indexOf('Required'), 1);
    uniqueHeaders.unshift('Required');
  }
  return uniqueHeaders;
});

export const groupedColumnsSelector = createSelector(
  uniqueColumnsSelector,
  metaDataSelector,
  selectedModelView,
  (uniqueColumns, allColumns, currentView) => {
    const sortedColumns = allColumns.sort((a, b) => {
      return a.headerName && a.headerName.localeCompare(b.headerName);
    });
    const groupedColumns = uniqueColumns.map(uniqueColumn => {
      const uniqueColumnObj = { category: uniqueColumn, headers: [] };
      sortedColumns.forEach(column => {
        if (column.category === uniqueColumn) {
          uniqueColumnObj.headers.push(column);
        }
      });
      return uniqueColumnObj;
    });
    const currentViewFields = [];
    currentView &&
      currentView.columns &&
      currentView.columns.forEach(column => {
        if (!column.hide) {
          currentViewFields.push(column.field);
        }
      });
    return groupedColumns.map(group => {
      const groupCopy = { ...group };
      const updatedGroup = groupCopy.headers.map(header => {
        const headerCopy = { ...header };
        headerCopy.isChecked = currentViewFields.indexOf(headerCopy.field) > -1;
        return headerCopy;
      });
      groupCopy.headers = updatedGroup;
      return groupCopy;
    });
  }
);

export const autocompleteSnackbarList = createSelector(labelSelector, getErrorObject, (labels, errorObject) => {
  const { errorCode, componentType } = errorObject;
  const list = [];
  if (componentType === 'autocomplete' && (errorCode === errorTypes.UNKNOWN_SERVICE_ERROR || errorCode === errorTypes.BUSINESS_FAILURE)) {
    list.push({
      displayMultiple: true,
      type: 'error',
      msgCopy: labels.tkSomethingWentWrongError,
      id: Date.now(),
    });
  }

  return list;
});

export const userDetailsSnackbarList = createSelector(labelSelector, getUserDetailsStatus, (labels, userDetailsStatus) => {
  const list = [];
  if (userDetailsStatus) {
    list.push({
      displayMultiple: true,
      type: userDetailsStatus.success ? 'success' : 'error',
      msgCopy: userDetailsStatus.success ? labels.tkChangesSavedSuccessfully : labels.tkAnErrorPleaseTryAgain,
      id: Date.now(),
    });
  }
  return list;
});

export const userDetailsAccountSnackbarList = createSelector(labelSelector, getUserDetailsAccountStatus, (labels, userDetailsAccountStatus) => {
  const list = [];
  if (userDetailsAccountStatus) {
    list.push({
      displayMultiple: true,
      type: userDetailsAccountStatus.success ? 'success' : 'error',
      msgCopy: userDetailsAccountStatus.success ? labels.tkChangesSavedSuccessfully : labels.tkAnErrorPleaseTryAgain,
      id: Date.now(),
    });
  }
  return list;
});

export const getFooterInfo = createSelector(
  labelSelector,
  isFreeTextSearch,
  getGridData,
  getFilteredDataLength,
  getFilters,
  (labels, isFreeTextSearch, data, filteredDataLength, filterBy) => {
    const number = data.length.toLocaleString(toLocaleString, { minimumFractionDigits: 0 });
    if (isFreeTextSearch && data.length >= labels.tkMaxDataRowLowLimitAdmin) {
      return injectKeys(labels.tkNoRecordMatchYourSearch, number);
    }
    const filteredRow = filterBy.length ? filteredDataLength : number;
    return injectKeys(labels.tkGridTotalRows, filteredRow, number);
  }
);
