import React, { useEffect, useState } from 'react';
import DOMPurify from 'dompurify';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';
import {TooltipTarget} from '@gs-ux-uitoolkit-react/tooltip';
import Repeater from '../../../core/Repeater';
import { ThreeSquaresLoader } from '../../../core/Loaders';
import Item from './Item';
import {Conditional} from '../../../core/Conditional';
import {REBATE_CONSTANTS} from '../../../../constants/pageConstants';
import AddEditSaveButtons from '../../../app/FundFact/FundRebate/AddEditSaveButtons';
import {getRebateCTAAnalyticsData} from '../../../../utils/portfolioAnalytics';
import {validateRebateAmount} from './utils';
import {formatDateForInvstPlcy} from '../../../../utils/dateFormatter';
import {DATE_TIME_FORMATTERS} from '../../../../constants/appConstants';
import './index.scss';

export const FundRebate = ({
  items, labels, fundRebate, saveFundRebate, fundRebateLoading, entitledToRebates,
  isMimicSession, onChangeYieldState, updateRebateStatusToUnsavedChanges, fundRebateEditStatus,
  setFundRebateEditStatus, setUnsavedRebateChanges, unsetUnsavedRebateChanges, sendAnalytics
}) => {
  const {tkFundRebate, tkFundRebateTooltipContent, tkRebateDisclaimer} = labels;
  const [toggleEditSave, setToggleEditSave] = useState(false);
  const [fundRebateInfo, setFundRebateInfo] = useState(fundRebate || {});
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
  const [errorState, setErrorState] = useState(0);

  useEffect(() => {
    const existingFundRebate = fundRebate || {};
    if (!existingFundRebate.startDate) {
      existingFundRebate.startDate = formatDateForInvstPlcy(moment().toDate(), DATE_TIME_FORMATTERS.YYYY_MM_DD);
    }
    setFundRebateInfo(existingFundRebate);
  }, [fundRebate, fundRebateLoading]);

  useEffect(() => {
    if (fundRebateEditStatus === false) {
      setFundRebateInfo(fundRebate || {});
      setToggleEditSave(false);
      setErrorState(0);
    }
  }, [fundRebateEditStatus]);

  const onEditClick = () => {
    if(fundRebate && fundRebate.rebateAmount) {
      sendAnalytics(getRebateCTAAnalyticsData('Edit CTA', 'Fund Fact Card'));
    } else {
      sendAnalytics(getRebateCTAAnalyticsData('Add CTA', 'Fund Fact Card'));
    }
    setErrorState(validateRebateAmount(fundRebateInfo.rebateAmount, fundRebate && fundRebate.rebateAmount));
    setToggleEditSave(true);
    setSaveButtonDisabled(true);
    setFundRebateEditStatus();
  };

  const onSaveClick = async (e) => {
    sendAnalytics(getRebateCTAAnalyticsData('Save CTA', 'Fund Fact Card'));
    e.preventDefault();
    e.stopPropagation();
    await saveFundRebate(fundRebateInfo);
    if (!fundRebateInfo.rebateAmount) {
      // When rebate is removed, unadjusted fields should be displayed
      onChangeYieldState('Unadjusted');
    } else if((!fundRebate || !fundRebate.rebateAmount) && fundRebateInfo.rebateAmount) {
      onChangeYieldState('Adjusted');
    }
    setFundRebateInfo(fundRebateInfo);
    setToggleEditSave(false);
  };

  const userHasMadeEdits = () => {
    // Rebate amount exists and is changed or removed
    if (fundRebate && fundRebate.rebateAmount && fundRebate.rebateAmount !== fundRebateInfo.rebateAmount) {
      return true;
    }

    // Rebate amount does not exist and is added
    if ( (!fundRebate || !fundRebate.rebateAmount) && fundRebateInfo.rebateAmount) {
      return true;
    }

    // Investment option exists and is changed or removed
    if (fundRebate && fundRebate.investmentOption && fundRebate.investmentOption !== fundRebateInfo.investmentOption){
      return true;
    }

    // Investment does not exist and is added
    if ( (!fundRebate || !fundRebate.investmentOption) && fundRebateInfo.investmentOption){
      return true;
    }

    // Payment date exists and is changed or removed
    if (fundRebate && fundRebate.paymentDate && fundRebate.paymentDate !== fundRebateInfo.paymentDate){
      return true;
    }

    // Payment date does not exist and is added
    if ( (!fundRebate || !fundRebate.paymentDate) && fundRebateInfo.paymentDate){
      return true;
    }
  };

  useEffect(() => {
    if (userHasMadeEdits()) {
      setUnsavedRebateChanges();
    } else {
      unsetUnsavedRebateChanges();
    }
    return unsetUnsavedRebateChanges;
  }, [fundRebateInfo]);

  const onCancelClick = () => {
    sendAnalytics(getRebateCTAAnalyticsData('Cancel CTA', 'Fund Fact Card'));
    if (userHasMadeEdits()) {
      updateRebateStatusToUnsavedChanges();
      return;
    }
    setFundRebateInfo(fundRebate || {});
    setToggleEditSave(false);
    setErrorState(REBATE_CONSTANTS.REBATE_NO_ERROR);
  };

  const handleError = (value) => {
    const errorState = validateRebateAmount(value, fundRebate && fundRebate.rebateAmount);
    setErrorState(errorState);
    setSaveButtonDisabled(errorState !== REBATE_CONSTANTS.REBATE_NO_ERROR);
  };

  const handleEditFields = (value, field, fieldType) => {
    setFundRebateInfo({
      ...fundRebateInfo,
      [field]: value,
    });

    if (fieldType === REBATE_CONSTANTS.INPUT) {
      handleError(value, field);
    } else {
      setSaveButtonDisabled(errorState !== REBATE_CONSTANTS.REBATE_NO_ERROR || (!fundRebateInfo.rebateAmount && (!fundRebate || !fundRebate.rebateAmount)));
    }
  };

  if (!entitledToRebates) {
    return null;
  }

  return (
    <div
      className={
        classNames(
          {
            'debit-container': fundRebateLoading,
          }
      )}
    >
      <div className="factHead">
        <span className='fundFactView__label'>
          {tkFundRebate}
          <TooltipTarget
            inline={true}
            label={
              <p
                className={'rebate-hyperlink'}
                dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(tkFundRebateTooltipContent)}}
              />
            }
          >
            <span data-testid="info-icon" className="info-icon" />
          </TooltipTarget>
        </span>

        <span className="account-edit-button">
          <AddEditSaveButtons
            toggleEditSave={toggleEditSave}
            rebateAmountExists={!!fundRebateInfo.rebateAmount}
            saveButtonDisabled={saveButtonDisabled}
            isMimicSession={isMimicSession}
            labels={labels}
            onEditClick={onEditClick}
            onCancelClick={onCancelClick}
            onSaveClick={onSaveClick}
            sendAnalytics={sendAnalytics}
          />
        </span>
      </div>

      <Conditional condition={fundRebateInfo.rebateAmount || toggleEditSave}>
        <>
          <Repeater
            items={items}
            props={{
              fundRebateInfo,
              isEdit: toggleEditSave,
              handleEditFields,
              errorState,
              labels
            }}
          >
            <Item />
          </Repeater>
          <div className="rebate-disclaimer" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(tkRebateDisclaimer)}}/>
        </>
      </Conditional>
      {fundRebateLoading && <ThreeSquaresLoader />}
    </div>
  );
};

FundRebate.propTypes = {
  items: PropTypes.array,
  labels: PropTypes.object,
  fundRebate: PropTypes.object,
  saveFundRebate: PropTypes.func,
  fundRebateLoading: PropTypes.bool,
  entitledToRebates: PropTypes.bool,
  isMimicSession: PropTypes.bool,
  onChangeYieldState: PropTypes.func,
  updateRebateStatusToUnsavedChanges: PropTypes.func,
  fundRebateEditStatus: PropTypes.bool,
  setFundRebateEditStatus: PropTypes.func,
  setUnsavedRebateChanges: PropTypes.func,
  unsetUnsavedRebateChanges: PropTypes.func,
  sendAnalytics: PropTypes.func
};
