import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import {Conditional} from '../../../../components/core/Conditional';
import CustomSelect from '../../../../components/core/Select/Custom';
import FundBenchmarkOption from './FundBenchmarkOption';
import FundBenchmarkList from '../FundBenchmarkList';
import translator from '../../../../services/translator';
import {getFundValueToSearch, hasObjectChanged, updateShareClassDetails} from '../../utils';
import './index.scss';
import {fundTrackerStateProps} from '../../store/helpers';
import {DATA_TYPE} from '../../constants';
import { canItemBeSearched } from '../../utils/helpers';

const { translate: t } = translator;

const SearchBenchmark = ({
  title,
  onChange,
  onSelectCompareItem,
  primaryProduct,
  displaySelectedBenchmarks = true,
  allShareClassData,
  skipBenchmarks = false, // to show the iMoneyNetAverages
  placeholder,
  benchmarks: selectedBenchmarks,
  shareClassAndBenchmarkData: { shareClasses = [], benchmarks: benchMarksData = [] } = {}
}) => {
  const [benchmarks, setBenchmarks] = useState(selectedBenchmarks);

  useEffect(() => {
    const benchMarkItems = updateShareClassDetails(benchmarks, allShareClassData);
    // update state only when required to avoid max depth call
    if (hasObjectChanged(benchMarkItems, benchmarks)) {
      setBenchmarks(benchMarkItems);
    }
  }, [benchmarks]);

  useEffect(() => {
    onSelectCompareItem('benchmarks', benchmarks);
  }, [benchmarks]);

  const handleSelection = useCallback(
    option => {
      onChange();
      const {
        label,
        value,
        customProperties: { type, cusip, isin }
      } = option || {};
      const containsBenchmark = benchmarks.find(benchmark => benchmark.value === value);
      if (!containsBenchmark) {
        setBenchmarks([...benchmarks, { label, value, type, cusip, isin }]);
      }
    },
    [benchmarks]
  );

  const handleDeSelection = useCallback(
    value => {
      onChange();
      const newBenchmarks = benchmarks.filter(benchmark => benchmark.value !== value);
      setBenchmarks(newBenchmarks);
    },
    [benchmarks]
  );

  const searchItems = useMemo(() => {
    // Prepare the plain list of Share Classes
    const shareClassPlainList = [];
    shareClasses
      .filter(({ id }) => canItemBeSearched(id, primaryProduct?.value, benchmarks))
      .forEach(item => {
        const shareclass = getFundValueToSearch(item);
        if (!isEmpty(shareclass)) {
          shareClassPlainList.push(shareclass);
        }
      });

    // If we need to add the Benchmarks as well
    if (!skipBenchmarks) {
      const shareClassGrouped = {
        label: t('tkFunds'),
        options: shareClassPlainList
      };

      // Prepare the benchmark list
      const benchmarkList = {
        label: t('tkIMoneyNetAverages'),
        options: benchMarksData
          .filter(({ id }) => canItemBeSearched(id, primaryProduct?.value, benchmarks))
          .map(item => ({
            value: item.id,
            label: item.name || item.category || '',
            customProperties: {
              type: DATA_TYPE.BENCHMARK
            }
          }))
      };
      return [shareClassGrouped, benchmarkList];
    }
    // Just return the plain list
    return shareClassPlainList;
  });

  const isDataLoading = !searchItems || !searchItems.length;
  const isDisableSearch = isDataLoading || benchmarks.length > 14;

  return (
    <div className='benchmark'>
      <div>
        <span className='benchmark__heading'>{title}</span>
        <div className='benchmark__search'>
          <CustomSelect
            isDataLoading={isDataLoading}
            isDisabled={isDisableSearch}
            placeholder={placeholder}
            searchItems={searchItems}
            handleSelection={handleSelection}
            option={FundBenchmarkOption}
          />
        </div>
      </div>
      <Conditional condition={displaySelectedBenchmarks}>
        <div className='benchmark__selectedItems'>
          <FundBenchmarkList
            items={benchmarks}
            disabled={false}
            placeholder={t('tkNoBenchmarkFundSelected')}
            onRemove={handleDeSelection}
          />
        </div>
      </Conditional>
    </div>
  );
};

SearchBenchmark.propTypes = {
  title: PropTypes.string,
  allShareClassData: PropTypes.array,
  benchmarks: PropTypes.array,
  displaySelectedBenchmarks: PropTypes.bool,
  onChange: PropTypes.func,
  onSelectCompareItem: PropTypes.func,
  placeholder: PropTypes.string,
  primaryProduct: PropTypes.string,
  shareClassAndBenchmarkData: PropTypes.object,
  skipBenchmarks: PropTypes.bool
};

export default connect(fundTrackerStateProps)(SearchBenchmark);
