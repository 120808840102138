import { eventAnalytics } from '../../../../actions/sagas';

export const dispatchAnalytics = (dispatch, data) => {
  const cloneData = data.event ? { ...data, event: { ...data.event } } : { ...data };
  dispatch(eventAnalytics(cloneData));
};

export const searchResultSelected = (searchTerm, searchType) => ({
  event: {
    eventName: 'auto_search_result_selected',
    searchType,
    searchTerm,
    startTime: Date.now(),
  },
});

export const userDetailsLoad = () => ({
  page: {
    pageInfo: {
      component: 'user card',
    },
  },
  event: {
    eventName: 'user_card_open',
    eventStage: 'open',
    endTime: Date.now(),
  },
});

export const userDetailsTabChange = payload => ({
  event: {
    eventName: 'user_card_link',
    valueChosen: payload,
    resourceStartTime: Date.now(),
    eventStage: 'open',
  },
});

export const userDetailsClose = () => ({
  event: {
    eventName: 'user_card_close',
    eventStage: 'close',
    resourceEndTime: Date.now(),
  },
});

export const userMaintenanceEditSaveCancelCTAClick = payload => ({
  event: {
    eventDesc: 'SSA Admin Interaction',
    eventDetail: `${payload.pageKey} Details ${payload.btnName} CTA`,
    eventAction: 'Click',
    GUID: payload.guid,
    componentName: `Admin ${payload.pageKey} Details ${payload.btnName === 'Edit' ? 'View' : 'Edit'} | ${payload.tabName}`,
    satelliteTrackEventName: 'adminEvents',
  },
});

export const userMaintenanceError = payload => ({
  event: {
    eventDesc: `${payload.pageKey} Details Error`,
    eventDetail: payload.errorMsg,
    eventAction: 'Error Display',
    GUID: payload.guid,
    componentName: `Admin ${payload.pageKey} Details Error | ${payload.tabName}`,
    satelliteTrackEventName: 'adminEvents',
  },
});

export const userActivateDeactivateClick = payload => ({
  event: {
    eventDesc: 'SS Admin Interaction',
    eventDetail: payload.btnName,
    eventAction: `User Details Status ${payload.btnName}`,
    GUID: payload.guid,
    componentName: `Admin User Details View | ${payload.tabName}`,
    satelliteTrackEventName: 'adminEvents',
  },
});

export const userDeactivateModal = payload => ({
  event: {
    eventDesc: 'SS Admin Interaction',
    eventDetail: payload.btnName,
    eventAction: 'Click',
    GUID: payload.guid,
    componentName: 'Deactivate User Modal',
    satelliteTrackEventName: 'adminEvents',
  },
});

export const unsavedChangesModal = payload => ({
  event: {
    eventDesc: 'SSA Admin Interaction',
    eventDetail: 'Unsaved changes modal display',
    eventAction: 'Display',
    GUID: payload.guid,
    componentName: `Admin ${payload.pageKey} Details Unsaved Changes Modal | ${payload.tabName}`,
    satelliteTrackEventName: 'adminEvents',
  },
});

export const unsavedChangesModalCTA = payload => ({
  event: {
    eventDesc: 'SSA Admin Interaction',
    eventDetail: payload.btnName,
    eventAction: 'Unsaved changes modal CTA Click',
    GUID: payload.guid,
    componentName: `Admin ${payload.pageKey} Details Unsaved Changes Modal | ${payload.tabName}`,
    satelliteTrackEventName: 'adminEvents',
  },
});

export const helpGuideCheckboxClick = payload => ({
  event: {
    eventDesc: 'SS Admin Interaction',
    eventDetail: `Help Guide checkbox ${payload.isChecked ? 'checks' : 'unchecks'}`,
    eventAction: 'Click',
    GUID: payload.guid,
    componentName: `Help Guide | ${payload.tabName}`,
    satelliteTrackEventName: 'adminEvents',
  },
});

export const helpGuidePreviousNextClicks = payload => ({
  event: {
    eventDesc: 'SS Admin Interaction',
    eventDetail: `${payload.btn} CTA`,
    eventAction: 'Click',
    GUID: payload.guid,
    componentName: `Help Guide | ${payload.tabName}`,
    satelliteTrackEventName: 'adminEvents',
  },
});

export const maintenanceAlertsFilterClicks = payload => ({
  event: {
    eventDesc: 'SS Admin Interaction',
    eventDetail: payload.filterName,
    eventAction: 'Maintenance Alert Filter Click',
    GUID: payload.guid,
    componentName: 'Maintenance Alerts - Admin Dashboard',
    satelliteTrackEventName: 'adminEvents',
  },
});

export const unmappedFirmAlertsClick = payload => ({
  event: {
    eventDesc: 'SS Admin Interaction',
    eventDetail: payload.firmAlertName,
    eventAction: 'Unmapped Firm Alert Click',
    GUID: payload.guid,
    componentName: 'Maintenance Alert - Admin Dashboard',
    satelliteTrackEventName: 'adminEvents',
  },
});

export const unmappedFirmAlertsModalCTA = payload => ({
  event: {
    eventDesc: 'SS Admin Interaction',
    eventDetail: payload.btnName,
    eventAction: 'Unmapped Firm Alerts CTA Click',
    GUID: payload.guid,
    componentName: payload.modalHeaderMsg,
    satelliteTrackEventName: 'adminEvents',
  },
});

export const organizationDetailsMapNewFirmClick = payload => ({
  event: {
    eventDesc: 'SS Admin Interaction',
    eventDetail: 'Map a new firm CTA',
    eventAction: 'Org Details CTA Click',
    GUID: payload.guid,
    componentName: `Organization Details Edit- ${payload.organizationName}`,
    satelliteTrackEventName: 'adminEvents',
  },
});

export const organizationDetailsMapNewFirmModalCTA = payload => ({
  event: {
    eventDesc: 'SS Admin Interaction',
    eventDetail: payload.btnName,
    eventAction: 'Org Details Modal CTA Click',
    GUID: payload.guid,
    componentName: payload.modalHeaderMsg,
    selectedFirms: payload.selectedFirms,
    satelliteTrackEventName: 'adminEvents',
  },
});

export const organizationDetailsUnmapFirmClick = payload => ({
  event: {
    eventDesc: 'SS Admin Interaction',
    eventDetail: 'Unmap this firm CTA',
    eventAction: `${payload.firmName} (${payload.firmCode})`,
    GUID: payload.guid,
    componentName: `Organization Details Edit - ${payload.organizationName}`,
    satelliteTrackEventName: 'adminEvents',
  },
});

export const organizationDetailsUnmapFirmModalCTAClick = payload => ({
  event: {
    eventDesc: 'SS Admin Interaction',
    eventDetail: payload.btnName,
    eventAction: payload.radioBtn,
    GUID: payload.guid,
    componentName: 'Are you sure you want to unmap this firm?',
    satelliteTrackEventName: 'adminEvents',
  },
});
