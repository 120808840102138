import {getDomainMapper} from "../../../utils/commonUtils";
const domainMapper = getDomainMapper();

export default {
  FETCH_FUND_TRACKER_CONFIGURATIONS: {
    'path': `/content/${domainMapper}/nodes/fund-tracker-configurations.json`,
    'method': 'GET'
  },
  FETCH_FUND_TRACKER_COMPARE_GRID_CONFIGURATIONS: {
    'path': `/content/${domainMapper}/nodes/fund-tracker-compare-data-columns-configuration.json`,
    'method': 'GET'
  },
  FETCH_FUND_TRACKER_COMPARE_DATA_GRID_VIEWS: {
    'path': `/content/${domainMapper}/nodes/fund-tracker-compare-data-grid-view.json`,
    'method': 'GET'
  },
  FETCH_FUND_TRACKER_WIZARD_DATA: {
    'path': '/api/v1/reports/fundTracker',
    'method': 'POST'
  },
  FETCH_FUND_TRACKER_BENCHMARK: {
    'path': '/api/v1/reports/fundTrackerBenchmark',
    'method': 'POST'
  },
  FETCH_SHARE_CLASSES_AND_BENCHMARKS: {
    'path': '/api/v1/reports/getShareClassesAndBenchmarks',
    'method': 'POST'
  },
  FETCH_SHARE_CLASS_PERFORMANCE: {
    'path': '/api/v1/products/ids',
    'method': 'GET'
  },
  FETCH_FUND_TRACKER_BENCHMARK_PERFORMANCE: {
    'path': '/api/v1/reports/fundTrackerBenchmarkPerformance',
    'method': 'POST'
  },
  FETCH_FUND_TRACKER_EXPORT_DATA: {
    'path': '/api/v1/reports/fundTracker/export',
    'method': 'POST'
  },
  FETCH_FUND_TRACKER_BENCHMARK_EXPORT_DATA: {
    'path': '/api/v1/reports/fundTrackerBenchmark/export',
    'method': 'POST'
  },
};
