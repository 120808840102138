import React from 'react';
import FundTracker from '../../modules/FundTracker';
import './index.scss';

const FundTrackerPage = props => (
  <div className={'js-main-content main-container__content fund-tracker'}>
    <FundTracker {...props} />
  </div>
);

export default FundTrackerPage;
