import React, { useRef, useEffect, useState, useCallback, memo } from 'react';
import throttle from 'lodash/throttle';
import PropTypes from 'prop-types';
import translator from '../../../../../../services/translator';

const { translate: t } = translator;

const AccountTooltip = ({ tooltipHoverRef, onTooltipClick }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [position, setPosition] = useState({});
  const ref = useRef({ isVisible: false });
  const tooltipContainerRef = useRef();

  const onMousemove = useCallback(event => {
    const { target } = event;
    if (
      target.className.includes('show-tooltip') ||
      (tooltipContainerRef.current && tooltipContainerRef.current.contains(event.target))
    ) {
      if (!ref.current.isVisible) {
        let cellWidth = 0;
        const updateParentWidth = parentElement => {
          if (parentElement && parentElement.className.includes('ag-cell')) {
            cellWidth = parentElement.getBoundingClientRect().width;
          } else {
            updateParentWidth(parentElement.parentElement);
          }
        };
        const parentPos = tooltipHoverRef.current.getBoundingClientRect();
        const childPos = target.getBoundingClientRect();
        updateParentWidth(target);
        const top = childPos.top - parentPos.top - 29;
        const left = childPos.left - parentPos.left + childPos.width;
        const updatedLeft = left > parentPos.width ? parentPos.width - 300 : left > cellWidth ? cellWidth : left;
        setIsVisible(true);
        setPosition({ top, left: updatedLeft });
        ref.current.isVisible = true;
      }
    } else {
      if (ref.current.isVisible) {
        setIsVisible(false);
        ref.current.isVisible = false;
      }
    }
  }, []);

  useEffect(() => {
    const throttleMousemove = throttle(onMousemove, 10);

    if (tooltipHoverRef.current) {
      tooltipHoverRef.current.addEventListener('mousemove', throttleMousemove);
    }
    // cleans up event listeners by removing them when the component is unmounted
    return () => {
      if (tooltipHoverRef.current) {
        tooltipHoverRef.current.removeEventListener('mousemove', throttleMousemove);
      }
    };
  }, []);

  return (
    <div ref={tooltipContainerRef}>
      {isVisible && (
        <div
          onClick={onTooltipClick}
          className='account-tooltip'
          style={{
            top: position.top,
            left: position.left,
          }}>
          <div className='account-tooltip__content' dangerouslySetInnerHTML={{ __html: t('tkFundExcludedTooltip') }} />
        </div>
      )}
    </div>
  );
};

AccountTooltip.propTypes = {
  tooltipHoverRef: PropTypes.object,
  onTooltipClick: PropTypes.func,
};

export default memo(AccountTooltip);
