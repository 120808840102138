import { ClassNamesArg, Emotion } from '@emotion/css/types/create-instance';
import { getEmotionInstance } from '../emotion';
import { CssProperties } from '../types';
import { type StyleEngine } from './style-engine';

/**
 * An implementation of StyleEngine for use in a browser.
 *
 * Inserts CSS and external stylesheets directly into the DOM.
 */
export class BrowserStyleEngine implements StyleEngine {
    /**
     * @param [emotion] A particular Emotion instance to use, or otherwise uses
     *   the global Emotion instance. BrowserStyleEngine always defaults to use
     *   the global Emotion instance to make sure we don't have multiple caches
     *   or <style> tags which, when moved by multiple Emotion instances being
     *   created, lose all of their style rules. See the comment in emotion.ts
     *   for details. However, this parameter is here for tests.
     */
    constructor(private emotion: Emotion = getEmotionInstance()) {}

    /**
     * Inserts global CSS into the document.
     *
     * Example:
     *
     *    styleEngineImpl.insertGlobalCss({
     *        'body': {
     *            backgroundColor: 'blue'
     *        }
     *    });
     */
    public insertGlobalCss(selectors: { [selector: string]: CssProperties }): void {
        this.emotion.injectGlobal(selectors);
    }

    /**
     * Inserts CSS into the document and returns the generated CSS class name.
     *
     * Example:
     *
     *    const className = styleEngineImpl.insertCss({ color: 'red' });
     */
    public insertCss(cssProperties: CssProperties): string {
        return this.emotion.css(cssProperties);
    }

    /**
     * Inserts an external StyleSheet into the document by its URL.
     *
     * Example:
     *
     *    styleEngineImpl.insertExternalStyleSheet('https://cdn.gs.com/fonts/notosansjp/v52/noto-sans-jp-300-400-500.css');
     */
    public insertExternalStyleSheet(url: string): void {
        // First, make sure that a <link> tag doesn't already exist for the font
        // we're about to inject
        const exists = !!document.querySelector(`link[rel="stylesheet"][href="${url}"]`);

        // Only inject new <link> element if it doesn't already exist
        if (!exists) {
            const linkEl = document.createElement('link');
            linkEl.rel = 'stylesheet';
            linkEl.href = url;
            document.head.appendChild(linkEl);
        }
    }

    /**
     * "ClassNames" function for combining one or more CSS classes together.
     *
     * When given multiple CSS-in-JS class names, creates a single (new) class
     * name which combines their styles, in the correct order.
     *
     * If given any non-CSS-in-JS class names, their names will simply be
     * inserted into the returned string, but their style properties will depend
     * on the usual CSS specificity rules (selector precedence, source order,
     * `!important`, etc.)
     */
    public cx(...classNames: ClassNamesArg[]): string {
        return this.emotion.cx(...classNames);
    }
}
