import React, { useState, useEffect, useCallback, useMemo, memo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { orderBy } from 'lodash';
import useDashBoard from '../../../../hooks/useDashBoard';
import useFirmToOrganizationMapping from '../../../../hooks/useFirmToOrganizationMapping';
import CardComponent from '../../../DashboardCards/Card';
import FirmOrganizationMappingModal from '../../../FirmOrganizationMappingModal';
import { UNMAPPED_FIRMS } from '../../../../constants';
import translator from '../../../../../../../services/translator';
import MaintenanceAlertsBody from './MaintenanceAlertsBody';
import MaintenanceAlertsHeader from './MaintenanceAlertsHeader';
import { userGuidSelector } from '../../../../../../../selectors/user';
import { dispatchAnalytics, unmappedFirmAlertsClick } from '../../../../analytics';

const RowRenderer = memo(({ priority, displayValue: { firmName, firmCode } }) => {
  const { translate: t } = translator;
  const title = t('tkUnmappedFirmAlertMessage', firmName, firmCode);
  return (
    <>
      <div className='dbcard__bodyRowText' title={title}>
        {title}
      </div>
      <span className={`dbcard__bodyRow${priority}Icon`} />
    </>
  );
});

RowRenderer.propTypes = {
  priority: PropTypes.string,
  displayValue: PropTypes.object,
};

const MaintenanceAlerts = ({ moduleName, reduxDispatch, userGuid }) => {
  const [firmData, setFirmData] = useState(null);
  const { onMapFirmToOrganization, success } = useFirmToOrganizationMapping(reduxDispatch);

  const defaultFilters = useMemo(
    () => [
      { name: 'type', selected: 'All Alerts' },
      { name: 'priority', selected: 'All Priorities' },
    ],
    []
  );

  const { loading, error, activeFilters, onFiltersUpdate, cardRows, reFetchDashBoardData } = useDashBoard(
    moduleName,
    defaultFilters,
    userGuid,
    reduxDispatch
  );

  const onRowClick = useCallback(({ type, displayValue: { firmName, firmCode, id } }) => {
    if (type === UNMAPPED_FIRMS) {
      setFirmData({ firmName, firmCode, id });
      dispatchAnalytics(reduxDispatch, unmappedFirmAlertsClick({ firmAlertName: `${firmName} (${firmCode})`, guid: userGuid }));
    }
  }, []);

  useEffect(() => {
    if (success) {
      reFetchDashBoardData();
    }
  }, [success, reFetchDashBoardData]);

  const closeModal = useCallback(() => {
    setFirmData(null);
  }, []);

  const sortedCardRows = useMemo(() => {
    return orderBy(
      cardRows,
      [row => row.displayValue?.modifiedAt?.split('T')[0], row => row.displayValue?.firmName, row => row.displayValue?.firmCode],
      ['desc', 'asc', 'asc']
    );
  }, [cardRows]);

  return (
    <>
      <CardComponent>
        <MaintenanceAlertsHeader loading={loading} activeFilters={activeFilters} onFiltersUpdate={onFiltersUpdate} />
        <MaintenanceAlertsBody loading={loading} error={error} sortedCardRows={sortedCardRows} RowRenderer={RowRenderer} onRowClick={onRowClick} />
      </CardComponent>
      {!!firmData && (
        <FirmOrganizationMappingModal
          closeModal={closeModal}
          firmData={firmData}
          onMapFirmToOrganization={onMapFirmToOrganization}
          reduxDispatch={reduxDispatch}
        />
      )}
    </>
  );
};

MaintenanceAlerts.propTypes = {
  moduleName: PropTypes.string,
  reduxDispatch: PropTypes.func,
  userGuid: PropTypes.string,
};

const mapStateToProps = state => ({
  userGuid: userGuidSelector(state),
});

const mapDispatchToProps = dispatch => ({
  reduxDispatch: dispatch,
});

export default memo(connect(mapStateToProps, mapDispatchToProps)(MaintenanceAlerts));
