import { combineReducers } from 'redux';

import fundTrackerReducer from '../modules/FundTracker/store/fund-tracker-slice';
import {appContextReducer, appDataReducer} from './app/index';
import {pageContextReducer, pageDataReducer} from './page/index';
import {preferencesReducer} from './preferences/index';
import {userReducer} from './user/index';
import { getAllGridReducers } from '../modules/Grid/reducer';
import { MODULES_KEYS } from '../constants/pageConstants';

const gridModuleReducerKeys = [
  MODULES_KEYS.CURRENT_TRADES,
  MODULES_KEYS.FUND_TRACKER_COMPARE_DATA_GRID,
  MODULES_KEYS.USER_SEARCH
];

const reducers = {
  appData: appDataReducer,
  appContext: appContextReducer,
  preferences: preferencesReducer,
  user: userReducer,
  pageData: pageDataReducer,
  pageContext: pageContextReducer,
  modules: combineReducers({
    ...getAllGridReducers(gridModuleReducerKeys),
    // fund tracker now uses RTK
    fundTracker: fundTrackerReducer
  })
};

export default reducers;
