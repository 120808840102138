import { useCallback } from 'react';
import { cloneDeep } from 'lodash';
import useUserMaintenance from '../../context';
import useSnackbar from '../useSnackbar';
import useFetch from '../useFetch';
import useLoader from '../useLoader';
import endPointsMapper from '../../../../../configs/endPointsMapper';
import { SAVE_ORG_CHANGES } from '../../actions';
import { dispatchAnalytics, userMaintenanceError } from '../../analytics';
import translator from '../../../../../services/translator';

const { translate: t } = translator;

const useSaveOrgDetails = () => {
  const {
    dispatch: ctxDispatch,
    state: {
      organizationDetails: { id },
      activeTab,
    },
    reduxDispatch,
  } = useUserMaintenance();
  const { openSnackbar } = useSnackbar();

  const onSuccess = useCallback(() => {
    openSnackbar({ type: 'success', message: t('tkChangesSavedSuccessfully') });
    ctxDispatch({ type: SAVE_ORG_CHANGES });
  }, []);

  const onError = useCallback(() => {
    openSnackbar({ type: 'error', message: t('tkOrgSaveErrorMsg') });
    setTimeout(() => {
      dispatchAnalytics(reduxDispatch, userMaintenanceError({ pageKey: 'Organization', errorMsg: t('tkOrgSaveErrorMsg'), tabName: activeTab }));
    }, 1000);
  }, []);

  const endpoint = cloneDeep(endPointsMapper.EDIT_SAVE_ORG_DETAILS);
  endpoint.path = `${endpoint.path}/${id}`;
  const { doFetch, loading } = useFetch(endpoint, onSuccess, onError);
  useLoader(loading);

  return { onSaveOrgDetails: doFetch };
};

export default useSaveOrgDetails;
