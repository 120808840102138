import React, { useRef, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Highstock from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import { displayChartMessage, handleClickedPoint } from './helpers';
import { chartColors } from './constants';
import translator from '../../../services/translator';
import { registerChart } from '../../../modules/FundTracker/store/fund-tracker-slice';

const { translate: t } = translator;

const StockChart = ({
  chartMessages = {},
  isLoading,
  onPointClicked,
  options = {},
  updateDateRange,
  updateZoomRange,
  chartExtremes
}) => {
  const chartRef = useRef(null);
  const dispatch = useDispatch();
  const [minDate, maxDate] = chartExtremes;
  const [hasChartInitialized, setHasChartInitialized] = useState(false);

  const { missingDataMsg } = chartMessages;
  const loadingMsg = `${t('tkLoading')}...`;

  useEffect(() => {
    if (chartRef.current && chartRef.current.chart) {
      setHasChartInitialized(true);
    }
  }, [chartRef.current]);

  useEffect(() => {
    if (hasChartInitialized && maxDate) {
      chartRef.current.chart.xAxis[0].setExtremes(maxDate - 3600 * 1000 * 24 * 90, maxDate);
    }
  }, [minDate, maxDate, hasChartInitialized]);

  // note: chartRenderer is used for highcharts, and since we need to access `this`, fn cannot be arrow-function
  function chartRenderer() {
    const chartInstance = this;

    if (chartInstance) {
      // Remove any existing message
      if (chartInstance.noDataLabel) {
        chartInstance.noDataLabel.destroy();
        chartInstance.noDataLabel = null;
      }

      if (isLoading) {
        displayChartMessage.call(chartInstance, loadingMsg);
      } else if (missingDataMsg) {
        displayChartMessage.call(chartInstance, missingDataMsg);
      }
    }
  };

  useEffect(() => {
    // Register the chart with the store on initial render
    if (hasChartInitialized) {
      dispatch(registerChart(chartRef.current.chart));
    }
  }, [hasChartInitialized]);

  // Add a render event to the chart options
  const updatedOptions = {
    ...options,
    chart: {
      ...options?.chart,
      events: {
        render: chartRenderer
      }
    },
    plotOptions: {
      series: {
        cursor: 'pointer',
        dataGrouping: {
          enabled: false
        },
        point: {
          events: {
            /* eslint-disable object-shorthand */
            click: function (e) {
              handleClickedPoint.call(this, e, onPointClicked);
            }
          }
        }
      }
    },
    colors: chartColors,
    xAxis: {
      events: {
        setExtremes: event => {
          const { trigger } = event;
          if (trigger === 'rangeSelectorButton' && updateZoomRange) {
            updateZoomRange(event);
          } else if (trigger === 'rangeSelectorInput' || (trigger === 'navigator' && updateDateRange)) {
            updateDateRange(event);
          }
        }
      },
      ...options?.xAxis
    }
  };

  return (
    <HighchartsReact
      ref={chartRef}
      highcharts={Highstock}
      constructorType={'stockChart'}
      options={updatedOptions}
    />
  );
};

StockChart.propTypes = {
  options: PropTypes.object,
  chartMessages: PropTypes.shape({
    missingDataMsg: PropTypes.string
    // ...any addl message which we can show on the chart
  }),
  onPointClicked: PropTypes.func,
  isLoading: PropTypes.bool,
  updateZoomRange: PropTypes.func,
  updateDateRange: PropTypes.func,
  chartExtremes: PropTypes.array
};

// only rerender when option or selected metric changed
const areEqual = (prevProps, nextProps) => {
  return (
    isEqual(prevProps.options, nextProps.options) &&
    isEqual(prevProps.chartMessages, nextProps.chartMessages) &&
    isEqual(prevProps.isLoading, nextProps.isLoading) && 
    isEqual(prevProps.chartExtremes, nextProps.chartExtremes)
  );
};

export default React.memo(StockChart, areEqual);
