import React from 'react';
import PropTypes from 'prop-types';
import {REBATE_CONSTANTS} from '../../../../../constants/pageConstants';
import translator from '../../../../../services/translator';
import AmountInput from '../AmountInput';
import {Conditional} from '../../../../core/Conditional';
import Dropdown from '../Dropdown';
import DatePicker from '../DatePicker';
import {formatFieldContent} from '../utils';

const { translate: t } = translator;
const {INPUT, PAYMENT_DATE_FROM, PAYMENT_DATE_TO, DROPDOWN, DATE} = REBATE_CONSTANTS;

const ItemEdit = ({
  value, labels, handleEditFields,
  field, fieldType, errorState
}) => {
  const paymentDateOptions = () => {
    const paymentDateOptions = [];
    for (let i = PAYMENT_DATE_FROM; i <= PAYMENT_DATE_TO ; i++) {
      paymentDateOptions.push({
        label : formatFieldContent("paymentDate", i, labels),
        value: i
      });
    }
    return paymentDateOptions;
  };

  const dropdownData = {
    investmentOption: [
      {
        value: 'Cash',
        label: t('tkCash'),
      },
      {
        value: 'Reinvest',
        label: t('tkReinvest'),
      }
    ],
    paymentDate: paymentDateOptions()
  };

  return (
    <span className="factData-item-edit">
      <Conditional condition={fieldType === INPUT}>
        <AmountInput
          value={value}
          field={field}
          handleEditFields={handleEditFields}
          errorState={errorState}
          labels={labels}
        />
      </Conditional>
      <Conditional condition={fieldType === DROPDOWN}>
        <Dropdown
          value={value}
          field={field}
          handleEditFields={handleEditFields}
          options={dropdownData[field]}
        />
      </Conditional>
      <Conditional condition={fieldType === DATE}>
        <DatePicker
          value={value}
          field={field}
          handleEditFields={handleEditFields}
          labels={labels}
        />
      </Conditional>
    </span>
  );
};

ItemEdit.propTypes = {
  value:  PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  labels: PropTypes.object,
  handleEditFields: PropTypes.func,
  field: PropTypes.string,
  fieldType: PropTypes.string,
  errorState: PropTypes.number
};

export default ItemEdit;
