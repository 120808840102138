import endPointsMapper from '../../../../../configs/endPointsMapper';
import useFetch from '../useFetch';

const useFetchInvestmentPolicyData = () => {

  const {
    doFetch: doFetchInvestmentPolicies,
    loading,
    data: investmentPolicyData,
    success,
    error
  } = useFetch(endPointsMapper.FETCH_ALL_INVESTMENT_POLICIES);

  return { doFetchInvestmentPolicies, loading, investmentPolicyData, success, error };
};

export default useFetchInvestmentPolicyData;
