import { Icon } from '@gs-ux-uitoolkit-react/icon-font';
import React from 'react';

const investmentPolicyTitle = (policyName) => {
  return (
    <div className='dbcard__investment-policy-title'>
      <Icon
        name='verified-user'
        type='filled'
        className='dbcard__investment-policy-title-icon'
      />
      {policyName}</div>
  );
};

export default investmentPolicyTitle;
