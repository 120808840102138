import { ConsumerProps, FC, PropsWithChildren, createContext, useContext } from 'react';
import { StyleEngine, getStyleEngine } from '@gs-ux-uitoolkit-common/style';

/*
 * When run on a browser, the UITK style implementation uses a singleton Emotion
 * instance stored on the global object. This utilizes a single shared cache for
 * all UITK components of potentially different UITK versions.
 *
 * However on Next.js's App Router, we need an Emotion instance per-request in
 * order to collect the styles for that particular request. And the only way to
 * provide this to the rest of the app for a given request is through React
 * Context. This is the context for that.
 *
 * This defaults to the global StyleEngine instance for the Next.js
 * "Pages router", and for users using the Next.js "App Router", this context
 * will be provided with a new StyleEngine instance on a per-request basis that
 * has a new Emotion instance as well.
 */

// The React context that provides the StyleEngine object to the React tree
const StyleEngineContext = createContext<StyleEngine>(getStyleEngine());

export type StyleEngineProviderProps = PropsWithChildren<{
    styleEngine: StyleEngine;
}>;

export const StyleEngineProvider: FC<StyleEngineProviderProps> = ({ styleEngine, children }) => {
    return (
        <StyleEngineContext.Provider value={styleEngine}>{children}</StyleEngineContext.Provider>
    );
};

export const StyleEngineConsumer: FC<ConsumerProps<StyleEngine>> = ({ children }) => {
    return <StyleEngineContext.Consumer>{children}</StyleEngineContext.Consumer>;
};

/**
 * Retrieves the StyleEngine object from the react tree.
 */
export function useStyleEngine(): StyleEngine {
    return useContext(StyleEngineContext);
}
