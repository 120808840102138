import React, {useMemo} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {
  selectCollapseState, preDefinedSortingState, getCurrentViewsKeys,
  gridHeader, currentViewSelector, getGridData, noRowDataSelector,
  selectedGroup
} from '../../../../../selectors/pages/fundTracker/index';
import GridModule from '../../../../../modules/Grid';
import { gridData, startExcelExport } from '../../../../Grid/selector';
import { sortWithFlatDataWithGrouping } from '../../../../../utils/sorting';
import {isOpenFileDownloadModal} from "../../../../../selectors/app/reports";

export const FundTrackerGrid = (props) => {
  const groupRowAggNodes = data => () => data;

  const gridConfig = {
    groupRowAggNodes: groupRowAggNodes(props.currentViewsKeys)
  };

  const sortFunction = (params, allProps) => {
    const { sortedMeta, selectedRows } = params;
    return sortWithFlatDataWithGrouping(
      props.gridRawData,
      sortedMeta,
      selectedRows,
      allProps.currentView.groupBy
    );
  };

  const {columnDefs, isOpenFileDownloadModal, ...restProps} = props;

  // Below config needed for PDF print, this can be removed once we implement full proof solution later,
  // this config is used along with media print css to display the grid contents properly in exported PDF
  const updatedColumnDefs = useMemo(() => {
    if (isOpenFileDownloadModal) {
      const customWidth = {
        shareClassName: 33,
        wam: 10,
        wal: 10,
        cusip: 13,
        mtmNav: 10,
        dailyFactor: 10
      }
      return columnDefs.map(col => (
        {...col, minWidth: 1, width: (customWidth[col.field] || 12)}
      ));
    }
    return columnDefs;
  }, [isOpenFileDownloadModal, columnDefs]);

  const updatedProps = {
    ...restProps,
    domLayout: 'autoHeight',
    columnDefs: updatedColumnDefs
  };

  return (
    <div className="compareDataGrid">
      <GridModule
        gridConfig={gridConfig}
        sortFunction={sortFunction}
        {...updatedProps}
      />
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { id } = ownProps;
  return {
    collapseState: selectCollapseState(state, id),
    currentViewsKeys: getCurrentViewsKeys(state, id),
    columnDefs: gridHeader(state, id),
    data: getGridData(state, id),
    currentView: currentViewSelector(state, id),
    preDefinedSortingState: preDefinedSortingState(state, id),
    gridRawData: gridData(state, id),
    startExcelExport: startExcelExport(state, id),
    noRowDataSelector: noRowDataSelector(state, id),
    selectedGroup : selectedGroup(state, id),
    isOpenFileDownloadModal: isOpenFileDownloadModal(state)
  };
};

FundTrackerGrid.propTypes = {
  collapseState: PropTypes.bool,
  currentViewsKeys: PropTypes.object,
  gridRawData: PropTypes.array,
  columnDefs: PropTypes.array,
  data: PropTypes.array,
  currentView: PropTypes.object,
  preDefinedSortingState: PropTypes.array,
  startExcelExport: PropTypes.object,
  noRowDataSelector: PropTypes.string,
  selectedGroup: PropTypes.object,
  isOpenFileDownloadModal: PropTypes.bool
};

export default connect(mapStateToProps)(FundTrackerGrid);
