import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment/moment';
import {controlPanleMapDispatchToProps} from '../../../../Grid/mapDispatchToProps';
import {allowPDFDownloadFeatureSelector} from '../../../../../selectors/global';
import Link from "../../../../../components/core/Link";
import translator from '../../../../../services/translator';
import {FUND_TRACKER} from "../../../constants";
import {Conditional} from "../../../../../components/core/Conditional";
import { fundTrackerStateProps } from '../../../store/helpers';

const {translate: t} = translator;

export const ControlPanel = props => {
  const {allowPDFDownload, dispatchDownloadExcel, dispatchOpenFileDownloadModal, asOfDate, allShareClassData, selection } = props;

  const handleDownloadExcel = () => {
    dispatchDownloadExcel(props);
  };

  const handleOpenFileDownloadModal = () => {
    dispatchOpenFileDownloadModal(props);
  };

  const handleDownload = () => {
    if (allowPDFDownload) {
      handleOpenFileDownloadModal();
    } else {
      handleDownloadExcel();
    }
  };

  const showCardComponent = () => {
    const isMetricsCompareMode = (selection === FUND_TRACKER.METRICS);
    return !selection || isMetricsCompareMode || allShareClassData.length <= 1;
  };

  return (
    <>
      <div className="download">
        <span className="download__icon" />
        <Link
          label={t('tkDownloadData')}
          extraProps={{'aria-label': 'download-link'}}
          handleClick={handleDownload}
        />
      </div>
      <Conditional condition={!showCardComponent()}>
        <div className="asOfDate" aria-label="asOfDate">
          {`${t('tkAsOf')} ${moment(asOfDate).format('DD.MMM.YYYY')}`}
        </div>
      </Conditional>
    </>
  );
};


ControlPanel.propTypes = {
  dispatchDownloadExcel: PropTypes.func,
  dispatchOpenFileDownloadModal: PropTypes.func,
  allowPDFDownload: PropTypes.bool,
  asOfDate: PropTypes.string,
  allShareClassData: PropTypes.array,
  selection: PropTypes.string
};

export const mapStateToProps = (state) => ({
  allowPDFDownload: allowPDFDownloadFeatureSelector(state),
  ...fundTrackerStateProps(state)
});

export default connect(mapStateToProps, controlPanleMapDispatchToProps)(ControlPanel);
