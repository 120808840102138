import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Input } from '@gs-ux-uitoolkit-react/input';
import { Icon } from '@gs-ux-uitoolkit-react/icon-font';
import { Button } from '@gs-ux-uitoolkit-react/button';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { CHANGE, DELETE } from '../../constants';
import translator from '../../../../../services/translator';

const { translate: t } = translator;

const InputWithSwitch = props => {
  const ref = useRef();
  const { value, errorState = {}, fieldIndex, clientName, handleChange } = props;

  const alias = value || '';

  const [tsInput, setTsInput] = useState(alias);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (errorState) {
      setErrorMessage(errorState.errorMessage);
    }
  }, [errorState]);

  useEffect(() => {
    setTsInput(alias);
  }, [alias]);

  const onDeleteClick = useCallback(() => {
    handleChange({
      event: DELETE,
      clientName,
      errorMessage,
      fieldIndex,
    });
  }, [clientName, fieldIndex, errorMessage]);

  const onBlur = useCallback(() => {
    handleChange({
      event: CHANGE,
      clientName,
      errorMessage,
      fieldIndex,
      alias: tsInput,
    });
  }, [tsInput, clientName, errorMessage]);

  const onTsInputChange = useCallback(
    event => {
      setTsInput(event.target.value);
      handleChange({
        clientName,
      });
    },
    [clientName]
  );

  return (
    <div className='textswitch-container'>
      <div className='input-edit' ref={ref}>
        <Input
          size='md'
          className={cn(
            'textswitch-container__text',
            { 'textswitch-container__default': !errorMessage },
            { 'textswitch-container__error': errorMessage }
          )}
          value={tsInput}
          placeholder={t('tkIdentifier')}
          onChange={onTsInputChange}
          onBlur={onBlur}
          status={errorMessage ? 'error' : 'none'}
          disabled={!clientName}
          maxLength={50}
        />
        {errorMessage && (
          <span className='error-message' data-testid='text-errorMessage'>
            {errorMessage}
          </span>
        )}
      </div>
      <Button size='sm' className='textswitch-container__removeField' onClick={onDeleteClick} actionType='primary' emphasis='minimal'>
        <Icon name='remove-circle' type='filled' style={{ color: 'rgb(217, 31, 17)' }} size='sm' />
      </Button>
    </div>
  );
};

InputWithSwitch.propTypes = {
  handleChange: PropTypes.func,
  value: PropTypes.object,
  errorState: PropTypes.object,
  fieldIndex: PropTypes.number,
  clientName: PropTypes.string,
};

export default InputWithSwitch;
