import { useCallback, useMemo } from 'react';
import { cloneDeep } from 'lodash';
import endPointsMapper from '../../../../../configs/endPointsMapper';
import useFetch from '../useFetch';

const useGetUserDetailsById = () => {
  const endPointConfig = useMemo(() => cloneDeep(endPointsMapper.FETCH_USER_SEARCH_DETAIL), [endPointsMapper]);

  const { doFetch, loading } = useFetch(endPointConfig);

  const getUserDetailsById = useCallback(
    async id => {
      endPointConfig.path = `${endPointsMapper.FETCH_USER_SEARCH_DETAIL.path}/${id}`;
      return doFetch();
    },
    [endPointConfig]
  );

  return { getUserDetailsById, loading };
};

export default useGetUserDetailsById;
