import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import CardComponent from '../../../DashboardCards/Card';
import InvestmentPolicyFooter from './InvestmentPolicyFooter';
import MultiOrgInvestmentPolicyBody from './MultiOrgInvestmentPolicyBody';
import { getAllPermitRoles } from '../../../../../../../selectors/pages/reports';
import InvestmentPolicyHeader from './InvestmentPolicyHeader';

const MultiOrgInvestmentPolicies = (props) => {
  const IS_SINGLE_ORG = false;

  return (
    <CardComponent>
      <InvestmentPolicyHeader permissions={props.permissions} isSingleOrg={IS_SINGLE_ORG}/>

      <MultiOrgInvestmentPolicyBody organizationId={props.organizationId} />

      <InvestmentPolicyFooter />
    </CardComponent>
  );
};

MultiOrgInvestmentPolicies.propTypes = {
  organizationId: PropTypes.string,
  permissions: PropTypes.array
};

const mapStateToProps = state => ({
  permissions: getAllPermitRoles(state)
});

export default connect(mapStateToProps)(MultiOrgInvestmentPolicies);
