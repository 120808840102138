import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { Icon } from '@gs-ux-uitoolkit-react/icon-font';
import { Button } from '@gs-ux-uitoolkit-react/button';
import { FUND_TRACKER, PLOTLINE_ID } from '../../constants';
import SimpleSelect from '../../../../components/core/Select/Simple';
import SearchMetrics from '../SearchMetrics';
import { simpleRenderer } from './renderer';
import { useAsync } from '../../utils/hooks';
import { Conditional } from '../../../../components/core/Conditional';
import {
  updateChartSeries,
  setMetricBenchmarksToState,
  setFieldForQuery,
  updateChartOptions,
  getUpdateSeriesWithNameAndLegend,
  isBenchmarksSeriesAlreadyExists,
  getAsOfDate,
  getShareclassesAndBenchmarks
} from '../../utils';
import {addMetricToSeries, fetchAllPerformanceData, fetchChartDataForSelectedProducts, fetchFundsOrBenchmarksData} from '../../services';
import { fundTrackerStateProps } from '../../store/helpers';

const ChartDropdown = ({
  // component props
  allMetricOptions, benchmarkMetricsOptions, primaryMetricFromFundFinderOrPreference, selectedTabMetricsOptions,
  // redux props
  metrics: selectedMetrics = [], benchmarks: selectedBenchMarks = [], allShareClassData,
  chartInstance, chartOptions, primaryProduct, selection, isApplyRebates
}) => {
  const chartDispatch = useDispatch();
  const [primaryMetricItem, compareMetricItem] = selectedMetrics;
  // TODO: we will be moving to RTK query in another iteration
  const { run } = useAsync(chartDispatch);
  const isMetricsCompareMode = selection === FUND_TRACKER.METRICS;

  const defaultMetricItem = useMemo(() => {
    return selectedTabMetricsOptions.find(item => {
      if (primaryMetricFromFundFinderOrPreference) {
        return item.enum === primaryMetricFromFundFinderOrPreference;
      }
      return item.default;
    });
  }, [selectedTabMetricsOptions, primaryMetricFromFundFinderOrPreference]);

  const getSelectedMetricOption = () => {
    if (!primaryMetricItem) {
      return defaultMetricItem;
    } else if (!isMetricsCompareMode) {
      return primaryMetricItem;
    }
    return compareMetricItem || primaryMetricItem;
  };

  const metricOptions = useMemo(() => {
    return selectedTabMetricsOptions.filter(item => {
      if (isMetricsCompareMode && compareMetricItem) {
        return compareMetricItem.value !== item.value && primaryMetricItem.value !== item.value;
      }
      return !item.isHideFromUI;
    });
  });

  const updateMetricItems = (
    metricItemToRemove,
    metricItemToAddToSeries,
    metricItemToAddToState,
    selection,
    fetchMetricDetailsFromDB = true
  ) => {
    // Remove the previous metrics from chart dropdown.
    // TODO: Convert util to take object arguments
    const updatedChartOptions = updateChartSeries(
      metricItemToRemove,
      [],
      { chartOptions, chartInstance, isApplyRebates, primaryProduct },
      undefined
    );
    // Set axis direction to opposite, if we are adding compare item
    const addOppositeAxis = !compareMetricItem;
    // Add the selected metrics item to the series
    if (fetchMetricDetailsFromDB) {
      // TODO: Convert util to take object arguments
      addMetricToSeries(
        metricItemToAddToSeries,
        run,
        chartDispatch,
        updatedChartOptions,
        { primaryProduct, isApplyRebates, allShareClassData },
        selectedMetrics,
        addOppositeAxis
      );
    } else {
      // Get the default series updated with name and legend
      const chartOptionsWithSeriesUpdates = {
        ...updatedChartOptions,
        series: getUpdateSeriesWithNameAndLegend(
          updatedChartOptions.series,
          allShareClassData,
          primaryProduct,
          undefined,
          isApplyRebates
        )
      };
      updateChartOptions(chartDispatch, chartOptionsWithSeriesUpdates);
    }
    // Add the selected metric item to the state
    const updatedData = {
      metrics: metricItemToAddToState,
      selection
    };
    setMetricBenchmarksToState(chartDispatch, updatedData);
  };

  const updateBenchmarksItems = ({
    metricItemToRemove, metricItemToAddToSeries, metricItemToAddToState,
    selection, fetchMetricDetailsFromDB = true, benchmarks,
    isApplyRebates
  }) => {
    // Remove the previous metrics from chart dropdown.
    const updatedChartOptions = updateChartSeries(
      metricItemToRemove,
      benchmarks,
      { chartOptions, chartInstance, isApplyRebates, primaryProduct },
      metricItemToRemove.value
    );
    // Add the selected metrics item to the series
    const benchmarksSeriesAlreadyExists = isBenchmarksSeriesAlreadyExists(updatedChartOptions, benchmarkMetricsOptions);
    if (fetchMetricDetailsFromDB && !benchmarksSeriesAlreadyExists) {
      const benchmarksToFetch = [{ value: primaryProduct.value, type: primaryProduct.type }, ...benchmarks];
      const fieldForQuery = { fieldValue: metricItemToAddToSeries.value };
      fetchFundsOrBenchmarksData(
        run,
        fieldForQuery,
        benchmarkMetricsOptions,
        benchmarksToFetch,
        chartDispatch,
        updatedChartOptions,
        { chartInstance, allShareClassData, primaryProduct, isApplyRebates, benchmarks: selectedBenchMarks },
        false,
        metricItemToAddToSeries.label,
        selectedMetrics
      );
    } else {
      updateChartOptions(chartDispatch, updatedChartOptions);
    }
    // Add the selected metric item to the state
    const updatedData = {
      metrics: metricItemToAddToState,
      benchmarks,
      selection
    };
    setMetricBenchmarksToState(chartDispatch, updatedData);
  };

  /* Selection will be either benchmarks/metrics if the user is changing the
     metrics after performing comparison, otherwise it is null. */
  const handleSelection = useCallback(selectedOption => {
    if (
      (selection && compareMetricItem && selectedOption.value === compareMetricItem.value) ||
      (!selection && primaryMetricItem && selectedOption.value === primaryMetricItem.value)
    ) {
      return;
    }

    // when the user changes the metric, remove the plotline if it exists
    const mainAxisX = chartInstance?.xAxis[0];
    if (mainAxisX?.plotLinesAndBands?.length) {
      mainAxisX?.removePlotLine(PLOTLINE_ID);
      const asOfDate = getAsOfDate(chartOptions?.series[0]?.data);
      const { benchmarks, shareclasses } = getShareclassesAndBenchmarks(allShareClassData);
      fetchAllPerformanceData({ asOfDate, benchmarks, chartDispatch, run, shareclasses });
    }

    if (!isMetricsCompareMode) {
      setFieldForQuery(chartDispatch, selectedOption);
    }

    if (selectedOption && selectedOption.value) {
      if (!isMetricsCompareMode) {
        const metricItemToRemove = getSelectedMetricOption();
        const metricItemToAddToState = [selectedOption];
        updateBenchmarksItems({
          metricItemToRemove,
          metricItemToAddToSeries: selectedOption,
          metricItemToAddToState,
          selection: FUND_TRACKER.BENCHMARKS,
          fetchMetricDetailsFromDB: true,
          benchmarks: selectedBenchMarks,
          isApplyRebates
        });
      } else {
        const metricItemToRemove = compareMetricItem;
        const metricItemToAddToState = [...selectedMetrics.slice(0, 1), selectedOption];
        updateMetricItems(
          metricItemToRemove,
          selectedOption,
          metricItemToAddToState,
          FUND_TRACKER.METRICS
        );
      }
    }
  });

  const handleChange = (_, selectedMetric) => {
    handleSelection(selectedMetric);
  };

  const handleDeSelection = () => {
    const metricItemToAddToState = [primaryMetricItem];

    // Add the selected metric item to the state
    const updatedData = {
      metrics: metricItemToAddToState,
      selection: null
    };
    setMetricBenchmarksToState(chartDispatch, updatedData);

    const { label: fieldLabel, value: fieldValue } = primaryMetricItem;
    fetchChartDataForSelectedProducts({
      run,
      chartDispatch,
      primaryProduct,
      shareClassField: { fieldLabel, fieldValue },
      selectedFundsOrBenchmarks: selectedBenchMarks,
      isApplyRebates
    });
  };

  const selectedMetricsOption = getSelectedMetricOption();

  return (
    <div className='metricsDropdownContainer'>
      <Conditional condition={isMetricsCompareMode}>
        <div className='item-wrapper'>
          <span className='primaryMetrics'>{primaryMetricItem?.label || ''}</span>
          <span className='separator'>{'VS'}</span>
        </div>
      </Conditional>
      <div className='item-wrapper'>
        <Conditional condition={isMetricsCompareMode}>
          <SearchMetrics
            allMetricOptions={allMetricOptions}
            onSelectCompareItem={handleChange}
          />
          <SimpleSelect
            valueKey='value'
            selectedOption={selectedMetricsOption}
            isActive={true}
            isDisabled={!primaryProduct?.value}
            hasError={false}
            clickHandler={handleSelection}
            optionRenderer={simpleRenderer}
            labelKey='label'
            options={metricOptions}
            testId='test-metrics'
          />
        </Conditional>
        <Conditional condition={isMetricsCompareMode}>
          <Button actionType='contrast' emphasis='bold' onClick={handleDeSelection}>
            <Icon name='cancel' type='outlined' />
          </Button>
        </Conditional>
      </div>
    </div>
  );
};

ChartDropdown.propTypes = {
  // component props
  allMetricOptions: PropTypes.array,
  benchmarkMetricsOptions: PropTypes.array,
  primaryMetricFromFundFinderOrPreference: PropTypes.string,
  selectedTabMetricsOptions: PropTypes.array,
  // redux props
  allShareClassData: PropTypes.array,
  benchmarks: PropTypes.array,
  chartInstance: PropTypes.object,
  chartOptions: PropTypes.object,
  metrics: PropTypes.array,
  primaryProduct: PropTypes.object,
  selection: PropTypes.string,
  isApplyRebates: PropTypes.bool
};

export default connect(fundTrackerStateProps)(ChartDropdown);
