import React from 'react';
import { Icon } from '@gs-ux-uitoolkit-react/icon-font';
import { Tooltip } from '@gs-ux-uitoolkit-react/tooltip';

import translator from '../../../../../../../services/translator';

const { translate: t } = translator;

const iconMapper = ({currentRuleAction, panelIndex, ruleIndex}) => {
  const warnTooltipId = `tooltip-warn-${panelIndex}-${ruleIndex}`;
  const blockTooltipId = `tooltip-block-${panelIndex}-${ruleIndex}`;
  return (
    currentRuleAction === 'WARN' ? (
      <>
        <div id={warnTooltipId} className='dbcard__tooltip'>Warn<Icon
          size='sm'
          name='warning' type='filled'
          className='dbcard__warning-style' />
        </div>
        <Tooltip target={warnTooltipId}>
          {t('tkInvestmentPolicyWarnTooltip')}
        </Tooltip>
      </>
    ) : (
      <>
        <div id={blockTooltipId} className='dbcard__tooltip'>Block<Icon
          size='sm'
          name='block' type='outlined'
          className='dbcard__block-style' />
        </div>
        <Tooltip target={blockTooltipId}>
          {t('tkInvestmentPolicyBlockTooltip')}
        </Tooltip>
      </>
    )
  );
};

export default iconMapper;
