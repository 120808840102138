import { useCallback, useLayoutEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { pending, setErrorOrResolve } from '../store/fund-tracker-slice';

export const useSafeDispatch = dispatch => {
  const mounted = useRef(false);

  useLayoutEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  return useCallback((...args) => (mounted.current ? dispatch(...args) : undefined), [dispatch]);
};

export const useAsync = dispatchFunction => {
  let dispatch = dispatchFunction;
  const { dispatch: defaultDispatch } = useDispatch();

  if (!dispatch) {
    dispatch = defaultDispatch;
  }

  const run = useCallback(
    promise => {
      dispatch(pending());
      promise
        .then(data => {
          dispatch(setErrorOrResolve(data));
        })
        .catch(error => {
          dispatch(setErrorOrResolve(error));
        });
    },
    [dispatch]
  );

  return {
    run
  };
};
