import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import OrderedListItem from '../OrderedListItem';
import './index.scss';

const OrderedList = props => {
  const { list = [], undismissableIndex = 0, onDismissItem, disableDismissButton } = props;

  const handleItemDismissed = useCallback(id => {
    onDismissItem && onDismissItem(id);
  }, [onDismissItem]);

  if (list.length === 0) {
    return null;
  }

  return (
    <ol className='ordered-list'>
      {list.map((listItem, index) => (
        <OrderedListItem
          key={listItem.id || `li__${index}`}
          listItem={listItem}
          index={index}
          handleItemDismissed={handleItemDismissed}
          disableDismissButton={disableDismissButton}
          isUndismissable={index === undismissableIndex}
        />
      ))}
    </ol>
  );
};

OrderedList.propTypes = {
  list: PropTypes.array.isRequired,
  onDismissItem: PropTypes.func.isRequired,
  disableDismissButton: PropTypes.bool,
  undismissableIndex: PropTypes.number
};

export default OrderedList;
