import { useMemo } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import useFetch from '../useFetch';
import endPointsMapper from '../../../../../configs/endPointsMapper';

const useFetchInvestmentPolicyOfScopeAccounts = (organizationId) => {

  const url = useMemo(() => {
    const endpoint = cloneDeep(endPointsMapper.FETCH_OUT_OF_SCOPES_BY_ORGANIZATION);
    endpoint.path = `${endpoint.path}/${organizationId}`;
    return endpoint;
  }, []);

  const { doFetch: doFetchOfScopeAccounts, loading, data: ofScopeAccountsData, success, error } = useFetch(url);

  return { doFetchOfScopeAccounts, loading, ofScopeAccountsData, success, error };
};

export default useFetchInvestmentPolicyOfScopeAccounts;
