import { ClassNamesArg, Emotion } from '@emotion/css/types/create-instance';
import { getEmotionInstance } from '../emotion';
import { CssProperties } from '../types';
import { type StyleEngine } from './style-engine';

/**
 * An implementation of StyleEngine for use in a server-side rendering (SSR)
 * environment (i.e. on a Node.js server).
 */
export class SsrStyleEngine implements StyleEngine {
    private externalStyleSheets = new Set<string>();

    /**
     * @param [emotion] A particular Emotion instance to use, or otherwise uses
     *   the global Emotion instance.
     */
    constructor(private emotion: Emotion = getEmotionInstance()) {}

    /**
     * Inserts global CSS into the document.
     *
     * Example:
     *
     *    styleEngineImpl.insertGlobalCss({
     *        'body': {
     *            backgroundColor: 'blue'
     *        }
     *    });
     */
    public insertGlobalCss(selectors: { [selector: string]: CssProperties }): void {
        this.emotion.injectGlobal(selectors);
    }

    /**
     * Inserts CSS into the document and returns the generated CSS class name.
     *
     * Example:
     *
     *    const className = styleEngineImpl.insertCss({ color: 'red' });
     */
    public insertCss(cssProperties: CssProperties): string {
        return this.emotion.css(cssProperties);
    }

    /**
     * Inserts an external StyleSheet into the document by its URL.
     *
     * Example:
     *
     *    styleEngineImpl.insertExternalStyleSheet('https://cdn.gs.com/fonts/notosansjp/v52/noto-sans-jp-300-400-500.css');
     */
    public insertExternalStyleSheet(url: string): void {
        this.externalStyleSheets.add(url);
    }

    /**
     * Retrieves the list of external stylesheets added with
     * {@link #insertExternalStyleSheet}. See that method for details.
     */
    public getExternalStyleSheets(): readonly string[] {
        return Array.from(this.externalStyleSheets);
    }

    /**
     * "ClassNames" function for combining one or more CSS classes together.
     *
     * When given multiple CSS-in-JS class names, creates a single (new) class
     * name which combines their styles, in the correct order.
     *
     * If given any non-CSS-in-JS class names, their names will simply be
     * inserted into the returned string, but their style properties will depend
     * on the usual CSS specificity rules (selector precedence, source order,
     * `!important`, etc.)
     */
    public cx(...classNames: ClassNamesArg[]): string {
        return this.emotion.cx(...classNames);
    }
}
