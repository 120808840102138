import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from '@gs-ux-uitoolkit-react/modal';
import { Button } from '@gs-ux-uitoolkit-react/button';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import useUserMaintenance from '../../../context';
import {
  CLOSE_MODAL,
  DISCARD_CHANGES,
  DISCARD_ORG_CHANGES,
  EDIT_MODE_INACTIVE,
  ORG_EDIT_MODE_INACTIVE,
  UPDATE_ORGANIZATION_ACCOUNTS,
} from '../../../actions';
import { MODAL, ACTIVE } from '../../../constants';
import translator from '../../../../../../services/translator';
import { dispatchAnalytics, userDeactivateModal, unsavedChangesModalCTA } from '../../../analytics';
import './index.scss';

const { translate: t } = translator;

const UserDetailsModal = ({
  modalName,
  onBack,
  isBackBtnClicked,
  updateBackClickState,
  saveUserAccountStatus = {},
  accountsAlteration: {
    allowOrganizationSearch,
    sidePanel: { showAccountSidePanel, setShowAccountSidePanel } = {},
    setAccountDetails,
    setShowEntitledAccounts,
  } = {},
}) => {
  const {
    state: {
      modal: ctxModal,
      userDetails: { id, userAccountStatus, userGuid },
      organizationAccounts,
      isEditable,
      activeTab,
    },
    dispatch,
    reduxDispatch,
  } = useUserMaintenance();

  const { onSaveUserAccountStatus } = saveUserAccountStatus;

  const { display, header = t('tkWarning'), content } = ctxModal.find(({ name }) => name === modalName);

  const renderModalContent = () => {
    if (modalName === MODAL.deactivationConfirmation) {
      return userAccountStatus === ACTIVE ? t(content.deactivationContent) : t(content.activationContent);
    }
    return t(content);
  };

  const onProceedClick = () => {
    if (modalName === MODAL.unsavedChanges) {
      dispatch({ type: EDIT_MODE_INACTIVE, payload: { isEditable: false } });
      dispatch({ type: DISCARD_CHANGES });
      dispatch({ type: ORG_EDIT_MODE_INACTIVE, payload: { isOrgEditable: false } });
      dispatch({ type: DISCARD_ORG_CHANGES });
      dispatchAnalytics(
        reduxDispatch,
        unsavedChangesModalCTA({ pageKey: isEditable ? 'User' : 'Organization', btnName: 'Yes', guid: userGuid, tabName: activeTab })
      );
    } else if (modalName === MODAL.deactivationConfirmation) {
      onSaveUserAccountStatus({
        id,
        status: userAccountStatus === ACTIVE ? 'DEACTIVATE' : 'ACTIVATE',
      });
      dispatchAnalytics(reduxDispatch, userDeactivateModal({ btnName: 'Yes', guid: userGuid }));
    } else if (modalName === MODAL.allAccountsConfirmation) {
      if (allowOrganizationSearch) {
        !isEmpty(organizationAccounts) && dispatch({ type: UPDATE_ORGANIZATION_ACCOUNTS, payload: { operation: 'RESET' } });
        setAccountDetails([]);
      }
      showAccountSidePanel && setShowAccountSidePanel(false);
      setShowEntitledAccounts(prev => !prev);
    }
    dispatch({ type: CLOSE_MODAL, payload: { modalName } });
    if (isBackBtnClicked) {
      updateBackClickState();
      onBack();
    }
  };

  const handleClose = btnName => {
    dispatch({ type: CLOSE_MODAL, payload: { modalName } });
    dispatchAnalytics(
      reduxDispatch,
      modalName === MODAL.unsavedChanges
        ? unsavedChangesModalCTA({ btnName, guid: userGuid, tabName: activeTab, pageKey: isEditable ? 'User' : 'Organization' })
        : userDeactivateModal({ btnName, guid: userGuid })
    );
  };

  return (
    <Modal visible={display} placement='center' className='ssa-modal gs-uitk-override-css'>
      <ModalHeader onDismissButtonClick={() => handleClose('Close')} className='ssa-modal__header'>
        {header}
      </ModalHeader>
      <ModalBody className='ssa-modal__body'>{renderModalContent()}</ModalBody>
      <ModalFooter className='ssa-modal__footer'>
        <Button actionType='secondary' onClick={() => handleClose('No')}>
          {t('tkNo')}
        </Button>
        <Button actionType='primary' onClick={onProceedClick}>
          {t('tkYes')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

UserDetailsModal.propTypes = {
  modalName: PropTypes.string,
  onBack: PropTypes.func,
  isBackBtnClicked: PropTypes.bool,
  updateBackClickState: PropTypes.func,
  saveUserAccountStatus: PropTypes.object,
  accountsAlteration: PropTypes.object,
};

export default UserDetailsModal;
