import React from 'react';
import { CardFooter } from '@gs-ux-uitoolkit-react/card';
import translator from '../../../../../../../services/translator';

const { translate: t } = translator;
const InvestmentPolicyFooter = () => {
  return (
    <CardFooter className='dbcard__investment-policy-footer'>
      <a href={'#'} className="dbcard__bodyRowBold">
        {t('tkViewAllPolicies')}
        <span className={'dbcard__ArrowForward'} />
      </a>
    </CardFooter>
  );
};

export default InvestmentPolicyFooter;
