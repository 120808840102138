import translator from "../services/translator";

const { translate: t } = translator;

/**
 * Alternative to lodash's capitalize function.
 * e.g. 1, capitalizeString('hello world') => 'Hello world'
 * e.g. 2, capitalizeString('hello World') => 'Hello world'
 */
export const capitalizeString = inputString => {
  if (typeof inputString !== 'string' || !inputString || inputString.length === 0) return '';
  const outputString = inputString.toLowerCase().trim();
  return outputString.charAt(0).toUpperCase() + outputString.slice(1);
};

/** 
 * given labels array, util gets translated values for the labels 
 * and builds a final output string from each translated label.
 * utils accepts certain options to modify the output string.
 * e.g., titleCase: true, to convert the output string to title case.
 */
export const composeTranslatedLabels = (labels = [], options = {}) => {
  let result = labels.map(label => t(label) || label).join(' ');
  if (options.titleCase) {
    // capitalizes the first letter of each word in the output string.
    result = result.replace(/\w+/g, capitalizeString);
  }
  if (options.upperCase) {
    // converts the output string to uppercase.
    result = result.toUpperCase();
  }
  if (options.sentenceCase) {
    // capitalizes the first letter of the first word, typically used for sentences.
    result = capitalizeString(result);
  }
  return result;
};
