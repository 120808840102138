import React, { useState, useEffect, useMemo } from 'react';
import map from 'lodash/map';
import classNames from 'classnames';
import { Checkbox } from '@gs-ux-uitoolkit-react/checkbox';
import translator from '../../../../../../services/translator';
import { UPDATE_SELECTED_FUNDS, UPDATE_HIDE_EXCLUDED_FUNDS, FORCE_UPDATE_SELECTED_FUNDS } from '../../../actions';
import useUserMaintenance from '../../../context';
import withGrid from '../../../../../../components/hoc/withGrid';
import Grid from '../../../../../../components/core/Grid';
const { translate: t } = translator;

const selectedRowsComparator = (selectedRows, rowData) => {
  return !!selectedRows[rowData.id]
};
const FundGrid = () => {
  const {
    state: { userDetails, hideExcludedFunds, isEditable, showLoader },
    storeValues: { fundsColumnDefs },
    dispatch,
  } = useUserMaintenance();

  const { funds = {} } = userDetails;
  const { excludedList = [], includedList = [] } = funds;

  const excludedFundsData = useMemo(() => {
    if (Array.isArray(excludedList)) {
      const funds = {}
      excludedList.forEach((data) => {
        funds[data.id] = true
      })
      return funds
    }
    return {}
  }, [excludedList]);

  const [columnDefs, setColumnDefs] = useState(fundsColumnDefs.map(col => {
    if (col.colId === 'excluded') {
      return { ...col, hide: !isEditable };
    }
    return col;
  }));

  const [filterBy, setFilterBy] = useState([]);
  const [gridApi, setGridApi] = useState(null);

  const dispatchRowSelection = (excluded, data, params) => {
    let newExcludedList = [];
    let newIncludedList = [];

    if (excluded) {
      newExcludedList = [...excludedList, params.data];
      newIncludedList = includedList.filter(data => data.id !== params.data.id)
    } else {
      newIncludedList = [...includedList, params.data];
      newExcludedList = excludedList.filter(data => data.id !== params.data.id)
    }

    dispatch({
      type: UPDATE_SELECTED_FUNDS,
      payload: {
        ...userDetails.funds,
        excludedList: newExcludedList,
        includedList: newIncludedList
      }
    });
  };

  const gridData = useMemo(() => {
    if (!isEditable || hideExcludedFunds) {
      return includedList;
    }
    return [...includedList, ...excludedList];
  }, [hideExcludedFunds, excludedList, includedList]);

  useEffect(() => {
    setColumnDefs(pre => {
      return pre.map(col => {
        if (col.colId === 'excluded') {
          return { ...col, hide: !isEditable };
        }
        return col;
      });
    });

    if (isEditable) {
      setTimeout(() => {
        dispatch({ type: FORCE_UPDATE_SELECTED_FUNDS });
      }, 1);
    } else {
      setFilterBy([]);
      gridApi && gridApi.clearFilters();
    }
  }, [isEditable]);

  const dispatchColumnWidthChanged = col => {
    setColumnDefs(
      columnDefs.map(column => {
        if (column.colId === col[0].colId) {
          return { ...column, ...col[0], suppressSizeToFit: true };
        }
        return column;
      })
    );
  };

  const dispatchFilterChange = filterModel => {
    const filters = map(filterModel, (obj, key) => ({ field: key, term: obj.filter }));
    setFilterBy(filters);
  };

  const dispatchGridReady = params => {
    setGridApi(params);
  };

  const updatedProps = {
    columnDefs,
    data: gridData,
    gsToolKit: true,
    config: {
      enableServerSideFilter: false,
      enableServerSideSorting: false,
      suppressScrollOnNewData: true,
      sortingOrder: ['desc', 'asc'],
      rowClass: 'excluded-row',
    },
    ...(gridData.length || showLoader ? {} : { noRowDataSelector: t('tkNoUserFunds') }),
    dispatchFilterChange,
    loading: showLoader,
    dispatchGridReady,
    filterBy,
    selectedRowsComparator,
    selectedRows: isEditable ? excludedFundsData : [],
    dispatchRowSelection,
    dispatchColumnWidthChanged,
  };

  const toggleHideExcludedFunds = () => {
    dispatch({ type: UPDATE_HIDE_EXCLUDED_FUNDS, payload: !hideExcludedFunds });
  };

  return (
    <div
      className={classNames('userdetailsfunds__container', { editable: isEditable, 'excluded-row-hide': isEditable && hideExcludedFunds })}
      data-testid='funds-grid'>
      {isEditable ? (
        <div className='userdetailsfunds__container--header'>
          <div className='userdetailsfunds__header'>
            {`${t('tkFundToVisible')} ${userDetails.firstName} ${userDetails.lastName} (${hideExcludedFunds ? gridData.length : gridData.length - Object.keys(excludedFundsData).length
              })`}
          </div>
          <div className='userdetailsfunds__hideExcludedFunds'>
            <Checkbox name='hideExcludedFunds' checked={hideExcludedFunds} onChange={toggleHideExcludedFunds}>
              {t('tkHideExcludedFunds')}
            </Checkbox>
          </div>
        </div>
      ) : <div />}
      <div className='userdetailsfunds__container--grid'>
        {withGrid(Grid)(updatedProps)}
      </div>
    </div>
  );
};

export default FundGrid;
