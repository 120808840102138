import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import CustomSelect from '../../../../components/core/Select/Custom';
import FundBenchmarkOption from '../SearchBenchMarks/FundBenchmarkOption';
import translator from '../../../../services/translator';
import { fetchUserEntitledShareClassesAndBenchmarks } from '../../services';
import { useAsync } from '../../utils/hooks';
import { clearDataMissingMessages, prepareSearchOption } from '../../utils';
import { DATA_TYPE, MY_TOP_HOLDING_ID } from '../../constants';
import { setAttributes } from '../../store/fund-tracker-slice';
import { shareclassesAndBenchmarkStateProps } from '../../store/helpers';
import { addAppContext } from '../../../../actions/app';
import './index.scss';

const { translate: t } = translator;

/**
 * NOTE: Previously called PrimaryFundSearch, component is renamed to reflect
 * the ability to search both funds and imoneynet averages
 */
const PrimaryProductSearch = ({
  userGuid,
  saveUserPreferredFunds,
  tailEndFundIds,
  shareClassAndBenchmarkData = {},
  skipBenchmarks = false
}) => {
  const [openMenu, setOpenMenu] = useState(false);
  const [allProducts, setSearchableProducts] = useState([]);
  const { shareClasses = [], benchmarks = [] } = shareClassAndBenchmarkData;
  const chartDispatch = useDispatch();
  const { run } = useAsync(chartDispatch);
  const myTopHoldingOption = useMemo(() => ({
    value: MY_TOP_HOLDING_ID,
    label: t('tkMyTopHoldings'),
    customProperties: {
      type: DATA_TYPE.FUND
    },
    __isNew__: true
  }), []);
  const selectRef = useRef();

  const combinedData = useMemo(() => shareClasses.concat(benchmarks), [shareClasses, benchmarks]);

  /** callback for when a searched item is selected */
  const handleSelection = useCallback(data => {
    const {
      value,
      customProperties: { type }
    } = data;

    if (value !== MY_TOP_HOLDING_ID) {
      // clear possible missing data messages
      clearDataMissingMessages(chartDispatch);

      // one item at a time selected, update the last selected item
      saveUserPreferredFunds({
        ...(type === DATA_TYPE.BENCHMARK
          ? { lastBenchmarkVendorIds: [value], lastShareclassIds: [] }
          : { lastBenchmarkVendorIds: [], lastShareclassIds: [value] })
      });

      // ensure fund finder selection is cleared
      chartDispatch(addAppContext({ fundTrackerInitialShareClassData: undefined }));

      // set primary product to curr selection
      chartDispatch(
        setAttributes({
          primaryProduct: { value, type, singleFundSelection: true },
          benchmarks: []
        })
      );
    } else {
      // we assume the top holdings wont have benchmarks
      saveUserPreferredFunds({ lastShareclassIds: [], lastBenchmarkVendorIds: [] });
      const [primaryId, ...benchmarks] = tailEndFundIds || [];
      const updatedBenchMarks = benchmarks.map(value => ({ type: DATA_TYPE.FUND, value, id: value }));
      chartDispatch(
        setAttributes({
          primaryProduct: { value: primaryId, type: DATA_TYPE.FUND },
          benchmarks: updatedBenchMarks,
          isLoadingTopOpeningFunds: true
        })
      );
    }

    setTimeout(() => {
      selectRef.current && selectRef.current.blur();
    });
  }, []);

  useEffect(() => {
    fetchUserEntitledShareClassesAndBenchmarks(run, chartDispatch, userGuid);
  }, [userGuid]);

  const dropdownOptions = useMemo(() => {
    const benchmarksList = [];
    let shareclassesList = [];

    if (!isEmpty(combinedData)) {
      // applies only to funds
      const tailEndFunds = [];

      combinedData.forEach(item => {
        const preparedSearchOption = prepareSearchOption(item);

        if (!isEmpty(preparedSearchOption)) {
          const {
            customProperties: { type }
          } = preparedSearchOption;
          const isTailEndFund = tailEndFundIds.some(id => preparedSearchOption.value === id);
          if (!isTailEndFund) {
            if (type === DATA_TYPE.BENCHMARK) {
              benchmarksList.push(preparedSearchOption);
            } else {
              shareclassesList.push(preparedSearchOption);
            }
          } else {
            tailEndFunds.push(preparedSearchOption);
          }
        }
      });
      // add tail end funds
      shareclassesList = shareclassesList.concat(tailEndFunds);
      // top holding funds as last option
      if (tailEndFundIds && !!tailEndFundIds.length) {
        shareclassesList.push(myTopHoldingOption);
      }

      // if we are including benchmarks make groups for share classes and benchmarks
      if (!skipBenchmarks) {
        return [
          { label: t('tkFunds'), options: shareclassesList },
          { label: t('tkIMoneyNetAverages'), options: benchmarksList }
        ];
      }

      // by default return only share classes
      return shareclassesList;
    }

    return null;
  }, [combinedData, tailEndFundIds]);

  useEffect(() => {
    if (dropdownOptions) {
      setSearchableProducts(dropdownOptions);
    }
  }, [dropdownOptions]);

  const isDataLoading = useMemo(() => !allProducts || !allProducts.length, [allProducts]);

  const hasTailEndFunds = useMemo(() => tailEndFundIds && !!tailEndFundIds.length, [tailEndFundIds]);

  const handleOnFocus = () => {
    if (hasTailEndFunds) {
      setSearchableProducts([{ label: t('tkFunds'), options: [myTopHoldingOption] }]);
      setOpenMenu(true);
    }
  };

  const handleInputChange = query => {
    if (query) {
      if (hasTailEndFunds) {
        if (query.length >= 3) {
          const fundsIndex = allProducts.findIndex(({ label }) => label === t('tkFunds'));
          const containsMyTopHoldingOption = allProducts[fundsIndex]?.options?.some(
            ({ value }) => value === MY_TOP_HOLDING_ID
          );
          if (!containsMyTopHoldingOption) {
            const updatedItems = dropdownOptions[0].options.concat([myTopHoldingOption]);
            setSearchableProducts(updatedItems);
          } else {
            setSearchableProducts(dropdownOptions);
          }
        } else {
          setSearchableProducts([{ label: t('tkFunds'), options: [myTopHoldingOption] }]);
        }
      } else {
        if (query.length >= 3) {
          setOpenMenu(true);
        } else {
          setOpenMenu(false);
        }
      }
    }
  };

  const handleOnBlur = () => {
    setOpenMenu(false);
  };

  return (
    <div className='primaryProductSearch' data-testid='primary-product-search-testid'>
      <CustomSelect
        isDataLoading={isDataLoading}
        isDisabled={isDataLoading}
        placeholder={`${t('tkSearchFundsOrMoneyNetAverages')}...`}
        searchItems={allProducts}
        handleOnFocus={handleOnFocus}
        handleSelection={handleSelection}
        handleInputChange={handleInputChange}
        handleOnBlur={handleOnBlur}
        ref={selectRef}
        isOpenMenu={openMenu}
        option={FundBenchmarkOption}
      />
    </div>
  );
};

PrimaryProductSearch.propTypes = {
  userGuid: PropTypes.string,
  saveUserPreferredFunds: PropTypes.func,
  tailEndFundIds: PropTypes.array,
  skipBenchmarks: PropTypes.bool,
  // store
  shareClassAndBenchmarkData: PropTypes.object
};

export default connect(shareclassesAndBenchmarkStateProps)(PrimaryProductSearch);
