import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Conditional} from '../Conditional';
import './index.scss';

class Checkbox extends PureComponent {
  static getDerivedStateFromProps(nextProps, prevState) {
    let newState = {};
    if (nextProps.isChecked !== undefined && nextProps.isChecked !== prevState.isChecked) {
      newState = {isChecked: nextProps.isChecked};
    }
    if (nextProps.isDisabled !== undefined && nextProps.isDisabled !== prevState.isDisabled) {
      newState = {...newState, isDisabled: nextProps.isDisabled};
    }
    return Object.keys(newState).length ? newState : null;
  }

  constructor(props) {
    super(props);
    this.state = {
      isChecked: this.props.isChecked || false,
      isDisabled: this.props.isDisabled || false
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange() {
    const {changeHandler} = this.props;
    if (!changeHandler) {
      return;
    }
    const currentState = this.state.isChecked;
    this.setState({isChecked: !currentState});
    changeHandler(this.props.value, !currentState, this.props);
  }

  render() {
    const {
      id, label, value, extraProps, isIntermediateChecked,
      customClass, customLabelClassname, customLabelRenderer,
      customLabelRendererProps
    } = this.props;
    const {isChecked, isDisabled} = this.state;
    const className = ['checkbox-container'];

    if (customClass) {
      className.push(customClass);
    }
    if (isChecked) {
      className.push('checkbox-container--active');
    }
    if (isIntermediateChecked) {
      className.push('checkbox-container--intermediate');
    }
    if (isDisabled) {
      className.push('disabled');
    }

    return (
      <div className={className.join(' ')}>
        <input
          type="checkbox"
          id={id}
          value={value}
          checked={isChecked}
          onChange={this.handleChange}
          disabled={isDisabled}
          {...extraProps}
          className={this.state.isChecked ?
            'checkbox-container__input icon-checkbox-filled' :
            'checkbox-container__input icon-checkbox-outlined'}/>
        <Conditional condition={!!customLabelRenderer}>
          <div
            onClick={this.handleChange}
            className="checkbox-container__label"
            key={value}>
            {
              customLabelRenderer && customLabelRenderer(customLabelRendererProps)
            }
          </div>
          <label
            htmlFor={id}
            className={!customLabelClassname ? 'checkbox-container__label' : customLabelClassname}>
            {label}
          </label>
        </Conditional>
      </div>
    );
  }
}

Checkbox.defaultProps = {
  label: '',
  isChecked: false,
  isDisabled: false
};

Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  changeHandler: PropTypes.func,
  value: PropTypes.string.isRequired,
  isChecked: PropTypes.bool,
  isIntermediateChecked: PropTypes.bool,
  isDisabled: PropTypes.bool,
  customClass: PropTypes.string,
  customLabelClassname: PropTypes.string,
  customLabelRenderer: PropTypes.object,
  customLabelRendererProps: PropTypes.object,
  extraProps: PropTypes.object
};

export default Checkbox;
