import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from '@gs-ux-uitoolkit-react/modal';
import { Radio } from '@gs-ux-uitoolkit-react/radio';
import { Button } from '@gs-ux-uitoolkit-react/button';
import PropTypes from 'prop-types';
import useUserMaintenance from '../../../context';
import { BOOLEAN } from '../../../../../../constants/pageConstants';
import translator from '../../../../../../services/translator';
import './index.scss';
import { dispatchAnalytics, organizationDetailsUnmapFirmModalCTAClick } from '../../../analytics';

const { translate: t } = translator;

const FirmOrgUnmappingModal = ({ closeModal, firmData: { firmCode, firmName, firmId, label: firmLabel }, updateUnmappedFirmData }) => {
  const {
    state: {
      organizationDetails: { organizationName },
    },
    reduxDispatch,
    storeValues: { userGuid },
  } = useUserMaintenance();

  const [selectedOption, setSelectedOption] = useState(BOOLEAN.NO);

  const handleSubmit = () => {
    if (selectedOption === BOOLEAN.YES) {
      updateUnmappedFirmData({ firmId, firmCode, firmName, operationType: 'UNMAP' });
    }
    dispatchAnalytics(
      reduxDispatch,
      organizationDetailsUnmapFirmModalCTAClick({
        guid: userGuid,
        btnName: 'Ok',
        radioBtn: `${selectedOption === BOOLEAN.NO ? 'No, do not' : 'Yes'} unmap this firm`,
      })
    );
    closeModal();
  };

  const handleOptionChange = event => {
    setSelectedOption(event.target.value);
  };

  const handleClose = btnName => {
    dispatchAnalytics(
      reduxDispatch,
      organizationDetailsUnmapFirmModalCTAClick({
        guid: userGuid,
        btnName,
        radioBtn: `${selectedOption === BOOLEAN.NO ? 'No, do not' : 'Yes'} unmap this firm`,
      })
    );
    closeModal();
  };

  return (
    <Modal visible={true} placement='center' className='firmorgunmap-modal gs-uitk-override-css' data-testid='unmap-modal'>
      <ModalHeader onDismissButtonClick={() => handleClose('Close')} className='firmorgunmap-modal__header'>
        {t('tkUnmapModalConfirmation')}
      </ModalHeader>
      <ModalBody className='firmorgunmap-modal__body'>
        <div>{t('tkUnmapConfirmation', firmLabel, organizationName)}</div>
        <div className='firmorgunmap-modal__radio'>
          <Radio name='modalRadio' value={BOOLEAN.YES} inline checked={selectedOption === BOOLEAN.YES} onChange={handleOptionChange}>
            {t('tkUnmapModalYes')}
          </Radio>
          <Radio name='modalRadio' value={BOOLEAN.NO} inline defaultChecked checked={selectedOption === BOOLEAN.NO} onChange={handleOptionChange}>
            {t('tkUnmapModalNo')}
          </Radio>
        </div>
      </ModalBody>
      <ModalFooter className='firmorgunmap-modal__footer'>
        <Button actionType='secondary' onClick={() => handleClose('Cancel')}>
          {t('tkCancelModal')}
        </Button>
        <Button actionType='primary' onClick={handleSubmit}>
          {t('tkOk')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

FirmOrgUnmappingModal.propTypes = {
  closeModal: PropTypes.func,
  firmData: PropTypes.object,
  updateUnmappedFirmData: PropTypes.func,
};

export default FirmOrgUnmappingModal;
