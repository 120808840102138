/* eslint-disable max-statements */
/* eslint-disable no-console */
import { cloneDeep, isEmpty, set } from 'lodash';
import { ADD, CHANGE, DELETE, CLEARING, PLACEHOLDER } from '../constants';
import translator from '../../../../services/translator';

const { translate: t } = translator;

const logToConsole = false;

const userMaintenanceReducerMap = {
  EDIT_MODE_ACTIVE: (state, payload) => ({
    ...state,
    isEditable: payload.isEditable,
    backUpUserDetails: cloneDeep(state.userDetails),
    showAccountSidePanel: false,
    savedRecentEdits: false,
    organizationAccounts: {},
  }),
  EDIT_MODE_INACTIVE: (state, payload) => ({
    ...state,
    isEditable: payload.isEditable,
    ...(!payload.isEditable && { isInputValid: {} }),
  }),
  TAB_SWITCHED: (state, payload) => ({
    ...state,
    activeTab: payload,
    activeAccountsType: !state.isEditable ? 'entitled' : state.activeAccountsType,
    selectedOrganization: {},
  }),
  ORG_EDIT_MODE_ACTIVE: (state, payload) => ({
    ...state,
    isOrgEditable: payload.isOrgEditable,
    backUpOrganizationDetails: cloneDeep(state.organizationDetails),
    isOrgSaved: false,
  }),
  ORG_EDIT_MODE_INACTIVE: (state, payload) => ({
    ...state,
    isOrgEditable: payload.isOrgEditable,
  }),
  UPDATE_PAGE_EDITED: (state, payload) => ({
    ...state,
    isPageEdited: payload.isPageEdited,
  }),
  UPDATE_ORG_PAGE_EDITED: (state, payload) => ({
    ...state,
    isOrgPageEdited: payload.isOrgPageEdited,
  }),
  SHOW_LOADER: (state, payload) => ({
    ...state,
    showLoader: payload,
  }),
  SWITCH_USER_ACCOUNT_STATUS: (state, payload) => {
    return {
      ...userMaintenanceReducerMap.RESET(state),
      userDetails: payload,
      backUpUserDetails: cloneDeep(payload),
      excludedFunds: [...state.defaultExcludedFunds],
    };
  },
  UPDATE_FIELDS: (state, payload) => {
    const arrayPath = Array.isArray(payload.path) ? payload.path : payload.path.split('.');
    const updatedUserDetails = {
      ...state.userDetails,
      entitlements: {
        ...state.userDetails.entitlements,
        featureEntitlements: {
          ...state.userDetails.entitlements.featureEntitlements,
          ...payload.featureEntitlements,
        },
      },
    };
    const updatedState = {
      ...state,
      isPageEdited: true,
      userDetails: { ...set(updatedUserDetails, arrayPath, payload.newValue) },
    };
    return updatedState;
  },
  UPDATE_MULTIPLE_FIELDS: (state, payload) => {
    const updatedUserDetails = {
      ...state.userDetails,
      entitlements: {
        ...state.userDetails.entitlements,
        featureEntitlements: {
          ...state.userDetails.entitlements.featureEntitlements,
          ...payload.featureEntitlements,
        },
      },
    };
    const updatedState = {
      ...state,
      isPageEdited: true,
      userDetails: updatedUserDetails,
    };
    return updatedState;
  },
  UPDATE_SINGLE_FIELD: (state, payload) => {
    const updatedState = {
      ...state,
      userDetails: { ...state.userDetails, ...payload },
    };
    return updatedState;
  },
  UPDATE_ORG_FIELDS: (state, payload) => {
    const arrayPath = Array.isArray(payload.path) ? payload.path : payload.path.split('.');
    const updatedOrganizationDetails = {
      ...state.organizationDetails,
      relationships: [...state.organizationDetails.relationships],
    };
    const updatedState = {
      ...state,
      isOrgPageEdited: true,
      organizationDetails: { ...set(updatedOrganizationDetails, arrayPath, payload.newValue) },
    };
    return updatedState;
  },
  UPDATE_PROFILE_RELATIONSHIPS_FIELD: (state, payload) => {
    const relationships = [...state.organizationDetails.relationships];
    let newErrorState = { ...state.orgDetailsValidation };
    let newRelationships = [];
    const { action, value, fieldIndex, config: { dataType } = {} } = payload;
    const filteredRelationships = relationships.filter(relationship => relationship.relationshipType !== CLEARING);
    const clearingAgents = relationships.filter(relationship => relationship.relationshipType === CLEARING);
    function updateErrorMessages() {
      const groupedByOrganizationId = newRelationships.reduce((acc, cur) => {
        cur.organizationId && (acc[cur.organizationId] = acc[cur.organizationId] || []).push(cur);
        return acc;
      }, {});
      const duplicates = Object.entries(groupedByOrganizationId)
        .filter(([, value]) => value.length > 1)
        .map(([key]) => key);
      newErrorState = {};
      duplicates.forEach(item => {
        newErrorState[item] = { errorMessage: t('tkOrganizationAlreadyExists') };
      });
    }
    if (action === 'add') {
      newRelationships = [
        ...relationships,
        {
          organizationId: '',
          organizationName: '',
          relationshipType: CLEARING,
          targetOrganizationType: 'TARGET',
        },
      ];
    } else if (action === 'delete') {
      if (dataType !== CLEARING) {
        const newSubsidaryOrIntermediary = filteredRelationships.map(item =>
          item.relationshipType === dataType ? { ...item, organizationId: '', organizationName: '' } : item
        );
        newRelationships = [...newSubsidaryOrIntermediary, ...clearingAgents];
      } else {
        const newClearingAgents = clearingAgents.filter((_, i) => fieldIndex !== i);
        newRelationships = [...filteredRelationships, ...newClearingAgents];
      }
      updateErrorMessages();
    } else {
      if (dataType === CLEARING) {
        const newClearingAgents = clearingAgents.map((agents, i) => {
          return fieldIndex === i ? value : agents;
        });
        newRelationships = [...filteredRelationships, ...newClearingAgents];
      } else {
        newRelationships = relationships.map(data => (dataType === data.relationshipType ? value : data));
      }
      updateErrorMessages();
    }
    const updatedState = {
      ...state,
      isOrgPageEdited: true,
      orgDetailsValidation: { ...newErrorState },
      organizationDetails: { ...state.organizationDetails, relationships: newRelationships },
    };
    return updatedState;
  },
  UPDATE_FEATURES_SELECT_ALL: (state, payload) => {
    const updatedUserDetails = {
      ...state.userDetails,
      entitlements: {
        ...state.userDetails.entitlements,
        featureEntitlements: {
          ...state.userDetails.entitlements.featureEntitlements,
          ...payload.featureEntitlements,
        },
      },
    };
    const updatedState = {
      ...state,
      isPageEdited: true,
      userDetails: updatedUserDetails,
    };
    return updatedState;
  },
  OPEN_MODAL: (state, payload) => {
    const updatedModal = state.modal.map(item => (item.name === payload.modalName ? { ...item, display: true } : item));
    return { ...state, modal: updatedModal };
  },
  CLOSE_MODAL: (state, payload) => {
    const updatedModal = state.modal.map(item => (item.name === payload.modalName ? { ...item, display: false } : item));
    return { ...state, modal: updatedModal };
  },
  ADD_UPDATE_USER_DETAILS: (state, payload) => ({
    ...state,
    userDetails: {
      ...payload.userDetails,
      ...(payload.success ? { funds: { ...state.userDetails.funds } } : {}),
    },
  }),
  UPDATE_USER_DETAILS_FUNDS: (state, payload) => ({
    ...state,
    userDetails: {
      ...state.userDetails,
      ...payload,
    },
  }),
  UPDATE_CREATE_USER_DETAILS_FUNDS: (state, payload) => ({
    ...state,
    userDetails: {
      ...state.userDetails,
      funds: payload.funds,
    },
    createNewUserFundOrgId: state.userDetails?.organizationId,
  }),
  UPDATE_ORGANIZATION_DETAILS: (state, payload) => ({
    ...state,
    organizationDetails: {
      ...payload.updatedObject,
    },
  }),
  UPDATE_ACCOUNT_DETAILS: (state, payload) => {
    return {
      ...state,
      userDetails: {
        ...state.userDetails,
        accountDetails: payload.accountDetails,
      },
      externalUserAccounts: {
        ...state.externalUserAccounts,
        entitled: payload.accountDetails,
      },
    };
  },
  UPDATE_CREATE_USER_ACCOUNT_DETAILS: (state, payload) => {
    return {
      ...state,
      userDetails: {
        ...state.userDetails,
        accountDetails: payload.accountDetails,
      },
      createNewUserAccountOrgId: payload.organizationId,
    };
  },
  UPDATE_CREATE_USER_ORG_ID: (state, payload) => {
    return {
      ...state,
      createNewUserAccountOrgId: payload.organizationId,
      createNewUserFundOrgId: payload.organizationId,
    }
  },
  VALIDATE_INPUT: (state, payload) => ({
    ...state,
    isInputValid: { ...state.isInputValid, [payload.fieldName]: { isValid: !payload.errorMessage, errorMessage: payload.errorMessage } },
  }),
  UPDATE_ERROR: (state, payload) => ({
    ...state,
    isInputValid: payload,
  }),
  ACTIVE_ACCOUNTS_TYPE: (state, payload) => ({
    ...state,
    activeAccountsType: payload.selectedAccountType,
    accountEntitlements: [],
    externalUserAccounts: {
      entitled: [],
      available: [],
    },
  }),
  SELECTED_ORGANIZATION: (state, payload) => ({
    ...state,
    selectedOrganization: payload,
  }),
  UPDATE_ORGANIZATION_ACCOUNTS: (state, payload) => {
    const { organizationName, organizationId, accountDetails, operation } = payload;
    if (operation === 'ADD') {
      return {
        ...state,
        organizationAccounts: {
          ...state.organizationAccounts,
          [organizationId]: {
            ...state.organizationAccounts[organizationId],
            ...(organizationId in state.organizationAccounts ? { accountDetails } : { organizationName, organizationId, accountDetails }),
          },
        },
      };
    } else if (operation === 'DELETE') {
      const { [organizationId]: toBeDeleted, ...restOrganizationAccounts } = state.organizationAccounts;
      return { ...state, organizationAccounts: restOrganizationAccounts };
    } else if (operation === 'RESET') {
      return { ...state, organizationAccounts: {} };
    }
  },
  EXTERNAL_USER_ACCOUNTS: (state, payload) => {
    return {
      ...state,
      externalUserAccounts: { ...state.externalUserAccounts, [payload.key]: payload.accountDetails },
    };
  },
  ORIGINAL_ACCOUNTS_TREE: (state, payload) => {
    return {
      ...state,
      originalAccountsTree: payload,
    };
  },
  UPDATE_ACCOUNT_ENTITLEMENTS: (state, payload) => {
    let accountEntitlements = [];
    if (payload.operation === 'ADD') {
      payload.accountEntitlementChanges.forEach(change => {
        accountEntitlements.push(change);
      });
      return { ...state, accountEntitlements: [...state.accountEntitlements, ...accountEntitlements] };
    } else if (payload.operation === 'DELETE') {
      accountEntitlements = state.accountEntitlements.filter(item => item.organizationId !== payload.organizationId);
      return { ...state, accountEntitlements };
    }
  },
  UPDATE_SELECTED_FUNDS: (state, payload) => ({
    ...state,
    userDetails: {
      ...state.userDetails,
      funds: payload,
    },
    isPageEdited: true,
    disableSaveBtn: false,
  }),
  FORCE_UPDATE_SELECTED_FUNDS: state => ({
    ...state,
    userDetails: {
      ...state.userDetails,
      funds: {
        ...state.userDetails.funds,
        excludedList: state.userDetails?.funds?.excludedList?.length ? [...state.userDetails.funds.excludedList] : [],
      },
    },
  }),
  UPDATE_HIDE_EXCLUDED_FUNDS: (state, payload) => ({
    ...state,
    hideExcludedFunds: payload,
  }),
  SAVE_BTN_CLICKED: (state, payload) => {
    const { saveBtn, savedRecentEdits = true } = payload;
    return {
      ...state,
      saveBtnClicked: saveBtn,
      savedRecentEdits,
    };
  },
  FILTER_FUND_GROUPS: (state, payload) => ({
    ...state,
    filters: payload.filters,
  }),
  SAVE_CHANGES: state => {
    return {
      ...state,
      ...userMaintenanceReducerMap.RESET(state),
    };
  },
  SAVE_ORG_CHANGES: state => {
    const udpatedRelationships = state.organizationDetails.relationships.slice().reduce((acc, cur) => {
      if (cur.relationshipType === CLEARING) {
        cur.organizationId && acc.push(cur);
      } else {
        acc.push(cur);
      }
      return acc;
    }, []);
    if (!udpatedRelationships.some(item => item.relationshipType === CLEARING)) {
      udpatedRelationships.push({ organizationId: '', organizationName: '', relationshipType: CLEARING, targetOrganizationType: 'TARGET' });
    }
    return {
      ...state,
      ...userMaintenanceReducerMap.ORG_RESET(state),
      organizationDetails: {
        ...state.organizationDetails,
        relationships: udpatedRelationships,
      },
      isOrgSaved: true,
    };
  },
  ORG_RESET: state => ({
    ...state,
    isOrgEditable: false,
    isOrgPageEdited: false,
    disableSaveBtn: true,
    orgDetailsValidation: {},
    mappedFirmsAccounts: [],
    mapUnmapFirmsList: [],
    firmsAvailableToMapCount: 0,
    updatedShareclassesAndFundGroups: {},
  }),
  DISCARD_ORG_CHANGES: state => ({
    ...userMaintenanceReducerMap.ORG_RESET(state),
    organizationDetails: cloneDeep(state.backUpOrganizationDetails),
  }),
  DISCARD_CHANGES: state => ({
    ...userMaintenanceReducerMap.RESET(state),
    organizationAccounts: {},
    userDetails: cloneDeep(state.backUpUserDetails),
    excludedFunds: [...state.defaultExcludedFunds],
  }),
  RESET: state => ({
    ...state,
    isEditable: false,
    isPageEdited: false,
    disableSaveBtn: true,
    updatedFields: [],
    accountEntitlements: [],
    hideExcludedFunds: false,
    isInputValid: {},
  }),
  ADD_ORGANIZATION_DETAILS: (state, payload) => ({
    ...state,
    organizationDetails: payload,
  }),
  UPDATE_ORG_ACCOUNT_DETAILS: (state, payload) => ({
    ...state,
    organizationDetails: { ...state.organizationDetails, accountDetails: payload },
  }),
  SHOW_INACTIVE_ORG_USERS: (state, payload) => ({
    ...state,
    showInActiveOrgUsers: payload,
  }),
  UPDATE_FIRMS_AVAILABLE_TO_MAP_COUNT: (state, payload) => ({
    ...state,
    firmsAvailableToMapCount: payload,
  }),
  MAPPED_FIRMS_ACCOUNTS: (state, payload) => ({
    ...state,
    mappedFirmsAccounts: [...state.mappedFirmsAccounts, ...payload],
  }),
  MAP_UNMAP_FIRMS_LIST: (state, payload) => {
    const newList = [...state.mapUnmapFirmsList];
    payload.forEach(item => {
      if (newList.find(firm => firm.firmId === item.firmId)) {
        const idxToDelete = newList.findIndex(firm => firm.firmId === item.firmId);
        idxToDelete !== -1 && newList.splice(idxToDelete, 1);
      } else {
        newList.push(item);
      }
    });
    return { ...state, mapUnmapFirmsList: newList };
  },
  ORG_ACCOUNTS_GRID_BACKUP: (state, payload) => ({ ...state, organizationAccountsGridBackup: payload }),
  CHANGE_MANAGEMENT_TAB_SWITCHED: (state, payload) => ({
    ...state,
    activeCmdTab: payload,
  }),
  UPDATE_SHARECLASSES_AND_FUND_GROUPS: (state, payload) => {
    const updatedShareclassesAndFundGroups = { ...state.updatedShareclassesAndFundGroups };
    payload.forEach(item => {
      if (item.id in updatedShareclassesAndFundGroups) {
        updatedShareclassesAndFundGroups[item.id] = { ...item, modifiedCount: updatedShareclassesAndFundGroups[item.id].modifiedCount + 1 };
      } else {
        updatedShareclassesAndFundGroups[item.id] = { ...item, modifiedCount: 1 };
      }
    });
    return { ...state, updatedShareclassesAndFundGroups };
  },
  ORG_FUNDS_GRID_BACKUP: (state, payload) => ({
    ...state,
    organizationFundGroupsBackup: payload.fundGroups,
    organizationFundsGridBackup: payload.gridData,
  }),
  USER_DATA_IMPORTED_FROM_ID_HUB: (state, payload) => ({
    ...state,
    dataImportedFromIdHub: payload,
  }),
  DELETE_ALL_ACCOUNT_ENTITLEMENTS_AND_PENDING_EDITS_FOR_CREATE: state => ({
    ...state,
    accountEntitlements: [],
    organizationAccounts: {},
    externalUserAccounts: {
      entitled: [],
      available: [],
    },
  }),
  UPDATE_SSO_ENTITLEMENTS_FIELD: (state, payload) => {
    const ssoClient = [...state.userDetails.ssoClient.filter(client => client.isEnabled).map(client => client)];
    const newErrorState = {};
    const deletedSsoClients = state.deletedSsoClients || [];
    let newSsoClient;
    const { action, fieldIndex, clientName, alias } = payload;
    const updateErrorMessages = () => {
      let hasInvalidFields = false;
      ssoClient.forEach((client, index) => {
        if (client.clientName === PLACEHOLDER) {
          hasInvalidFields = true;
          newErrorState[index] = { errorMessage: t('tkSsoEmptyDropdownError') };
        }
      });
      return hasInvalidFields;
    };

    switch (action) {
      case ADD:
        newSsoClient = updateErrorMessages()
          ? [...ssoClient]
          : [
            ...ssoClient,
            {
              clientName: PLACEHOLDER,
              alias: '',
              isEnabled: true,
            },
          ];
        break;

      case DELETE:
        if (ssoClient[fieldIndex].clientName !== PLACEHOLDER && !isEmpty(ssoClient[fieldIndex].alias)) {
          state.deletedSsoClients = [...deletedSsoClients, ssoClient[fieldIndex]];
          newSsoClient = ssoClient.map((client, i) => {
            if (i === fieldIndex) {
              return { ...client, clientName: clientName || client.clientName, alias: alias || client.alias, isEnabled: false };
            }
            return client;
          })
        } else {
          newSsoClient = ssoClient.filter((_, i) => i !== fieldIndex).map(client => client);
        }
        break;

      case CHANGE: {
        if (state.userDetails.ssoClient.filter(client => !client.isEnabled && !deletedSsoClients.includes(client)).length > 0) {
          state.deletedSsoClients = [...state.userDetails.ssoClient
            .filter(client => !client.isEnabled && !deletedSsoClients.includes(client))
            .map(client => client)
          ];
        }
        newSsoClient = ssoClient.map((client, index) => {
          if (index === fieldIndex) {
            // check if clientName exists in deletedSsoClients
            const deletedClientIndex = state.deletedSsoClients.findIndex(deletedClient => deletedClient.clientName === clientName);
            if (deletedClientIndex !== -1) {
              const deletedClient = state.deletedSsoClients[deletedClientIndex];
              state.deletedSsoClients[deletedClientIndex].isEnabled = true;
              state.deletedSsoClients.splice(deletedClientIndex, 1);

              if (ssoClient[fieldIndex].clientName !== PLACEHOLDER && !isEmpty(ssoClient[fieldIndex].alias)) {
                ssoClient[fieldIndex].isEnabled = false;
                state.deletedSsoClients = [...deletedSsoClients, ssoClient[fieldIndex]];
              }
              return {
                ...deletedClient,
                alias: alias || deletedClient.alias, isEnabled: true
              };
            } else if (ssoClient[fieldIndex].clientName !== PLACEHOLDER && !isEmpty(ssoClient[fieldIndex].alias)) {
              ssoClient[fieldIndex].isEnabled = false;
              state.deletedSsoClients = [...deletedSsoClients, ssoClient[fieldIndex]];
            }
            return { ...client, clientName: clientName || client.clientName, alias, isEnabled: true };
          }
          return client;
        });
        break;
      }

      default:
        newSsoClient = ssoClient;
    }
    state.userDetails.ssoClient
      .filter(client => !client.isEnabled && !newSsoClient.map(client => client.clientName).includes(client.clientName))
      .map(client => newSsoClient.push(client));

    return {
      ...state,
      userDetailsValidation: { ...newErrorState },
      userDetails: { ...state.userDetails, ssoClient: newSsoClient },
    };
  },
  UPDATE_SSO_ERROR: (state, payload) => {
    const { errorMessage } = payload;
    return {
      ...state,
      userDetailsValidation: { ...errorMessage },
    };
  },
};

export const userMaintenanceReducer = (state, action) => {
  const actionHandler = userMaintenanceReducerMap[action.type];
  if (!actionHandler) {
    console.error(`No case for type ${action.type} found in userMaintenanceReducer`);
    return;
  }
  const { type, payload } = action;
  const stateData = actionHandler ? actionHandler(state, payload) : state;
  logToConsole && console.log({ action: type, payload, previousState: state, newState: stateData });
  return stateData;
};
