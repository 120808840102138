export const FETCH_USER_SEARCH_DETAILS = 'FETCH_USER_SEARCH_DETAILS';
export const UPDATE_CURRENT_PAGE = 'UPDATE_CURRENT_PAGE';
export const FETCH_USER_DETAILS_FROM_ORGANIZATION_DETAILS = 'FETCH_USER_DETAILS_FROM_ORGANIZATION_DETAILS';
export const SHOW_USER_SEARCH_DETAILS_GRID = 'SHOW_USER_SEARCH_DETAILS_GRID';
export const TOGGLE_IN_ACTIVE_USERS = 'TOGGLE_IN_ACTIVE_USERS';
export const CLEAR_USER_DETAILS_DATA = 'CLEAR_USER_DETAILS_DATA';
export const EDIT_MODE_ACTIVE = 'EDIT_MODE_ACTIVE';
export const ORG_EDIT_MODE_ACTIVE = 'ORG_EDIT_MODE_ACTIVE';
export const ORG_EDIT_MODE_INACTIVE = 'ORG_EDIT_MODE_INACTIVE';
export const EDIT_MODE_INACTIVE = 'EDIT_MODE_INACTIVE';
export const SWITCH_USER_ACCOUNT_STATUS = 'SWITCH_USER_ACCOUNT_STATUS';
export const MODIFIED_FIELD = 'MODIFIED_FIELD';
export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const UPDATE_PAGE_EDITED = 'UPDATE_PAGE_EDITED';
export const ADD_UPDATE_USER_DETAILS = 'ADD_UPDATE_USER_DETAILS';
export const UPDATE_USER_DETAILS_FUNDS = 'UPDATE_USER_DETAILS_FUNDS';
export const SAVE_CHANGES = 'SAVE_CHANGES';
export const SAVE_BTN_CLICKED = 'SAVE_BTN_CLICKED';
export const DISCARD_CHANGES = 'DISCARD_CHANGES';
export const DISCARD_ORG_CHANGES = 'DISCARD_ORG_CHANGES';
export const TOGGLE_LOADING = 'TOGGLE_LOADING';
export const DISABLE_SAVE_BTN = 'DISABLE_SAVE_BTN';
export const SHOW_LOADER = 'SHOW_LOADER';
export const VALIDATE_INPUT = 'VALIDATE_INPUT';
export const UPDATE_ERROR = 'UPDATE_ERROR';
export const TAB_SWITCHED = 'TAB_SWITCHED';
export const ACTIVE_ACCOUNTS_TYPE = 'ACTIVE_ACCOUNTS_TYPE';
export const UPDATE_ORGANIZATION_ACCOUNTS = 'UPDATE_ORGANIZATION_ACCOUNTS';
export const UPDATE_ACCOUNT_ENTITLEMENTS = 'UPDATE_ACCOUNT_ENTITLEMENTS';
export const EXTERNAL_USER_ACCOUNTS = 'EXTERNAL_USER_ACCOUNTS';
export const ORIGINAL_ACCOUNTS_TREE = 'ORIGINAL_ACCOUNTS_TREE';
export const UPDATE_ACCOUNT_DETAILS = 'UPDATE_ACCOUNT_DETAILS';
export const UPDATE_SINGLE_FIELD = 'UPDATE_SINGLE_FIELD';
export const SELECTED_ORGANIZATION = 'SELECTED_ORGANIZATION';
export const UPDATE_SELECTED_FUNDS = 'UPDATE_SELECTED_FUNDS';
export const UPDATE_HIDE_EXCLUDED_FUNDS = 'UPDATE_HIDE_EXCLUDED_FUNDS';
export const FORCE_UPDATE_SELECTED_FUNDS = 'FORCE_UPDATE_SELECTED_FUNDS';
export const ADD_ORGANIZATION_DETAILS = 'ADD_ORGANIZATION_DETAILS';
export const SHOW_INACTIVE_ORG_USERS = 'SHOW_INACTIVE_ORG_USERS';
export const FILTER_FUND_GROUPS = 'FILTER_FUND_GROUPS';
export const UPDATE_ORG_ACCOUNT_DETAILS = 'UPDATE_ORG_ACCOUNT_DETAILS';
export const UPDATE_FIELDS = 'UPDATE_FIELDS';
export const UPDATE_MULTIPLE_FIELDS = "UPDATE_MULTIPLE_FIELDS";
export const UPDATE_ORG_FIELDS = 'UPDATE_ORG_FIELDS';
export const UPDATE_FEATURES_SELECT_ALL = 'UPDATE_FEATURES_SELECT_ALL';
export const UPDATE_PROFILE_RELATIONSHIPS_FIELD = 'UPDATE_PROFILE_RELATIONSHIPS_FIELD';
export const UPDATE_ORGANIZATION_DETAILS = 'UPDATE_ORGANIZATION_DETAILS';
export const UPDATE_ORG_PAGE_EDITED = 'UPDATE_ORG_PAGE_EDITED';
export const SAVE_ORG_CHANGES = 'SAVE_ORG_CHANGES';
export const UPDATE_FIRMS_AVAILABLE_TO_MAP_COUNT = 'UPDATE_FIRMS_AVAILABLE_TO_MAP_COUNT';
export const MAPPED_FIRMS_ACCOUNTS = 'MAPPED_FIRMS_ACCOUNTS';
export const MAP_UNMAP_FIRMS_LIST = 'MAP_UNMAP_FIRMS_LIST';
export const ORG_ACCOUNTS_GRID_BACKUP = 'ORG_ACCOUNTS_GRID_BACKUP';
export const CHANGE_MANAGEMENT_TAB_SWITCHED = 'CHANGE_MANAGEMENT_TAB_SWITCHED';
export const UPDATE_SHARECLASSES_AND_FUND_GROUPS = 'UPDATE_SHARECLASSES_AND_FUND_GROUPS';
export const ORG_FUNDS_GRID_BACKUP = 'ORG_FUNDS_GRID_BACKUP';
export const UPDATE_CREATE_USER_DETAILS_FUNDS = 'UPDATE_CREATE_USER_DETAILS_FUNDS';
export const UPDATE_CREATE_USER_ACCOUNT_DETAILS = 'UPDATE_CREATE_USER_ACCOUNT_DETAILS';
export const FETCH_INVESTMENT_POLICY_BY_ORG_ID = 'FETCH_INVESTMENT_POLICY_BY_ORG_ID';
export const DELETE_ALL_ACCOUNT_ENTITLEMENTS_AND_PENDING_EDITS_FOR_CREATE = 'DELETE_ALL_ACCOUNT_ENTITLEMENTS_AND_PENDING_EDITS_FOR_CREATE';
export const UPDATE_SSO_ENTITLEMENTS_FIELD = 'UPDATE_SSO_ENTITLEMENTS_FIELD';
export const UPDATE_SSO_ERROR = 'UPDATE_SSO_ERROR';
export const UPDATE_CREATE_USER_ORG_ID = "UPDATE_CREATE_USER_ORG_ID";

export const clearUserDetailsData = data => ({ type: CLEAR_USER_DETAILS_DATA, data });

export const fetchUserSearchDetails = data => ({ type: FETCH_USER_SEARCH_DETAILS, data });

export const updateCurrentPage = data => ({ type: UPDATE_CURRENT_PAGE, data });

export const showDetailsGrid = data => ({ type: SHOW_USER_SEARCH_DETAILS_GRID, data });

export const toggleInActiveUsers = data => ({ type: TOGGLE_IN_ACTIVE_USERS, data });

export const switchUserAccountStatus = data => ({ type: SWITCH_USER_ACCOUNT_STATUS, data });

export const fetchUserDetailsFromOrganizationDetails = data => ({ type: FETCH_USER_DETAILS_FROM_ORGANIZATION_DETAILS, data });
