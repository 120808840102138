import React, { useCallback, useEffect, useState, memo } from 'react';
import PropTypes from 'prop-types';
import { CardBody } from '@gs-ux-uitoolkit-react/card';
import './index.scss';
import { Accordion, AccordionPanel } from '@gs-ux-uitoolkit-react/accordion';
import { Alert } from '@gs-ux-uitoolkit-react/alert';
import { Icon } from '@gs-ux-uitoolkit-react/icon-font';
import InvestmentPolicyRuleRenderer from './InvestmentPolicyRuleRenderer';
import useFetchInvestmentPolicyData from '../../../../hooks/useInvestmentPolicyCard';
import useFetchInvestmentPolicyOfScopeAccounts from '../../../../hooks/useInvestmentPolicyOfScopeAccounts';
import CardError from '../../../DashboardCards/Card/CardError';
import { ThreeSquaresLoader } from '../../../../../../../components/core/Loaders';
import investmentPolicyTitle from './InvestmentPolicyTitle';
import translator from '../../../../../../../services/translator';

const { translate: t } = translator;

const InvestmentPolicyBody = ({ organizationId }) => {
  const [rowData, setRowData] = useState([]);
  const [warning, setWarning] = useState(false);

  const { loading, doFetchInvestmentPolicies, investmentPolicyData, error } = useFetchInvestmentPolicyData();
  const { doFetchOfScopeAccounts, ofScopeAccountsData } = useFetchInvestmentPolicyOfScopeAccounts(organizationId);

  const fetchPolicies = useCallback(() => {
    const payload = {
      'level': 'ORGANIZATION',
      organizationId
    };
    
    Promise.allSettled([doFetchInvestmentPolicies(payload), doFetchOfScopeAccounts()])
      .then((results) => {
        results.forEach((result) => {
          if (result.status === 'rejected') {
            console.error('Promise rejected:', result.reason);
          }
        });
      });
  }, [organizationId]);

  useEffect(() => {
    fetchPolicies();
  }, []);

  useEffect(() => {
    if (investmentPolicyData) {
      const { investmentPolicies = [] } = investmentPolicyData;
      const policiesMap = investmentPolicies
        .sort((a, b) => new Date(a.modifiedAt) - new Date(b.modifiedAt))
        .map(policy => ({
          policyName: policy.policyName,
          gtamPolicyId: policy.gtamPolicyId,
          rules: policy.rules,
          modifiedAt: policy.modifiedAt,
          createdAt: policy.createdAt
        }));
      setRowData(prevRowData => JSON.stringify(prevRowData) !== JSON.stringify(policiesMap) ? policiesMap : prevRowData);
    }
  }, [investmentPolicyData]);

  useEffect(() => {
    if (ofScopeAccountsData && ofScopeAccountsData.length > 0) {
      setWarning(true);
    } else {
      setWarning(false);
    }
  }, [ofScopeAccountsData]);

  return (
    <CardBody className='dbcard__investment-policy-body'>
      {!loading && error ? (
        <CardError errorMessage={error ? t('tkDashboardCardError') : t('tkFilterNoResultsFound')} />
      ) : (!loading && rowData?.length === 0) ? (
        <div className={'dbcard__noInvestmentPolicies'}>
          <span><Icon name='info'
                      type='outlined'
                      className='dbcard__noInvestmentPoliciesIcon'
          />{t('tkNoInvestmentPolicies')}</span>
        </div>
      ) : loading ? (
        <ThreeSquaresLoader />
      ) : (
        rowData && (
          <>
            <Alert
              status='warning'
              size='sm'
              emphasis='subtle'
              visible={warning}>
              {t('tkInvestmentPolicyAccountAlert')}
            </Alert>
            <Accordion className='scrollableAccordion' data-testid='investment-policy-accordion'>
              {rowData.map((row) => (
                <AccordionPanel
                  key={row.gtamPolicyId}
                  header={investmentPolicyTitle(row.policyName)}
                  defaultExpanded={row === rowData[0]}
                  classes={{ toggle: 'customToggle', body: 'customBody' }}
                >
                  {row.rules?.map((rule, ruleIndex) => (
                    <InvestmentPolicyRuleRenderer key={ruleIndex} rule={rule} ruleIndex={ruleIndex} panelIndex={row.gtamPolicyId} />
                  ))}
                </AccordionPanel>
              ))}
            </Accordion>
          </>
        ))}
    </CardBody>
  );
};

InvestmentPolicyBody.propTypes = {
  organizationId: PropTypes.string
};

export default memo(InvestmentPolicyBody);
