export default {
  FETCH_PREFERENCES_INVESTMENT_POLICIES: {
    'path': '/api/v1/investmentPolicy/investmentPolicyByCurrentUser',
    'method': 'GET'
  },
  FETCH_ALL_INVESTMENT_POLICIES: {
    'path': '/api/v1/investmentPolicy/query',
    'method': 'POST'
  },
  FETCH_INVESTMENT_POLICY_BY_ID: {
    'path': '/api/v1/investmentPolicy',
    'method': 'GET'
  },
  FETCH_OUT_OF_SCOPES_BY_ORGANIZATION: {
    'path': '/api/v1/investmentPolicy/ofScopeAccounts',
    'method': 'GET'
  }
}
