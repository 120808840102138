import { connect } from 'react-redux';
import {REBATE_CONSTANTS} from '../../../constants/pageConstants';
import {saveFundRebate} from '../../../actions/containers/fundFact';
import {isMimicSessionSelector} from '../../../selectors/app';
import {addPageContext} from '../../../actions/page';
import {eventAnalytics} from '../../../actions/sagas';
import {
  entitledToRebates, getFundRebate, getFundRebateEditStatus, getFundRebateLoading, getFundRebateStatus
} from '../../../selectors/containers/fundFactView';
import {FundRebate} from '../../../components/app/FundFact/FundRebate'

const mapStateToProps = (state) => ({
  fundRebate: getFundRebate(state),
  fundRebateLoading: getFundRebateLoading(state),
  fundRebateStatus: getFundRebateStatus(state),
  entitledToRebates: entitledToRebates(state),
  isMimicSession: isMimicSessionSelector(state),
  fundRebateEditStatus: getFundRebateEditStatus(state)
});

const mapDispatchToProps = (dispatch) => ({
  saveFundRebate: (data) => dispatch(saveFundRebate(data)),
  updateRebateStatusToUnsavedChanges: () => dispatch(addPageContext({fundRebateStatus: {type: REBATE_CONSTANTS.REBATE_UNSAVED_CHANGES}})),
  setFundRebateEditStatus: () => dispatch(addPageContext({fundRebateEditStatus: true})),
  setUnsavedRebateChanges: () => dispatch(addPageContext({unsavedRebateChanges: true})),
  unsetUnsavedRebateChanges: () => dispatch(addPageContext({unsavedRebateChanges: false})),
  sendAnalytics: (data) => dispatch(eventAnalytics(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(FundRebate);
