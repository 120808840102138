import endPointsMapper from '../../../../../configs/endPointsMapper';
import useFetch from '../useFetch';

const useSearchUserOrgFirm = () => {

  const { doFetch, loading, data } = useFetch(endPointsMapper.FETCH_USER_SEARCH);

  return { searchUserOrgFirm: doFetch, loading, data };
};

export default useSearchUserOrgFirm;
