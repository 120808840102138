// ---------- USER DETAILS EDIT SCENARIO & ORGANIZATION DETAILS VIEW MODE ------------
import { MODAL } from '../constants';

export const userMaintenanceInitialState = {
  activeTab: 'Profile',
  isEditable: false,
  disableSaveBtn: true,
  isPageEdited: false,
  updatedFields: [],
  modal: [
    {
      name: MODAL.unsavedChanges,
      display: false,
      content: 'tkUnsavedChangesModalText',
    },
    {
      name: MODAL.deactivationConfirmation,
      display: false,
      content: { deactivationContent: 'tkDeactivationConfirmationModalText', activationContent: 'tkActivationConfirmationModalText' },
    },
    {
      name: MODAL.allAccountsConfirmation,
      display: false,
      content: 'tkAllAccountsConfirmationModalText',
    },
  ],
  userDetails: {
    profile: {
      entitlements: {},
    },
  },
  backUpUserDetails: {
    profile: {
      entitlements: {},
    },
  },
  isInputValid: {},
  orgDetailsValidation: {},
  userDetailsValidation: {},
  deletedSsoClients: [],
  activeAccountsType: 'entitled',
  accountEntitlements: [],
  organizationAccounts: {},
  selectedOrganization: {},
  externalUserAccounts: {
    entitled: [],
    available: [],
  },
  originalAccountsTree: [],
  excludedFunds: [],
  defaultExcludedFunds: [],
  hideExcludedFunds: false,
  organizationDetails: {},
  mappedFirmsAccounts: [],
  firmsAvailableToMapCount: 0,
  mapUnmapFirmsList: [],
  isOrgSaved: false,
  updatedShareclassesAndFundGroups: {},
};
