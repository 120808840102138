import React from 'react';
import moment from 'moment';
import momentTimezone from 'moment-timezone';

// TODO: make this i18n
const notPresent = 'Unavailable';

export const getObject = (data, key) => {
  if (!data || !key) return undefined;

  return key.split('.').reduce((d, k) => {
    return d ? d[k] : undefined;
  }, data);
};

export const getData = (data, key) => {
  const keyObj = getObject(data, key);
  if (keyObj === undefined) {
    return notPresent;
  }
  return keyObj;
};

export const getDateInFormat = date => {
  if (!date || date === notPresent) {
    return notPresent;
  }
  return moment.utc(date).format('DD.MMM.YYYY');
};

export const getDecimalRoundOff = (value, n) => {
  if (!value || value === notPresent) {
    return notPresent;
  }
  return Number(value).toFixed(n);
};

export const getTimeZoneAbbr = timezone => {
  if (timezone !== 'Unavailable') {
    return momentTimezone.tz(timezone).zoneAbbr();
  }
  return '';
};

export const getLabel = ({ label, isLabelLink, labelFormatter }, data, index, translator) => {
  if (labelFormatter) {
    return labelFormatter.apply(this, [data]);
  }

  return (
    <div style={{ color: isLabelLink ? '#186ADE' : '#000' }} data-test={`label-${index}`}>
      {translator(label)}
    </div>
  );
};

export const isVisible = (visible, dataValue, getIndex, idCard, setVisible) => {
  const arr = visible;
  if (dataValue !== notPresent) {
    arr[getIndex.indexOf(idCard)] = true;
    setVisible(arr);
  }
};
