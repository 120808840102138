import React, {useEffect, useState, useMemo} from 'react';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash/cloneDeep';
import {Card, CardBody, CardHeader, CardTitle} from '@gs-ux-uitoolkit-react/card';
import {withScroll} from '../../../../components/core/Scrollbar';
import {Conditional} from '../../../../components/core/Conditional';
import AdjustedYieldToggle from '../../../../components/app/FundFact/FundRebate/AdjustedYieldToggler';
import {DATA_TYPE} from '../../constants';
import {getCardConfigurations} from './helpers/card-configs';
import translator from '../../../../services/translator';
import {getData, getDateInFormat, getDecimalRoundOff, isVisible, getLabel } from './helpers/utils';
import './index.scss';

const {translate: t} = translator;

const CardText = (idCard, config, data, headerSize, index, setVisible, visible, performance) => {
  const { subCards, key, subKey, formatter } = config;
  let idData = data?.type === DATA_TYPE.BENCHMARK ? performance : data;
  if (!idData) idData = data;

  const getIndex = ['performance', 'assets', 'liquidity', 'duration'];
  if (subCards) {
    const { title, id, config, asOfDate } = subCards;
    const show = visible[getIndex.indexOf(id)];
    return (
      <div key={`${key}-${index}`} style={{ marginBottom: 12 }}>
        <CardTitle style={{ margin: 0, display: 'flex' }} tag={`h${headerSize + 1}`}>
          <div style={{ flex: 1, color: '#000000', fontSize: '14px', lineHeight: '20px' }}>{t(title)}</div>
          {
            asOfDate && show && (
              <div
                style={{
                  color: '#3E5463',
                  fontWeight: 400,
                  fontSize: '14px',
                  lineHeight: '20px'
                }}>{`As of ${getDateInFormat(getData(idData, asOfDate))}`}</div>
            )
          }
        </CardTitle>
        <div>
          {
            config.map(c => {
              return CardText(id, c, idData, headerSize + 1, index, setVisible, visible);
            })
          }
        </div>
      </div>
    );
  } else {
    const valuesToRoundOff = [
      'shareclassPerformance.yield.amount',
      'shareclassPerformance.curYield7Day.amount',
      'shareclassPerformance.effYield7Day.amount',
      'shareclassPerformance.yield30Day.amount',
      'shareclassPerformance.liqDly.amount',
      'shareclassPerformance.ligWkly.amount'
    ];
    let dataValue;
    if (formatter) {
      dataValue = formatter.apply(this, [
        idData,
        key
      ]);
    } else if (valuesToRoundOff.indexOf(key) !== -1) {
      dataValue = getDecimalRoundOff(getData(idData, key, subKey), 2);
    } else {
      dataValue = getData(idData, key, subKey);
    }
    isVisible(visible, dataValue, getIndex, idCard, setVisible);
    return (
      <div key={`${key}-${index}`} style={{ display: 'flex' }}>
        <div style={{ flex: 1 }}>{getLabel(config, idData, index, t)}</div>
        <div style={{ fontWeight: 500, color: '#000000', textAlign: 'right' }} data-test={`value-${index}`}>
          {dataValue}
        </div>
      </div>
    );
  }
};

const CardBodyComponent = ({ title, innerConfig, id, data, setVisible, visible, performance }) => (
  <div data-test={t(title)}>
    {
      innerConfig.map((ic, index) => {
        return CardText(id, ic, data, 3, index, setVisible, visible, performance);
      })
    }
  </div>
);

CardBodyComponent.propTypes = {
  title: PropTypes.object,
  innerConfig: PropTypes.object,
  id: PropTypes.object,
  data: PropTypes.object,
  setVisible: PropTypes.func,
  visible: PropTypes.array,
  performance: PropTypes.object
};

const ShareClassCard = props => {
  const { config, data, index, performance, onChangeYieldState } = props;
  const isRebateExists = data?.shareclassPerformance?.isRebateExists;
  const { title, innerConfig, id, asOfDate } = config;
  const [visible, setVisible] = useState([false, false, false, false]);
  const isShowAdjYieldToggle = (id === 'performance') && isRebateExists;

  return (
    <Card style={{ height: '100%' }}>
      <CardHeader>
        <CardTitle style={{ display: 'flex' }}>
          <div style={{ flex: 1, color: '#000000', fontSize: '16px', lineHeight: '24px' }} data-test={`title-${index}`}>
            {t(title)}
          </div>
          {
            asOfDate && visible[0] && (
              <div
                style={{ color: '#3E5463', fontWeight: 400, fontSize: '14px', lineHeight: '20px' }}
                data-test={`titleAsOfDate-${index}`}>
                {`As of ${getDateInFormat(getData(data?.type === DATA_TYPE.BENCHMARK ? performance : data, asOfDate))}`}
              </div>
            )
          }
        </CardTitle>
        <Conditional condition={isShowAdjYieldToggle}>
          <AdjustedYieldToggle
            onChangeYieldState={onChangeYieldState}
          />
        </Conditional>
      </CardHeader>
      <CardBody>
        {
          withScroll(CardBodyComponent)({
            title,
            innerConfig,
            id,
            data,
            setVisible,
            visible,
            performance
          })
        }
      </CardBody>
    </Card>
  );
};

ShareClassCard.propTypes = {
  config: PropTypes.object,
  data: PropTypes.object,
  index: PropTypes.object,
  onChangeYieldState: PropTypes.func,
  performance: PropTypes.object
};

const CardComponent = ({shareclass = {}, shareClassPerformanceData = []}) => {
  const productPerformanceData = shareClassPerformanceData.find(data => data.id === shareclass.id);
  const [newCardConfig, setCardConfig] = useState([]);
  const isRebateExists = shareclass?.shareclassPerformance?.isRebateExists;
  const cardConfigurations = useMemo(() =>
    getCardConfigurations(shareclass.type),
    [shareclass.type]);

  useEffect(() => {
    const tempCardConfig = cloneDeep(cardConfigurations).map(conf => {
      if (conf.id === 'fundFacts') {
        const newInnerConfig = [...conf.innerConfig];
        const data = shareclass.shareclass;
        if (data) {
          const {shareClassDetails: {domicile} = {}} = data;
          if (domicile && domicile !== 'US') {
            newInnerConfig.splice(1, 2, {
              key: 'shareclass.isin',
              label: 'tkISIN'
            });
            conf.innerConfig = newInnerConfig;
          }
        }
      } else if (conf.id === 'performance') {
        conf.innerConfig = [...conf.innerConfig]
          .filter(({isAdjustedYield}) => {
            return isRebateExists ? isAdjustedYield : !isAdjustedYield;
          });
      }
      return conf;
    });
    setCardConfig(tempCardConfig);
  }, [cardConfigurations, isRebateExists, shareclass.shareclass, shareclass.type]);

  const onChangeYieldState = (yieldState) => {
    const tempCardConfig = cloneDeep(cardConfigurations).map(conf => {
      if (conf.id === 'performance') {
        conf.innerConfig = cardConfigurations
          .find(c1 => c1.id === 'performance')
          .innerConfig
          .filter(({isCommonField, isAdjustedYield}) => {
            return isCommonField || (yieldState === 'Adjusted' ? isAdjustedYield : !isAdjustedYield);
          });
      }
      return conf;
    });
    setCardConfig(tempCardConfig);
  };

  return (
    <div className='fund-fact-card'>
      {
        newCardConfig.map((config, index) => {
          return (
            <div
              key={`${config.title}-${index}`}
              style={{
                height: 335,
                width: 'calc(25% - 12px)',
                marginRight: '12px'
              }}>
              <ShareClassCard
                config={config}
                data={shareclass}
                index={index}
                onChangeYieldState={onChangeYieldState}
                performance={productPerformanceData}
              />
            </div>
          );
        })
      }
    </div>
  );
};

CardComponent.propTypes = {
  shareclass: PropTypes.object,
  shareClassPerformanceData: PropTypes.object
};

export default CardComponent;
