import React, { useRef, useEffect, useState, memo } from "react";
import PropTypes from "prop-types";
import { Button } from "@gs-ux-uitoolkit-react/button";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "@gs-ux-uitoolkit-react/modal";
import history from "../../../utils/history";
import translator from "../../../services/translator";
import "../../../modules/admin/UserSearch/components/UserDetails/Modal/index.scss";

const { translate: t } = translator;

const RouterPrompt = (props) => {
  const { when, title, content, analyticsCbFn } = props;

  const [showPrompt, setShowPrompt] = useState(false);
  const [currentPath, setCurrentPath] = useState("");
  const unblockRef = useRef();

  const onCancel = (btnName) => {
    setShowPrompt(false);
    btnName && analyticsCbFn?.onClose?.(btnName);
  };

  const handleConfirm = () => {
    if (unblockRef) {
      unblockRef.current();
    }
    analyticsCbFn?.onClose?.("Yes");
    setShowPrompt(false);
    history.push(currentPath);
  };

  useEffect(() => {
    const beforeunload = (event) => {
      event.preventDefault();
      return (event.returnValue = "");
    };

    if (when) {
      window.addEventListener("beforeunload", beforeunload);
    } else {
      window.removeEventListener("beforeunload", beforeunload);
    }

    return () => {
      window.removeEventListener("beforeunload", beforeunload);
    };
  }, [when]);

  useEffect(() => {
    unblockRef.current = history.block((location) => {
      if (when) {
        setCurrentPath(location.pathname);
        setShowPrompt(true);
        analyticsCbFn?.onLoad?.();
        return false;
      }
      return true;
    });
    return () => {
      unblockRef.current && unblockRef.current();
    };
  }, [when]);

  return (
    <Modal
      visible={showPrompt}
      onClose={() => onCancel(undefined)}
      placement="center"
      className="ssa-modal gs-uitk-override-css"
    >
      <ModalHeader
        onDismissButtonClick={() => onCancel("Close")}
        className="ssa-modal__header"
      >
        {title}
      </ModalHeader>
      <ModalBody className="ssa-modal__body">{t(content)}</ModalBody>
      <ModalFooter className="ssa-modal__footer">
        <Button onClick={() => onCancel("No")} actionType="secondary">
          {t("tkNo")}
        </Button>
        <Button onClick={handleConfirm} actionType="primary" autoFocus>
          {t("tkYes")}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

RouterPrompt.propTypes = {
  when: PropTypes.bool,
  title: PropTypes.string,
  content: PropTypes.string,
  analyticsCbFn: PropTypes.object,
};

export default memo(RouterPrompt);
