import { CssProperties } from '../types';
import { BrowserStyleEngine } from './browser-style-engine';
import { isBrowser } from '@gs-ux-uitoolkit-common/shared';
import { SsrStyleEngine } from './ssr-style-engine';
import { ClassNamesArg } from '@emotion/css/types/create-instance';

/**
 * An abstraction over Emotion (our current CSS-in-JS styling engine) and some
 * utility methods related to injecting CSS styles and external style sheets.
 *
 * This is used by {@link StyleSheet} to be able to inject styles into the DOM
 * or server-side environment without needing to worry about which environment
 * it's operating in.
 *
 * This also allows us to swap out the underlying styling engine easily in the
 * future (for instance, to change Emotion to something else, or to write our
 * own) easily in the future.
 */
export interface StyleEngine {
    /**
     * Inserts global CSS into the document.
     *
     * Example:
     *
     *    styleEngineImpl.insertGlobalCss({
     *        'body': {
     *            backgroundColor: 'blue'
     *        }
     *    });
     */
    insertGlobalCss(selectors: { [selector: string]: CssProperties }): void;

    /**
     * Inserts CSS styles into the document and returns the generated CSS class
     * name for them.
     *
     * Example:
     *
     *    const className = styleEngineImpl.insertCss({ color: 'red' });
     *    // 'gs-uitk-c-1234567'
     */
    insertCss(cssProperties: CssProperties): string;

    /**
     * Inserts an external StyleSheet into the document by its URL.
     *
     * Example:
     *
     *    styleEngineImpl.insertExternalStyleSheet('https://cdn.gs.com/fonts/notosansjp/v52/noto-sans-jp-300-400-500.css');
     */
    insertExternalStyleSheet(url: string): void;

    /**
     * "ClassNames" function for combining one or more CSS classes together.
     *
     * When given multiple CSS-in-JS class names, creates a single (new) class
     * name which combines their styles, in the correct order.
     *
     * If given any non-CSS-in-JS class names, their names will simply be
     * inserted into the returned string, but their style properties will depend
     * on the usual CSS specificity rules (selector precedence, source order,
     * `!important`, etc.)
     */
    cx(...classNames: ClassNamesArg[]): string;
}

/**
 * A module-level instance of the BrowserStyleEngine just to prevent multiple
 * from being instantiated, but not on the global object in case the
 * implementation needs to change and there are multiple UI Toolkit versions
 * being used on a single page.
 */
let styleEngine: StyleEngine;

/**
 * Returns the module-level StyleEngine instance, which will either be a
 * BrowserStyleEngine for browsers, or SsrStyleEngine for server-side rendering
 * environments.
 */
export function getStyleEngine() {
    if (!styleEngine) {
        styleEngine = isBrowser ? new BrowserStyleEngine() : new SsrStyleEngine();
    }
    return styleEngine;
}
