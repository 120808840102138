import ServiceManager from '../../../services';
import endPointsMapper from '../../../configs/endPointsMapper';
import {orderShareclassPerformance} from '../utils';

const fetchDataFromService = async (payLoad, endPointConfig) => {
  const response = await ServiceManager({
    endPointConfig,
    payLoad
  });
  const {data} = response || {};
  const responseData = data || response;
  const error = (responseData && responseData.error || response && response.error);
  if (error) {
    return Promise.reject(error);
  }
  return responseData;
};

/**
 * given shareclasses and benchmarks payloads, fetches their performance data
 * we wait for both results to resolve before sending the performance metrics.
 * it is not guaranteed that sc and bench payloads will always be present
 */
export const fetchFundAndBenchmarkPerformance = async ({ shareclassPayload, benchmarkPayload }) => {
  // check if SC should be fetched
  const { isin, cusip } = shareclassPayload || {};
  const fetchShareclasses = isin?.length > 0 || cusip?.length > 0;

  // check if iMN should be fetched
  const { vendorIds } = benchmarkPayload || {};
  const fetchBenchmarks = vendorIds?.length > 0;

  try {
    const [scPerf, benchPerf] = await Promise.allSettled([
      fetchShareclasses ? fetchDataFromService(shareclassPayload, endPointsMapper.FETCH_SHARE_CLASS_PERFORMANCE) : Promise.resolve(null),
      fetchBenchmarks ? fetchDataFromService(benchmarkPayload, endPointsMapper.FETCH_FUND_TRACKER_BENCHMARK_PERFORMANCE) : Promise.resolve(null)
    ]);

    const shareclassPerformance = scPerf.status === 'fulfilled' ? scPerf.value?.results || [] : [];
    const benchmarkPerformance = benchPerf.status === 'fulfilled' ? benchPerf.value?.fundTrackerBenchmarkPerformance || [] : [];

    if (scPerf.status === 'rejected') {
      // todo: show error message using snackbar that the performance could not be retrieve for the selected funds.
      console.error('Error fetching share class performance:', scPerf.reason);
    }

    if (benchPerf.status === 'rejected') {
      console.error('Error fetching benchmark performance:', benchPerf.reason);
    }

    return {
      shareclassPerformance,
      benchmarkPerformance
    };
  } catch (error) {
    console.error('Unexpected error:', error);
    return {
      shareclassPerformance: [],
      benchmarkPerformance: []
    };
  }
};

export const fetchAllChartData = async ({
  startDate,
  endDate,
  shareclassFieldValue: field,
  shareclassIds,
  vendorIds,
  isApplyRebates
}) => {
  const scPayload = { startDate, endDate, shareclassIds, field, isApplyRebates };
  const benchPayload = { startDate, endDate, vendorIds, field };

  try {
    const [shareClassesResponse, benchMarksResponse] = await Promise.all([
      shareclassIds.length > 0 ? fetchDataFromService(scPayload, endPointsMapper.FETCH_FUND_TRACKER_WIZARD_DATA) : Promise.resolve(null),
      vendorIds.length > 0 ? fetchDataFromService(benchPayload, endPointsMapper.FETCH_FUND_TRACKER_BENCHMARK) : Promise.resolve(null)
    ]);

    let { fundTracker } = shareClassesResponse || {};
    if (fundTracker) {
      fundTracker = orderShareclassPerformance(fundTracker, shareclassIds, 'shareclass.id');
    }

    return {
      shareclassSeries: fundTracker || [],
      benchmarkSeries: benchMarksResponse?.fundTrackerBenchmark || []
    };
  } catch (error) {
    console.error('Error fetching chart data:', error);
    // TODO: as part of ELSOL-67199, we should handle this error
    return {
      shareclassSeries: [],
      benchmarkSeries: []
    };
  }
};

const fetchShareClassesAndBenchmarkData = async ({userGuid}) => {
  const payLoad = { userGuid };
  const responseData = await fetchDataFromService(payLoad, endPointsMapper.FETCH_SHARE_CLASSES_AND_BENCHMARKS);
  const {getShareClassesAndBenchmarksForUser} = responseData;
  return getShareClassesAndBenchmarksForUser;
};

export const saveFundTrackerThresholdForUser = (thresholdPayload) => {
  return fetchDataFromService(thresholdPayload, endPointsMapper.SAVE_FUND_TRACKER_THRESHOLD);
};

export const getFundTrackerThresholdsForUser = (userGuid) => {
  const endPointConfig = {
    ...endPointsMapper.FETCH_FUND_TRACKER_THRESHOLDS,
    path: endPointsMapper.FETCH_FUND_TRACKER_THRESHOLDS.path.replace('{userGuid}', userGuid)
  };
  return fetchDataFromService(null, endPointConfig);
};

/**
 * given start & end dates, service fetches export data for funds and benchmarks,
 * depending on which is present in the payload
 */
const fetchExportData = async ({ startDate, endDate, shareclassIds, field, vendorIds }) => {
  const shareclassPayload = { startDate, endDate, shareclassIds, field };
  const benchmarkPayload = { startDate, endDate, vendorIds, field };
  const fetchBenchmarks = vendorIds?.length > 0;
  const fetchShareclasses = shareclassIds?.length > 0;
  const [fundsExportResponse, benchmarksExportResponse] = await Promise.all([
    fetchShareclasses && fetchDataFromService(shareclassPayload, endPointsMapper.FETCH_FUND_TRACKER_EXPORT_DATA),
    fetchBenchmarks && fetchDataFromService(benchmarkPayload, endPointsMapper.FETCH_FUND_TRACKER_BENCHMARK_EXPORT_DATA)
  ]);

  return {
    fundsExport: fundsExportResponse?.fundTrackerExport || [],
    benchmarksExport: benchmarksExportResponse?.fundTrackerBenchmarkExport || []
  };
};

export {
  fetchShareClassesAndBenchmarkData,
  fetchExportData
};
