import React, { memo, useState } from 'react';
import cn from 'classnames';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import InputField from './InputField';
import SwitchField from './SwitchField';
import DropdownField from './DropdownField';
import DropdownCheckBox from './DropdownCheckBox';
import InputWithSwitch from './InputWithSwitch';
import DropdownLabel from './DropdownLabel';
import AutoCompleteOrgSearch from './AutoCompleteOrgSearch';
import AutoCompleteUserDetail from './AutoCompleteUserDetail';
import LastUpdatedAtRenderer from '../UserDetails/Profile/LastUpdatedAtRenderer';
import translator from '../../../../../services/translator';
import { SWITCH, DROPDOWN, NA, TEXT, TEXT_WITH_SWITCH, AUTOCOMPLETE_ORG_SEARCH, AUTOCOMPLETE_USER_DETAILS, SSO_DROPDOWN, DROPDOWN_CHECKBOX, NO } from '../../constants';
import './index.scss';

const { translate: t } = translator;

const valueRendererConfig = {
  lastUpdatedAt: LastUpdatedAtRenderer,
};

const FieldRenderer = props => {
  const {
    config,
    value,
    isEditable,
    editModeDropdownData = [],
    featuresIcon,
    sectionStart,
    sectionEnd,
    showNAIfNull,
    handleChange,
    errorState,
    canDeleteField,
    activeIcon,
    fieldIndex,
    displayNoByDefault
  } = props;

  const { label: title, editableFieldType, valueFormatter, dropdownKey, index = '', valueRenderer, labelType = null } = config;

  const [ssoDropdownValue, setSsoDropdownValue] = useState(null);

  const getFormattedValue = () => {
    let val = value;
    if (valueFormatter) {
      val = valueFormatter(val, isEditable);
    }
    if (val === 'null' && showNAIfNull) {
      return NA;
    } else if (val && typeof val !== 'boolean') {
      return val;
    } else if (displayNoByDefault) {
      return NO;
    } else {
      return showNAIfNull ? NA : t('tkNullDataPlaceholder');
    }
  };

  const fieldValue = getFormattedValue();

  const getDropdownLabel = () => {
    const { label } = (editModeDropdownData && editModeDropdownData[dropdownKey]?.find(({ value }) => value === fieldValue)) || { label: fieldValue };
    return label;
  };

  const renderStatusValue = () => {
    return (
      <>
        <span
          data-testid='status-icon'
          className={cn({
            userdetails__activeIcon: fieldValue === 'Active',
            userdetails__inactiveIcon: fieldValue === 'Inactive',
          })}
        />
        {fieldValue}
      </>
    );
  };

  const renderEditComponents = () => {
    switch (editableFieldType) {
      case TEXT:
        return <InputField config={config} value={value} errorState={errorState} handleChange={handleChange} />;
      case SWITCH:
        return <SwitchField config={config} value={value} handleChange={handleChange} />;
      case DROPDOWN:
        return (
          <DropdownField
            errorState={errorState}
            config={config}
            value={value}
            handleChange={handleChange}
            options={editModeDropdownData[config.dropdownKey]}
          />
        );
      case DROPDOWN_CHECKBOX:
        return (
          <DropdownCheckBox
            config={config}
            value={value}
            handleChange={handleChange}
          />
        );
      case TEXT_WITH_SWITCH:
        return (
          <InputWithSwitch
            clientName={ssoDropdownValue}
            fieldIndex={fieldIndex}
            config={config}
            value={value}
            errorState={errorState}
            handleChange={handleChange}
          />
        );
      case AUTOCOMPLETE_ORG_SEARCH:
        return (
          <AutoCompleteOrgSearch
            fieldIndex={fieldIndex}
            config={config}
            value={value}
            handleChange={handleChange}
            errorState={errorState}
            canDeleteField={canDeleteField}
          />
        );
      case AUTOCOMPLETE_USER_DETAILS:
        return <AutoCompleteUserDetail fieldIndex={fieldIndex} config={config} value={value} handleChange={handleChange} errorState={errorState} />;
      default:
        return fieldValue;
    }
  };

  const renderEditableValues = () => {
    if (activeIcon) {
      return renderStatusValue();
    } else {
      return renderEditComponents();
    }
  };

  const renderValue = () => {
    let value = null;
    if (isEditable) {
      value = renderEditableValues();
    } else if (featuresIcon) {
      value = <span data-testid='feature-icon' className='userdetails__featuresIcon' />;
    } else if (editableFieldType === DROPDOWN) {
      value = getDropdownLabel();
    } else if (activeIcon) {
      value = renderStatusValue();
    } else {
      value = typeof fieldValue === 'string' ? fieldValue : '';
    }
    return { fieldData: value, tooltip: typeof value === 'string' ? value : '' };
  };

  const { fieldData, tooltip } = renderValue();

  const isDropDownLabel = labelType === SSO_DROPDOWN && isEditable;

  const ValueRendererComp = valueRendererConfig[valueRenderer]; // eslint-disable-line

  return (
    <div
      data-testid='userDetails-container'
      title={tooltip}
      className={cn('userdetails', {
        userdetails__sectionStart: sectionStart,
        userdetails__sectionEnd: sectionEnd,
      })}>
      <div
        className={cn('', {
          userdetails__ssoDropdown: isDropDownLabel,
          userdetails__label: !isDropDownLabel,
        })}>
        {isDropDownLabel ? (
          <DropdownLabel
            title={t(title)}
            fieldIndex={fieldIndex}
            value={value}
            config={config}
            ssoDropdownValue={ssoDropdownValue}
            handleChange={handleChange}
            setSsoDropdownValue={setSsoDropdownValue}
          />
        ) : (
          <div className='userdetails__field'>{t(title, index)}</div>
        )}
      </div>
      <div className='userdetails__detail'>
        <div
          className={cn('userdetails__value', {
            userdetails__switch: isEditable && editableFieldType === SWITCH,
            userdetails__dropdown: isEditable && [DROPDOWN, AUTOCOMPLETE_ORG_SEARCH, AUTOCOMPLETE_USER_DETAILS, DROPDOWN_CHECKBOX].includes(editableFieldType),
          })}>
          {ValueRendererComp ? <ValueRendererComp fieldData={fieldData} /> : fieldData}
        </div>
      </div>
    </div>
  );
};

FieldRenderer.propTypes = {
  handleChange: PropTypes.func,
  config: PropTypes.object,
  sectionStart: PropTypes.bool,
  sectionEnd: PropTypes.bool,
  value: PropTypes.any,
  isEditable: PropTypes.bool,
  featuresIcon: PropTypes.bool,
  editModeDropdownData: PropTypes.object,
  showNAIfNull: PropTypes.bool,
  errorState: PropTypes.object,
  canDeleteField: PropTypes.bool,
  activeIcon: PropTypes.bool,
  fieldIndex: PropTypes.number,
  valueRenderer: PropTypes.string,
  displayNoByDefault: PropTypes.bool
};

const propsAreEqual = (prevProps, nextProps) => {
  const { handleChange, config, editModeDropdownData, ...restPrevProps } = prevProps;
  const { handleChange: change, config: nextConfig, editModeDropdownData: dropdownData, ...restNextProps } = nextProps;
  return isEqual(restPrevProps, restNextProps);
};

export default memo(FieldRenderer, propsAreEqual);
