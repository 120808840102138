import React, { useState, useMemo } from 'react';
import { Checkbox } from '@gs-ux-uitoolkit-react/checkbox';
import cn from 'classnames';
import { Tabs } from '../../UserDetails/TabSwitchBar';
import Profile from '../Profile';
import Accounts from '../Accounts';
import Funds from '../Funds';
import Users from '../Users';
import HelpGuideTray from '../../../../../../components/core/HelpGuideTray';
import useConsumeHelpGuide from '../../../../../../components/core/HelpGuideTray/useConsumeHelpGuide';
import useUserMaintenance from '../../../context';
import RouterPrompt from '../../../../../../components/core/RouterPrompt';
import Snackbar from '../../../../../../components/core/Snackbar';
import { Conditional } from '../../../../../../components/core/Conditional';
import { TAB_SWITCHED } from '../../../actions';
import { SNACKBAR_AUTO_DISMISS } from '../../../constants';
import translator from '../../../../../../services/translator';
import {
  dispatchAnalytics,
  helpGuideCheckboxClick,
  helpGuidePreviousNextClicks,
  unsavedChangesModal as unsavedChangesModalAnalytics,
  unsavedChangesModalCTA,
} from '../../../analytics';

const { translate: t } = translator;

export const ORG_DETAILS_TABS = {
  PROFILE: 'Profile',
  ACCOUNTS: 'Accounts',
  FUNDS: 'Funds',
  USERS: 'Users',
};

const TabSwitchBar = () => {
  const {
    dispatch,
    state: {
      activeTab,
      isOrgPageEdited,
      isOrgEditable,
      modal: [unsavedChangesModal],
    },
    reduxDispatch,
    storeValues: { userGuid },
  } = useUserMaintenance();
  const [showSnackBar, setShowSnackBar] = useState(false);

  const { selectedRowData, handleNextBtn, handlePreviousBtn, toggleHelpGuideActive, helpGuideActive, closeHelpGuide } = useConsumeHelpGuide();
  const tabConfig = useMemo(
    () => [
      { id: ORG_DETAILS_TABS.PROFILE, label: 'tkProfile', Component: Profile },
      { id: ORG_DETAILS_TABS.ACCOUNTS, label: 'tkAccounts', Component: Accounts },
      { id: ORG_DETAILS_TABS.USERS, label: 'tkUsers', Component: Users },
      { id: ORG_DETAILS_TABS.FUNDS, label: 'tkFunds', Component: Funds },
    ],
    []
  );

  const switchTab = tabId => {
    closeHelpGuide();
    dispatch({ type: TAB_SWITCHED, payload: tabId });
  };

  const toggleHelpGuide = () => {
    helpGuideActive && setShowSnackBar(true);
    toggleHelpGuideActive(prevState => {
      dispatchAnalytics(reduxDispatch, helpGuideCheckboxClick({ guid: userGuid, isChecked: !prevState, tabName: activeTab }));
      return !prevState;
    });
  };

  const handlePreviousWithAnalytics = fieldName => {
    handlePreviousBtn(fieldName);
    dispatchAnalytics(reduxDispatch, helpGuidePreviousNextClicks({ guid: userGuid, tabName: activeTab, btn: 'Previous' }));
  };

  const handleNextWithAnalytics = fieldName => {
    handleNextBtn(fieldName);
    dispatchAnalytics(reduxDispatch, helpGuidePreviousNextClicks({ guid: userGuid, tabName: activeTab, btn: 'Next' }));
  };

  const onRouterPromptLoad = () => {
    dispatchAnalytics(reduxDispatch, unsavedChangesModalAnalytics({ pageKey: 'Organization', tabName: activeTab, guid: userGuid }));
  };

  const onRouterPromptClose = btnName => {
    dispatchAnalytics(reduxDispatch, unsavedChangesModalCTA({ pageKey: 'Organization', tabName: activeTab, guid: userGuid, btnName }));
  };

  return (
    <>
      <RouterPrompt
        when={!!isOrgPageEdited}
        title={t('tkWarning')}
        content={unsavedChangesModal.content}
        analyticsCbFn={{ onLoad: onRouterPromptLoad, onClose: onRouterPromptClose }}
      />
      {helpGuideActive && showSnackBar && <Snackbar type='info' hideTime={SNACKBAR_AUTO_DISMISS} autoHide={true} msgCopy={t('tkHelpGuide')} />}
      <div className='user-profile-container' data-testid='org-details-switch'>
        {helpGuideActive && showSnackBar && <Snackbar type='info' msgCopy={t('tkHelpGuide')} />}
        <div className='tabswitchbar'>
          <div className='tabswitchbar__left'>
            {tabConfig.map(({ id, label }, idx) => (
              <Tabs key={idx} tabId={id} label={t(label)} currentTab={activeTab} switchTab={switchTab} />
            ))}
          </div>
          <div className='tabswitchbar__right'>
            <Checkbox checked={helpGuideActive} onChange={toggleHelpGuide}>
              {t('tkTurnOnHelpGuide')}
            </Checkbox>
          </div>
        </div>
      </div>
      <div
        className={cn('details-container', {
          'fullwidth-tab': activeTab === ORG_DETAILS_TABS.FUNDS || activeTab === ORG_DETAILS_TABS.USERS,
          pageInEditMode: isOrgEditable,
        })}
        data-testid='usersearch-details-tabs'>
        {tabConfig.map(({ label, Component, id }, idx) => (
          <Conditional key={idx} condition={activeTab === id}>
            <div className='tab-content' style={t(label) === t('tkAccounts') ? { paddingTop: 0 } : {}}>
              <Component />
            </div>
          </Conditional>
        ))}
        {!!selectedRowData && helpGuideActive && (
          <HelpGuideTray
            selectedRowData={selectedRowData}
            handlePreviousBtn={handlePreviousWithAnalytics}
            handleNextBtn={handleNextWithAnalytics}
            closeHelpGuide={closeHelpGuide}
          />
        )}
      </div>
    </>
  );
};

export default TabSwitchBar;
