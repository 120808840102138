import PropTypes from 'prop-types';
import translator from '../../../../../services/translator';
import {capitalizeString} from '../../../../../utils/stringUtils';

const { translate: t } = translator;

const ApiHelpGuideRender = ({ config }) => {
  return (
    <div>
      {
        config.map(data => {
          return (
            <div>
              <span>{data.label}:&nbsp;</span>
              <a href={data.link} target='_blank'>{data.link}</a>
            </div>
          )
        })
      }
      <span></span>
    </div>
  )
}

ApiHelpGuideRender.propTypes = {
  config: PropTypes.object,
};

const ApiProductsHelpGuideRender = () => <ApiHelpGuideRender config={[{ label: t("tkView"), link: t("tkApiProductsHelpGuide") }]} />
const ApiTransactionsHelpGuideRender = () => <ApiHelpGuideRender config={[{ label: t("tkView"), link: t("tkApiTransactionsViewHelpGuide") }, { label: capitalizeString(t("tkEdit")), link: t("tkApiTransactionsEditHelpGuide") }]} />
const ApiAccountsHelpGuideRender = () => <ApiHelpGuideRender config={[{ label: t("tkView"), link: t("tkApiAccountsHelpGuide") }]} />
const ApiOrganizationsHelpGuideRender = () => <ApiHelpGuideRender config={[{ label: t("tkViewAndEdit"), link: t("tkApiOrganizationsHelpGuide") }]} />
const ApiUsersHelpGuideRender = () => <ApiHelpGuideRender config={[{label: t("tkViewAndEdit"), link: t("tkApiUsersHelpGuide")}]} />

export const helpGuideConfig = {
  userDetails: {
    profile: [
      // Identifying Information
      { index: 0, section: 'tkIdentifyingInformation', fieldName: 'tkFirstName', content: 'tkFirstNameHelpGuide' },
      { index: 1, section: 'tkIdentifyingInformation', fieldName: 'tkLastName', content: 'tkLastNameHelpGuide' },
      { index: 2, section: 'tkIdentifyingInformation', fieldName: 'tkUserId', content: 'tkUserIdHelpGuide' },
      {
        index: 3,
        section: 'tkIdentifyingInformation',
        fieldName: 'tkOrganization',
        content: 'tkOrganizationHelpGuide',
      },
      { index: 4, section: 'tkIdentifyingInformation', fieldName: 'tkGuid', content: 'tkGUIDHelpGuide' },
      {
        index: 5,
        section: 'tkIdentifyingInformation',
        fieldName: 'tkiMoneyNetID',
        content: 'tkIMoneyNetIDHelpGuide',
        internalUserField: true,
      },
      {
        index: 6,
        section: 'tkIdentifyingInformation',
        fieldName: 'tkGSInternal',
        content: 'tkGSInternalHelpGuide',
        internalUserField: true,
      },
      {
        index: 7,
        section: 'tkIdentifyingInformation',
        fieldName: 'tkMosaicInternal',
        content: 'tkMosaicInternalHelpGuide',
        internalUserField: true,
      },

      // Contact Information
      { index: 8, section: 'tkContactInformation', fieldName: 'tkWorkEmailAddress', content: 'tkWorkEmailHelpGuide' },
      { index: 9, section: 'tkContactInformation', fieldName: 'tkWorkPhone', content: 'tkWorkPhoneHelpGuide' },

      // Third Party Partner SSO Entitlements
      {
        index: 10,
        section: 'tkThirdPartyPartnerSsoEntitlements',
        fieldName: 'tkClearwaterId',
        content: 'tkClearwaterIDHelpGuide',
      },
      {
        index: 11,
        section: 'tkThirdPartyPartnerSsoEntitlements',
        fieldName: 'tkIonTreasuryId',
        content: 'tkIONTreasuryIDHelpGuide',
      },
      {
        index: 12,
        section: 'tkThirdPartyPartnerSsoEntitlements',
        fieldName: 'tkGTreasuryId',
        content: 'tkGTreasuryIDHelpGuide',
      },
      {
        index: 13,
        section: 'tkThirdPartyPartnerSsoEntitlements',
        fieldName: 'tkKyriba',
        content: 'tkKyribaHelpGuide',
      },
      {
        index: 14,
        section: 'tkThirdPartyPartnerSsoEntitlements',
        fieldName: 'tkIsxFinancial',
        content: 'tkIsxFinancialHelpGuide',
      },
      {
        index: 15,
        section: 'tkThirdPartyPartnerSsoEntitlements',
        fieldName: 'tkHuntingtonNb',
        content: 'tkHuntingtonNbHelpGuide',
      },

      // Setup
      { index: 16, section: 'tkSetup', fieldName: 'tkProfile', content: 'tkProfileHelpGuide', internalUserField: true },
      { index: 17, section: 'tkSetup', fieldName: 'tkAccessType', content: 'tkAccessTypeHelpGuide', internalUserField: true },
      { index: 18, section: 'tkSetup', fieldName: 'tkTimeZone', content: 'tkTimezoneHelpGuide' },
      { index: 19, section: 'tkSetup', fieldName: 'tkNSCCAccess', content: 'tkNSCCAccessHelpGuide', internalUserField: true },
      { index: 20, section: 'tkSetup', fieldName: 'tkJurisdiction', content: 'tkJurisdictionHelpGuide' },

      // Functionality
      {
        index: 21,
        section: 'tkFunctionality',
        fieldName: 'tkEndClientBuffer',
        content: 'tkEndClientBufferHelpGuide',
        internalUserField: true,
      },
      {
        index: 22,
        section: 'tkFunctionality',
        fieldName: 'tkAllowShellAccounts',
        content: 'tkAllowShellAccountsHelpGuide',
        internalUserField: true,
      },
      {
        index: 23,
        section: 'tkFunctionality',
        fieldName: 'tkAllowCloseouts',
        content: 'tkAllowCloseoutsHelpGuide',
        internalUserField: true,
      },

      // Access
      {
        index: 24,
        section: 'tkAccess',
        fieldName: 'tkMosaicLastLogin',
        content: 'tkMosaicLastLoginHelpGuide',
      },
      { index: 25, section: 'tkAccess', fieldName: 'tkSSOOnly', content: 'tkSSOOnlyHelpGuide' },
      { 
        index: 26, 
        section: 'tkAccess', 
        fieldName: 'tkBlocked', 
        content: 'tkBlockedHelpGuide' 
      },
      // Change Management Details
      {
        index: 27,
        section: 'tkChangeManagementDetails',
        fieldName: 'tkAccountCreationDate',
        content: 'tkAccountCreationDateHelpGuide',
      },
      {
        index: 28,
        section: 'tkChangeManagementDetails',
        fieldName: 'tkAccountProfileLastModifiedDate',
        content: 'tkAccountProfileLastModifiedDateHelpGuide',
      },
    ],
    features: [
      // Feature Entitlements - Core
      {
        index: 0,
        section: 'tkFeatureEntitlementsCore',
        fieldName: 'tkContactUs',
        content: 'tkContactUsHelpGuide',
      },
      {
        index: 1,
        section: 'tkFeatureEntitlementsCore',
        fieldName: 'tkFAQAndGlossary',
        content: 'tkFAQ&GlossaryHelpGuide',
      },
      {
        index: 2,
        section: 'tkFeatureEntitlementsCore',
        fieldName: 'tkMessageCenter',
        content: 'tkMessageCenterHelpGuide',
      },

      // Feature Entitlements - Account
      {
        index: 3,
        section: 'tkFeatureEntitlementsAccount',
        fieldName: 'tkAccountDetails',
        content: 'tkAccountDetailsHelpGuide',
      },
      {
        index: 4,
        section: 'tkFeatureEntitlementsAccount',
        fieldName: 'tkActivityTransactions',
        content: 'tkActivityTransactionHelpGuide',
      },
      {
        index: 5,
        section: 'tkFeatureEntitlementsAccount',
        fieldName: 'tkInvestmentPolicy',
        content: 'tkInvestmentPolicyHelpGuide',
      },
      {
        index: 6,
        section: 'tkFeatureEntitlementsAccount',
        fieldName: 'tkYourPortfolio',
        content: 'tkPortfolioHelpGuide',
      },
      {
        index: 7,
        section: 'tkFeatureEntitlementsAccount',
        fieldName: 'tkReporting',
        content: 'tkReportingHelpGuide',
      },

      // Feature Entitlements - Fund
      {
        index: 8,
        section: 'tkFeatureEntitlementsFund',
        fieldName: 'tkFundFinder',
        content: 'tkFundFinderHelpGuide',
      },
      {
        index: 9,
        section: 'tkFeatureEntitlementsFund',
        fieldName: 'tkFundTracker',
        content: 'tkFundTrackerHelpGuide',
      },
      {
        index: 10,
        section: 'tkFeatureEntitlementsFund',
        fieldName: 'tkGSAMFloatingNAVCalc',
        content: 'tkGSAMFloatingNAVCalculatorHelpGuide',
      },
      {
        index: 11,
        section: 'tkFeatureEntitlementsFund',
        fieldName: 'tkNavTransparencyInsights',
        content: 'tkTransparencyInsightsHelpGuide',
      },
      {
        index: 12,
        section: 'tkFeatureEntitlementsFund',
        fieldName: 'tkOrganizationRebates',
        content: 'tkOrganizationRebatesHelpGuide',
        isVisibleOnlyForAdmin: true
      },

      // Feature Entitlements - Trading
      {
        index: 13,
        section: 'tkFeatureEntitlementsTrading',
        fieldName: 'tkTradeApproval',
        content: 'tkTradeApprovalHelpGuide',
      },
      {
        index: 14,
        section: 'tkFeatureEntitlementsTrading',
        fieldName: 'tkTradeBlotter',
        content: 'tkTradeBlotterHelpGuide',
      },
      {
        index: 15,
        section: 'tkFeatureEntitlementsTrading',
        fieldName: 'tkTradeImport',
        content: 'tkTradeImportHelpGuide',
      },      
      // Reporting Entitlements - Core Reports
      {
        index: 16,
        section: 'tkReportingEntitlementsCoreReports',
        fieldName: 'tkAccountBalances',
        content: 'tkAccountBalancesHelpGuide',
      },
      {
        index: 17,
        section: 'tkReportingEntitlementsCoreReports',
        fieldName: 'tkCurrentTradeStatus',
        content: 'tkCurrentTradeStatusHelpGuide',
      },
      {
        index: 18,
        section: 'tkReportingEntitlementsCoreReports',
        fieldName: 'tkCurrentTradeTotalsbyFund',
        content: 'tkCurrentTradeTotalsbyFundHelpGuide',
      },
      {
        index: 19,
        section: 'tkReportingEntitlementsCoreReports',
        fieldName: 'tkFundBalances',
        content: 'tkFundBalancesHelpGuide',
      },
      {
        index: 20,
        section: 'tkReportingEntitlementsCoreReports',
        fieldName: 'tkFundRatesandFactors',
        content: 'tkFundRatesandFactorsHelpGuide',
      },
      {
        index: 21,
        section: 'tkReportingEntitlementsCoreReports',
        fieldName: 'tkGainLossOffshore',
        content: 'tkGainLossNAVMethodsforVariableMMFHelpGuide',
      },
      {
        index: 22,
        section: 'tkReportingEntitlementsCoreReports',
        fieldName: 'tkGainLoss',
        content: 'tkGainLossNAVMethodsforFNAVMMFHelpGuide',
      },
      {
        index: 23,
        section: 'tkReportingEntitlementsCoreReports',
        fieldName: 'tkHistoricalTransaction',
        content: 'tkHistoricalTransactionHelpGuide',
      },
      {
        index: 24,
        section: 'tkReportingEntitlementsCoreReports',
        fieldName: 'tkMonthlyDividendActivity',
        content: 'tkMonthlyDividendActivityHelpGuide',
      },
      {
        index: 25,
        section: 'tkReportingEntitlementsCoreReports',
        fieldName: 'tkWireSettlement',
        content: 'tkWireSettlementHelpGuide',
      },

      // Reporting Entitlements - Other Reports
      {
        index: 26,
        section: 'tkReportingEntitlementsOtherReports',
        fieldName: 'tkClassFee',
        content: 'tkClassFeesHelpGuide',
      },
      {
        index: 27,
        section: 'tkReportingEntitlementsOtherReports',
        fieldName: 'tkDebitCreditAccountListing',
        content: 'tkDebitCreditAccountListingHelpGuide',
      },
      {
        index: 28,
        section: 'tkReportingEntitlementsOtherReports',
        fieldName: 'tkDebitCreditDailyFeedSummaryActivity',
        content: 'tkDebitCreditDailyFeedSummaryActivityHelpGuide',
      },
      {
        index: 29,
        section: 'tkReportingEntitlementsOtherReports',
        fieldName: 'tkDebitCreditDailyTransactionActivity',
        content: 'tkDebitCreditDailyTransactionActivityHelpGuide',
      },
      {
        index: 30,
        section: 'tkReportingEntitlementsOtherReports',
        fieldName: 'tkDebitCreditSettlementSummary',
        content: 'tkDebitCreditSettlementSummaryHelpGuide',
      },
      {
        index: 31,
        section: 'tkReportingEntitlementsOtherReports',
        fieldName: 'tkExPostCostsChargesSummary',
        content: 'tkExPostCosts&ChargesSummaryHelpGuide',
      },
      {
        index: 32,
        section: 'tkReportingEntitlementsOtherReports',
        fieldName: 'tkHistoricalTradeApprovalException',
        content: 'tkHistoricalTradeApprovalExceptionHelpGuide',
      },
      {
        index: 33,
        section: 'tkReportingEntitlementsOtherReports',
        fieldName: 'tkMonthlyServiceFee',
        content: 'tkMonthlyServiceFeeHelpGuide',
      },
      {
        index: 34,
        section: 'tkApi',
        fieldName: 'tkApiAccounts',
        CustomRender: ApiAccountsHelpGuideRender,
        internalUserField: true
      },
      {
        index: 35,
        section: 'tkApi',
        fieldName: 'tkApiOrganizations',
        CustomRender: ApiOrganizationsHelpGuideRender,
        internalUserField: true
      },
      {
        index: 36,
        section: 'tkApi',
        fieldName: 'tkApiProducts',
        CustomRender: ApiProductsHelpGuideRender,
        internalUserField: true
      },
      {
        index: 37,
        section: 'tkApi',
        fieldName: 'tkApiTransactions',
        CustomRender: ApiTransactionsHelpGuideRender,
        internalUserField: true
      },           
      {
        index: 38,
        section: 'tkApi',
        fieldName: 'tkApiUsers',
        CustomRender: ApiUsersHelpGuideRender,
        internalUserField: true
      },
    ],
    accounts: [],
    funds: [
      // Fund Type Filters
      {
        index: 0,
        section: 'tkFundTypeFilters',
        fieldName: 'tkMonthlyServiceFee',
        content: 'tkFundTypeFiltersMonthlyServiceFeeHelpGuide',
      },
      {
        index: 1,
        section: 'tkFundTypeFilters',
        fieldName: 'tkFundFamily',
        content: 'tkFundFamilyHelpGuide',
      },
      {
        index: 2,
        section: 'tkFundTypeFilters',
        fieldName: 'tkDomicile',
        content: 'tkDomicileHelpGuide',
      },
      {
        index: 3,
        section: 'tkFundTypeFilters',
        fieldName: 'tkInvestmentType',
        content: 'tkInvestmentTypeHelpGudie',
      },
      {
        index: 4,
        section: 'tkFundTypeFilters',
        fieldName: 'tkShareClassType',
        content: 'tkShareClassTypeHelpGuide',
      },
    ],
  },
  organizationDetails: {
    profile: [
      // Identifying Information
      { index: 0, section: 'tkIdentifyingInformation', fieldName: 'tkOrganizationName', content: 'tkOrganizationNameHelpGuide' },
      { index: 1, section: 'tkIdentifyingInformation', fieldName: 'tkOrganizationId', content: 'tkOrganizationIdHelpGuide' },
      { index: 2, section: 'tkIdentifyingInformation', fieldName: 'tkCity', content: 'tkCityHelpGuide' },
      { index: 3, section: 'tkIdentifyingInformation', fieldName: 'tkState', content: 'tkStateHelpGuide' },
      { index: 4, section: 'tkIdentifyingInformation', fieldName: 'tkCountry', content: 'tkCountryHelpGuide' },
      { index: 5, section: 'tkIdentifyingInformation', fieldName: 'tkChannel', content: 'tkChannelHelpGuide' },
      { index: 6, section: 'tkIdentifyingInformation', fieldName: 'tkCoverage', content: 'tkCoverageHelpGuide' },

      // Organization Relationships
      { index: 7, section: 'tkOrganizationRelationship', fieldName: 'tkSubsidiaryOf', content: 'tkSubsidiaryOfHelpGuide' },
      { index: 8, section: 'tkOrganizationRelationship', fieldName: 'tkIntermediary', content: 'tkIntermediaryHelpGuide' },

      // Clearing Agents
      { index: 9, section: 'tkClearingAgents', fieldName: 'tkClearingAgentField', content: 'tkClearingAgentHelpGuide' },

      // Setup
      { index: 10, section: 'tkSetup', fieldName: 'tkStatus', content: 'tkStatusHelpGuide' },
      { index: 11, section: 'tkSetup', fieldName: 'tkMakerCheckerApprovals', content: 'tkMakerCheckerHelpGuide' },

      // Fund Rebates
      { index: 12, section: 'tkFundRebates', fieldName: 'tkShareClassRebates', content: 'tkShareClassRebatesHelpGuide' },
      { index: 13, section: 'tkFundRebates', fieldName: 'tkFundFamilyRebates', content: 'tkFundFamilyRebatesHelpGuide' },

      // Change Management Details
      { index: 14, section: 'tkChangeManagementDetails', fieldName: 'tkCreationDate', content: 'tkOrgCreationDateHelpGuide' },
      { index: 15, section: 'tkChangeManagementDetails', fieldName: 'tkLastModifiedDate', content: 'tkOrgLastModifiedHelpGuide' },
    ],
    accounts: [],
    funds: [
      // Fund Type Filters
      {
        index: 0,
        section: 'tkFundTypeFilters',
        fieldName: 'tkMonthlyServiceFee',
        content: 'tkFundTypeFiltersMonthlyServiceFeeHelpGuide',
      },
      {
        index: 1,
        section: 'tkFundTypeFilters',
        fieldName: 'tkFundFamily',
        content: 'tkFundFamilyHelpGuide',
      },
      {
        index: 2,
        section: 'tkFundTypeFilters',
        fieldName: 'tkDomicile',
        content: 'tkDomicileHelpGuide',
      },
      {
        index: 3,
        section: 'tkFundTypeFilters',
        fieldName: 'tkInvestmentType',
        content: 'tkInvestmentTypeHelpGudie',
      },
      {
        index: 4,
        section: 'tkFundTypeFilters',
        fieldName: 'tkShareClassType',
        content: 'tkShareClassTypeHelpGuide',
      },
    ],
    users: [],
  },
};
