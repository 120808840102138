import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import ruleMapping from './InvestmentPolicyRuleMapper';
import iconMapper from './InvestmentPolicyIcon';
import { currencyFormatter } from '../../../../../../../utils/amountFormatter';

import translator from '../../../../../../../services/translator';
const { translate: t } = translator;
const InvestmentPolicyRuleRenderer = ({ rule, ruleIndex, panelIndex }) => {

  const getLimitValueDisplay = useMemo(() => {
    return () => {
      if (rule?.ruleType === 'PCT_OF_TOTAL_CASH_IN_SINGLE_FUND' || rule?.ruleType === 'EXPOSURE_IN_SINGLE_FUND') {
        return `${rule?.limitValue}%`;
      }
      return `${currencyFormatter(rule?.limitValue, 2)} ${rule?.currencyCode}`;
    };
  }, [rule]);

  return (
    <div className='dbcard__investment-policy-rule'>
        <span>{t(ruleMapping[rule?.ruleType])} {'<='} <strong className='dbcard__investment-policy-limit-value'>{getLimitValueDisplay()}.</strong></span>
        {iconMapper({ currentRuleAction: rule?.onBreachAction, panelIndex, ruleIndex })}
    </div>
  );
};

InvestmentPolicyRuleRenderer.propTypes = {
  rule: PropTypes.object,
  ruleIndex: PropTypes.number,
  panelIndex: PropTypes.number
};

export default InvestmentPolicyRuleRenderer;
