import translator from '../../../../services/translator';

const { translate: t } = translator;

export const decideHelpGuideArray = (
  helpGuideConfig, currentTab, isInternalUser, isMigratedToSsa, userAdminEdit
) => {
  const currentTabHelpGuideConfig = (helpGuideConfig && helpGuideConfig[currentTab.toLowerCase()]) || [];
  const helpGuideArray = isInternalUser
    ? currentTabHelpGuideConfig.sort((firstItem, secondItem) => firstItem.index - secondItem.index)
    : currentTabHelpGuideConfig.filter(item => !('internalUserField' in item)).sort((firstItem, secondItem) => firstItem.index - secondItem.index);
  return helpGuideArray
    .filter(({ isVisibleOnlyForAdmin }) => {
      if (isVisibleOnlyForAdmin) {
        return isMigratedToSsa && userAdminEdit;
      }
      return true;
    })
    .map(item => ({ ...item, fieldName: t(item.fieldName) }));
};
