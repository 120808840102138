/* eslint-disable react/jsx-no-useless-fragment */

import React, {useRef, useState, useEffect} from 'react';
import {CSSTransition, TransitionGroup} from 'react-transition-group';
import { useDispatch, connect } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames';
import DOMPurify from 'dompurify';
import Snackbar from '../../../../components/core/Snackbar';
import Modal from '../../../../components/core/Modal';
import Button from '../../../../components/core/Button';
import translator from '../../../../services/translator';
import Link from '../../../../components/core/Link';
import Checkbox from '../../../../components/core/CheckBox';
import AppConstants from '../../../../constants/appConstants';
import history from '../../../../utils/history';
import {getAppRoute} from '../../../../utils/commonUtils';
import {getSelectedShareClassIds} from '../../utils';
import {getFundTrackerThresholds} from '../../services';
import { useAsync } from '../../utils/hooks';
import {Conditional} from '../../../../components/core/Conditional';
import {withScroll} from '../../../../components/core/Scrollbar';
import {ThresholdGroupLabelRenderer} from '../ThresholdGroupLabelRenderer';
import {ThresholdLabelRenderer} from '../ThresholdLabelRenderer';
import { isRebateExistsForSomeFundSelector } from '../../../../selectors/pages/fundTracker';
import './index.scss';

const {translate: t, labels} = translator;

const setThresholdModalStyle = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  height: 'auto',
  transform: 'translate(-50%, -50%)'
};

const InputField = ({inputLabel, isFieldValid, isFieldDecimalPlaceValid, placeholder, onChange, onBlur, value = ''}) => {
  const validNumberRegEx = /^-?\d*\.?\d*$/;
  const onInputValueChange = (inputVal) => {
    const validValue = validNumberRegEx.test(inputVal) ? inputVal : value;
    onChange(validValue);
  };
  const validationMsg = !isFieldDecimalPlaceValid ?
    t('tkThresholdDecimalErrorMsg') : t('tkThresholdValidationMsg');

  return (
    <div className='input-container'>
      <span className='set-threshold-modal__inputLabel' style={{width: '90%'}}>{inputLabel}</span>
      <input
        data-testid='set-threshold-modal-input'
        className={cn('input', {'input--error': !isFieldValid})}
        value={value}
        placeholder={placeholder}
        onBlur={e => onBlur(e.target.value)}
        onChange={e => onInputValueChange(e.target.value)}
      />
      <Conditional condition={!isFieldValid}>
        <span className='validation-msg'>{validationMsg}</span>
      </Conditional>
    </div>
  );
};

InputField.propTypes = {
  inputLabel: PropTypes.string,
  isFieldValid: PropTypes.bool,
  isFieldDecimalPlaceValid: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.string,
  placeholder: PropTypes.bool
};

export const simpleRenderer = ({option, hoverOption, isHovered, selectOption, labelKey, valueKey, selectedLabel}) => {
  const isSelected = selectedLabel === option[labelKey];
  return (
    <div
      key={option[valueKey]}
      className={cn('trade-dropdown__row', {'trade-dropdown__row--selected': isSelected}, {'trade-dropdown__row--hovered': isHovered})}
      onKeyPress={hoverOption}
      onMouseDown={() => selectOption(option)}>
      {option[labelKey]}
    </div>
  );
};

simpleRenderer.propTypes = {
  hoverOption: PropTypes.func,
  option: PropTypes.object,
  valueKey: PropTypes.string,
  labelKey: PropTypes.string,
  selectOption: PropTypes.func,
  selectedLabel: PropTypes.string,
  isHovered: PropTypes.bool
};

const LabelRenderer = ({threshHoldRecord}) => {
  const isShowThresholdGroupHeader = threshHoldRecord.items && threshHoldRecord.items.length > 1;
  if (isShowThresholdGroupHeader) {
    return <ThresholdGroupLabelRenderer {...threshHoldRecord} />;
  }
  return <ThresholdLabelRenderer {...threshHoldRecord} />;
};

LabelRenderer.propTypes = {
  threshHoldRecord: PropTypes.object
};

const ThresholdList = ({thresholdList}) => (
    <div className='listWrapper'>
      {
        thresholdList.map(({shareClassName, lowerThreshold, upperThreshold}) => {
          return (
              <div className='listWrapper__itemsWrapper'>
                <span className='shareclass'>{`${shareClassName}`}</span>
                <Conditional condition={lowerThreshold}>
                  <span>{` < ${lowerThreshold}`}</span>
                </Conditional>
                <Conditional condition={lowerThreshold && upperThreshold}>
                  <span>{` ${t('tkand')} `}</span>
                </Conditional>
                <Conditional condition={upperThreshold}>
                  <span>{`> ${upperThreshold}`}</span>
                </Conditional>
              </div>
          );
        })
      }
    </div>
);

ThresholdList.propTypes = {
  thresholdList: PropTypes.array
};

const ThresholdModal = ({title, isModalOpen, isApplyRebates, isRebateExistsForSomeFund, handleClose, handleSetThreshold, handleDeleteThreshold, additionalProps = {}}) => {
  const chartDispatch = useDispatch();

  const {run} = useAsync(chartDispatch);
  const {
    selectedMetric, metricOptions, lowerThreshold, upperThreshold, emailNotificationEnabled = true,
    isExistingThresholdItem, isAllThresholdLimitsEqual, thresholdList, primaryProduct,
    isBenchMarksCompareMode, selectedFundsOrBenchMarks, userGuid, thresholdsDeletedFlag
  } = additionalProps;
  const [lessThanThreshold, setLessThanThreshold] = useState('0');
  const [greaterThanThreshold, setGreaterThanThreshold] = useState('0');
  const [emailBreachNotification, setEmailBreachNotification] = useState(true);
  const [isLowerBoundValid, setIsLowerBoundValid] = useState(true);
  const [isLowerBoundDecimalValid, setIsLowerBoundDecimalValid] = useState(true);
  const [isUpperBoundValid, setIsUpperBoundValid] = useState(true);
  const [isUpperBoundDecimalValid, setIsUpperBoundDecimalValid] = useState(true);
  const [lowerUpperBoundError, setLowerUpperBoundError] = useState({});
  const [isExistingThreshold, setIsExistingThreshold] = useState(false);
  const [isAllThresholdLimitsSame, setIsAllThresholdLimitsSame] = useState(false);
  const [thresholdRecordToDelete, setThresholdRecordToDelete] = useState(null);
  const thresholdModalRef = useRef();

  useEffect(() => {
    setLessThanThreshold(lowerThreshold);
  }, [lowerThreshold]);

  useEffect(() => {
    setGreaterThanThreshold(upperThreshold);
  }, [upperThreshold]);

  useEffect(() => {
    setEmailBreachNotification(emailNotificationEnabled);
  }, [emailNotificationEnabled]);

  useEffect(() => {
    setIsAllThresholdLimitsSame(isAllThresholdLimitsEqual);
  }, [isAllThresholdLimitsEqual]);

  useEffect(() => {
    setIsExistingThreshold(isExistingThresholdItem);
  }, [isExistingThresholdItem]);

  const resetValues = () => {
    setThresholdRecordToDelete(null);
    setIsAllThresholdLimitsSame(isAllThresholdLimitsEqual);
    setLessThanThreshold(lowerThreshold);
    setGreaterThanThreshold(upperThreshold);
    setEmailBreachNotification(emailNotificationEnabled);
  };

  const onCloseModal = (status) => {
    resetValues();
    thresholdModalRef.current.closeModal(() => handleClose && handleClose(status));
  };

  useEffect(() => {
    if (thresholdsDeletedFlag) {
      // Reload the thresholds list
      getFundTrackerThresholds(
        run,
        chartDispatch,
        userGuid
      );
      setThresholdRecordToDelete(null);
      onCloseModal();
    }
  }, [thresholdsDeletedFlag]);

  const isValueEmpty = (val) => (
    (val === undefined) || (val === null) || (val ? (val.toString().trim() === '') : true)
  );

  const isDecimalPlaceValid = (val) => {
    if (val) {
      const decimalPlace = val.toString().split('.')[1];
      return isValueEmpty(decimalPlace) || decimalPlace.length < 5;
    }
    return true;
  };

  const stripEmptyDecimal = (val) => {
    const decimalPlace = val.split('.')[1];
    return (decimalPlace === '') ? val.slice(0, -1) : val;
  };

  const isValueValid = (val) => {
    const valueEmpty = isValueEmpty(val);
    return valueEmpty || (!valueEmpty && isDecimalPlaceValid(val));
  };

  const getMetricLabel = (metricEnum) => {
    let metricLabel = '';
    if (metricEnum) {
      metricOptions.forEach(option => {
        const metricItem = option.items.find(item => (item.enum === metricEnum));
        if (metricItem) {
          metricLabel = metricItem.label;
        }
      });
    }
    return metricLabel;
  };

  const onDeleteThreshold = () => {
    const shareClassIds = getSelectedShareClassIds(
      primaryProduct, isBenchMarksCompareMode, selectedFundsOrBenchMarks
    );
    const payload = {
      metric: selectedMetric,
      shareClassIds,
      userGuid
    };
    handleDeleteThreshold(payload);
  };

  const getMetricLabelAndValueType = (metricOptions, metricEnum) => {
    let metricLabelAndType = [];
    if (metricEnum) {
      metricOptions.forEach(option => {
        const metricItem = option.items.find(item => (item.enum === metricEnum));
        if (metricItem) {
          metricLabelAndType = [metricItem.label, metricItem.type];
        }
      });
    }
    return metricLabelAndType;
  };

  const handleSetThresholdBtnClick = () => {
    // This will be the case when user click on 'CONTINUE' button, when they choose to override the existing thresholds
    if (!isAllThresholdLimitsSame) {
      setIsAllThresholdLimitsSame(true);
    } else {
      let allValid = false;
      const bothThresholdsEmpty = isValueEmpty(lessThanThreshold) && isValueEmpty(greaterThanThreshold);
      const bothThresholdsValid = isValueValid(lessThanThreshold) && isValueValid(greaterThanThreshold);
      if (!bothThresholdsEmpty && bothThresholdsValid) {
        // Make the field values true
        setIsLowerBoundValid(true);
        setIsLowerBoundDecimalValid(true);
        setIsUpperBoundValid(true);
        setIsUpperBoundDecimalValid(true);

        // Check if threshold ranges are correct
        if (parseFloat(lessThanThreshold) > parseFloat(greaterThanThreshold)) {
          allValid = false;
          const snackBarProps = {
            labels,
            sticky: false,
            type: 'error',
            msgCopy: t('tkLowerUpperBoundError'),
            autoHide: false,
            id: 'idSnackBar'
          };
          setLowerUpperBoundError(snackBarProps);
        } else {
          allValid = true;
          setLowerUpperBoundError({});
        }
      } else if (bothThresholdsEmpty) {
        allValid = false;
      }

      if (allValid) {
        // Save the threshold values
        handleSetThreshold([
          selectedMetric,
          (lessThanThreshold || undefined),
          (greaterThanThreshold || undefined),
          emailBreachNotification
        ]);
        resetValues();
      } else {
        if (bothThresholdsEmpty) {
          // If it's the existing threshold, check if we are removing thresholds for single fund OR multiple funds
          if (isExistingThreshold) {
            const {
              shareClassName, metric, lowerThreshold, upperThreshold
            } = thresholdList.find(({shareClassId}) => shareClassId === primaryProduct);
            const [metricLabel, metricValueType = ''] = getMetricLabelAndValueType(metricOptions, metric);
            const thresholdRecord = {
              metricLabel,
              metricValueType,
              isShowTooltipForShareClassName: false,
              lowerThreshold,
              upperThreshold,
              showThresholdDetails: false
            };
            if (!isBenchMarksCompareMode) {
              setThresholdRecordToDelete({
                ...thresholdRecord,
                shareClassName,
                isShowTooltipForShareClassName: false,
                showThresholdDetails: true
              });
            } else {
              const items = thresholdList.map(({shareClassName}) => ({shareClassName}));
              setThresholdRecordToDelete({
                ...thresholdRecord,
                isShowTooltipForShareClassName: true,
                showThresholdDetails: true,
                items
              });
            }
          } else {
            // If it's not the existing threshold, just close the modal
            onCloseModal();
          }
        }
      }
    }
  };

  const onChangeLessThanThreshold = (val) => {
    const decimalPlacedValid = isDecimalPlaceValid(val);
    setIsLowerBoundValid(decimalPlacedValid);
    setIsLowerBoundDecimalValid(decimalPlacedValid);
    setLessThanThreshold(val);
  };

  const onChangeGreaterThanThreshold = (val) => {
    const decimalPlacedValid = isDecimalPlaceValid(val);
    setIsUpperBoundValid(decimalPlacedValid);
    setIsUpperBoundDecimalValid(decimalPlacedValid);
    setGreaterThanThreshold(val);
  };

  const onBlurLessThanThreshold = (val) => {
    if (isValueValid(val)) {
      setIsLowerBoundValid(true);
      setIsUpperBoundValid(true);
      const finalVal = stripEmptyDecimal(val);
      setLessThanThreshold(finalVal);
    }
  };

  const onBlurGreaterThanThreshold = (val) => {
    if (isValueValid(val)) {
      setIsUpperBoundValid(true);
      setIsLowerBoundValid(true);
      const finalVal = stripEmptyDecimal(val);
      setGreaterThanThreshold(finalVal);
    }
  };

  const onEmailBreachNotificationChange = () => {
    setEmailBreachNotification(!emailBreachNotification);
  };

  const onClickViewThresholds = () => {
    history.push(getAppRoute(AppConstants.PREFERENCES_FUND_TRACKER_THRESHOLDS_ROUTE));
  };

  const metricLabel = isAllThresholdLimitsSame ? getMetricLabel(selectedMetric) : '';
  const modalTitle = isAllThresholdLimitsSame ? title : t('tkOverrideExistingThresholds');

  const scrollConfig = {
    cssTrackY: 'modal-scroll__track modal-scroll__track--Y',
    cssContent: 'modal-scroll__content',
    cssWrapper: 'modal-scroll__wrapper',
    overflowX: 'hidden',
    overflowY: 'scroll',
    style: {
      position: 'static',
      width: '100%',
      height: '100%'
    }
  };

  return (
    <Modal
      ref={thresholdModalRef}
      setThresholdModalStyle={setThresholdModalStyle}
      suppressScrollOnActive={true}
      customClass='modal--center set-threshold-modal set-threshold-modal__index'
      backdropCustomClass='set-threshold-modal__backdrop-index'
      open={isModalOpen}
      handleClose={onCloseModal}
      animation={false}>
      <div className='set-threshold-modal'>
        <Conditional condition={thresholdRecordToDelete}>
          <div className='set-threshold-modal__heading'>{t('tkDeleteThreshold')}</div>
          <>
            <Conditional condition={metricLabel}>
              <div className='set-threshold-modal__metric'>{metricLabel}</div>
            </Conditional>
            <div className='set-threshold-modal__heading'>{modalTitle}</div>
          </>
        </Conditional>
        <Conditional condition={!isAllThresholdLimitsSame}>
          <>
            <div className='set-threshold-modal__message'>
              <p dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(t('tkOverrideThresholdDescription1', getMetricLabel(selectedMetric)))}}/>
            </div>
            <div className='set-threshold-modal__threshold-list'>
              {
                withScroll(ThresholdList, scrollConfig)({thresholdList})
              }
              <span className='set-threshold-modal__confirm-msg'>
                {t('tkDoYouWishToOverrideThresholds')}
              </span>
            </div>
          </>
          <>
            <Conditional condition={thresholdRecordToDelete}>
              <>
                <div className='set-threshold-modal__deleteMessage'>
                  {t('tkDeleteThresholdAlertMsg')}
                </div>
                <div className='set-threshold-modal__deleteMessage'>
                  <ul>
                    <li>
                      <LabelRenderer
                        threshHoldRecord={thresholdRecordToDelete}
                      />
                    </li>
                  </ul>
                </div>
              </>
              <>
                <TransitionGroup component={null}>
                  <CSSTransition
                    in={true}
                    appear={true}
                    classNames='snackbar__transition'
                    timeout={AppConstants.CSS_DURATION_SLOTH}>
                    <Snackbar {...lowerUpperBoundError} />
                  </CSSTransition>
                </TransitionGroup>
                <div className='set-threshold-modal__message'>
                  {t('tkShowThresholdBreachMessage')}
                </div>
                <div className='set-threshold-modal__thresholds'>
                  <InputField
                    inputLabel={`${metricLabel} ${t('tkLessThan')}`}
                    value={lessThanThreshold}
                    isFieldValid={isLowerBoundValid}
                    isFieldDecimalPlaceValid={isLowerBoundDecimalValid}
                    placeholder={t('tkEnterLowerBound')}
                    onBlur={onBlurLessThanThreshold}
                    onChange={onChangeLessThanThreshold}
                  />
                  <InputField
                    inputLabel={`${metricLabel} ${t('tkGreaterThan')}`}
                    value={greaterThanThreshold}
                    isFieldValid={isUpperBoundValid}
                    isFieldDecimalPlaceValid={isUpperBoundDecimalValid}
                    placeholder={t('tkEnterUpperBound')}
                    onBlur={onBlurGreaterThanThreshold}
                    onChange={onChangeGreaterThanThreshold}
                  />
                </div>
                {
                  isApplyRebates && isRebateExistsForSomeFund &&
                    <div className='set-threshold-modal__notify-rebates'>
                      {t('tkRebateSetThresholdNote')}
                    </div>
                }
                <div className='set-threshold-modal__notify-breach'>
                  <Checkbox
                    id='set-threshold-modal__notify-breach-checkbox'
                    value={emailBreachNotification}
                    label={t('tkEmailBreachNotification')}
                    isChecked={emailBreachNotification}
                    changeHandler={onEmailBreachNotificationChange}
                  />
                </div>
              </>
            </Conditional>
          </>
        </Conditional>
        <div className='set-threshold-modal__bottomRow'>
          <div className='link-container'>
            <Conditional condition={!thresholdRecordToDelete}>
              <Link
                classType='link-primary'
                handleClick={onClickViewThresholds}
                label={t('tkViewAllThresholds')}
              />
            </Conditional>
          </div>
          <div className='button-container'>
            <Button
              label={t('tkCancel')}
              customClass='button-secondary-small'
              clickHandler={(event) => onCloseModal(event)}
            />
            <Conditional condition={thresholdRecordToDelete}>
              <Button
                label={t('tkDelete')}
                customClass='button-primary-small'
                clickHandler={() => onDeleteThreshold()}
              />
              <Button
                label={isAllThresholdLimitsSame ? t('tkSave') : t('tkContinue')}
                customClass='button-primary-small'
                clickHandler={(event) => handleSetThresholdBtnClick(event)}
              />
            </Conditional>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  isRebateExistsForSomeFund: isRebateExistsForSomeFundSelector(state),
});

ThresholdModal.propTypes = {
  metricOptions: PropTypes.array,
  additionalProps: PropTypes.object,
  title: PropTypes.string,
  isModalOpen: PropTypes.bool,
  isApplyRebates: PropTypes.bool,
  handleClose: PropTypes.func,
  handleSetThreshold: PropTypes.func,
  handleDeleteThreshold: PropTypes.func,
  isRebateExistsForSomeFund: PropTypes.bool
};

export default connect(mapStateToProps)(ThresholdModal);
