import moment from 'moment';
import { DATE_TIME_FORMATTERS } from '../../constants/appConstants';

/**
 * fn will replace values matching the regex pattern with the replacement string
 * it also ensures that there are no consecutive spaces in the formatted string
 */
export const applyPatternFormatter = (str, pattern = /[^a-zA-Z0-9]/g, replacement = ' ') => {
  if (!str || typeof str !== 'string' || typeof replacement !== 'string') {
    return '';
  }
  const formattedValue = str.replace(pattern, replacement);
  return formattedValue.replace(/\s+/g, ' ').trim();
};

/**
 * fn will append the formatted date to the filename.
 * if date is not provided, it will default to today's date.
 *
 * @param {string} options.format - the format to apply to the date
 * @param {boolean} options.toUpperCase - whether to convert the filename to uppercase
 * @param {string} options.date - the date to be formatted
 * @param {string} options.extension - the file extension to be appended
 */
export const formatFilenameWithDate = (
  fileName,
  options = {
    format: DATE_TIME_FORMATTERS.DDMMMYYYY,
    toUpperCase: true,
    date: null,
    extension: null
  }
) => {
  // if fileName not provided or empty
  if (!fileName || typeof fileName !== 'string' || fileName.length < 1) return fileName;

  const { format, toUpperCase, date, extension } = options;
  // if date not provided, set date to today's and apply format
  const formattedDate = date ? moment(date).format(format) : moment().format(format);
  const output = `${fileName.trim()}_${formattedDate}`;

  return `${!toUpperCase ? output : output.toUpperCase()}${extension ? `.${extension}` : ''}`;
};
