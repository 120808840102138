import React, { useRef, useState, useMemo } from "react"
import { Modal, ModalHeader, ModalBody, ModalFooter } from '@gs-ux-uitoolkit-react/modal';
import { Button } from '@gs-ux-uitoolkit-react/button';
import PropTypes from 'prop-types';
import AutoComplete from '../../../../../../components/core/AutoComplete';
import useSearchUserOrgFirm from '../../../hooks/useSearchUserOrgFirm';
import useGetUserDetailsById from '../../../hooks/useGetUserDetailsById';
import { ADD_UPDATE_USER_DETAILS, TAB_SWITCHED, UPDATE_ERROR, UPDATE_CREATE_USER_ORG_ID, UPDATE_ACCOUNT_ENTITLEMENTS, DELETE_ALL_ACCOUNT_ENTITLEMENTS_AND_PENDING_EDITS_FOR_CREATE, UPDATE_ACCOUNT_DETAILS } from '../../../actions';
import { getSortedStringData } from '../../../../../../utils/sorting';
import { getAccountPayloadFromAccountTree } from "../../../utils/accountsTree"
import useSnackBar from '../../../hooks/useSnackbar';
import useUserMaintenance from '../../../context';
import { TABS } from '../TabSwitchBar';
import translator from '../../../../../../services/translator';
import './index.scss';
import { ThreeSquaresLoader } from "../../../../../../components/core/Loaders";

const { translate: t } = translator;

const CloneUserModal = ({ closeModal }) => {

  const {
    state: { userDetails },
    dispatch,
  } = useUserMaintenance();

  const ref = useRef();
  const { openSnackbar } = useSnackBar();
  const { data: autoComplete = {}, loading, searchUserOrgFirm, error } = useSearchUserOrgFirm();
  const { getUserDetailsById, loading: showLoader } = useGetUserDetailsById()

  const [selectedUser, setSelectedUser] = useState(null);

  const { users: { values = [] } = {} } = autoComplete || {};

  const updatedOptions = useMemo(() => {
    if (!autoComplete) return [];
    const result = [];
    if (values.length) {
      const allMatches = values.map(item => ({
        ...item,
        label: `${item.firstName} ${item.lastName}, ${item.contactDetails.email}`,
        labels: [
          {
            name: `${item.firstName} ${item.lastName}`,
            isScGreyText: false,
          },
          {
            name: item.contactDetails.email,
            isScGreyText: true,
          },
        ],
      }));
      result.push({ title: null, values: getSortedStringData(allMatches, 'label', true) });
    }
    return result;
  }, [autoComplete]);

  const onInputChange = data => {
    if (data.length >= 3) {
      searchUserOrgFirm({ searchString: data, searchGroups: ['user'], searchMaxResults: 7 });
    }
  };

  const onOptionSelection = data => {
    setSelectedUser(data);
    ref.current && ref.current.setSearchTerm('');
  };

  const handleSubmit = async () => {
    try {
      const details = await getUserDetailsById(selectedUser.id);
      const { data: {
        timezone,
        accessTypes,
        profile,
        permissions,
        entitlements,
        organizationId,
        organizationName,
        jurisdiction,
        accountDetails,
        funds,
      } } = details;
      const clonedUserDetails = {
        ...userDetails,
        accountDetails,
        funds,
        organizationId,
        organizationName,
        accessTypes,
        timezone,
        jurisdiction,
        profile: {
          ...userDetails?.profile,
          name: profile?.name,
        },
        permissions: {
          ...userDetails.permissions,
          nscc: permissions.nscc,
          useCutoffClientBuffer: permissions.useCutoffClientBuffer,
          allowShellAccounts: permissions.allowShellAccounts,
          allowCloseouts: permissions.allowCloseouts,
        },
        entitlements: {
          ...userDetails.entitlements,
          featureEntitlements: {
            ...userDetails.entitlements.featureEntitlements,
            ...entitlements.featureEntitlements,
          }
        },
      }
      dispatch({ type: UPDATE_CREATE_USER_ORG_ID, payload: { organizationId } });
      dispatch({ type: TAB_SWITCHED, payload: TABS.PROFILE });
      dispatch({ type: ADD_UPDATE_USER_DETAILS, payload: { userDetails: clonedUserDetails } });
      dispatch({ type: UPDATE_ERROR, payload: {} });
      dispatch({ type: DELETE_ALL_ACCOUNT_ENTITLEMENTS_AND_PENDING_EDITS_FOR_CREATE });
      if (accountDetails?.length) {
        dispatch({ type: UPDATE_ACCOUNT_ENTITLEMENTS, payload: { accountEntitlementChanges: getAccountPayloadFromAccountTree(accountDetails), operation: 'ADD' } });
        dispatch({ type: UPDATE_ACCOUNT_DETAILS, payload: { accountDetails } });
      }
      openSnackbar({ type: 'success', message: t('tkSuccessfullyCloned', selectedUser.firstName, selectedUser.lastName) });
      closeModal();
    } catch (error) {
      openSnackbar({ type: 'error', message: t('tkUserAutoCompleteErrorMsg') });
    }
  };

  return (
    <Modal visible={true} placement='center' className='cloneUser gs-uitk-override-css'>
      {showLoader && <ThreeSquaresLoader />}
      <ModalHeader onDismissButtonClick={showLoader ? null : closeModal} className='cloneUser__header'>
        {t('tkCloneAnExistingUser')}
      </ModalHeader>
      <ModalBody className='cloneUser__body'>
        <div>{t('tkSearchForAUser')}</div>
        <AutoComplete
          ref={ref}
          placeholderText={t('tkEnterNameEmail')}
          searchIconPosition='left'
          onInputChange={onInputChange}
          debounceTime={150}
          minChar={3}
          isLoading={loading}
          options={updatedOptions}
          onOptionSelection={onOptionSelection}
          defaultSearchTerm={''}
        />
        {selectedUser && (
          <section>
            <p className='cloneUser__msg'>{t('tkCloneAnExistingUser')}</p>
            <article className='cloneUser__importMsg'>
              <div>
                <span className='cloneUser__userIcon' />
              </div>
              <div className='cloneUser__userInfo'>
                <div className='cloneUser__userName'>
                  <span >{`${selectedUser.lastName}, ${selectedUser.firstName}`}</span>
                  <span className='cloneUser__email'>{` (${selectedUser.contactDetails.email})`}</span>
                </div>
                <div className='cloneUser__detail'>
                  <div>{`${t('tkLogin')}: ${selectedUser.login}`}</div>
                
                  <div>{`${t('tkJurisdictionCode')}: ${selectedUser.jurisdiction || t('tkNullDataPlaceholder')}`}</div>
                </div>
              </div>
            </article>
          </section>
        )}
        {error && <div className='cloneUser__errorMsg'>{t('tkUserAutoCompleteErrorMsg')}</div>}
      </ModalBody>
      <ModalFooter className='cloneUser__footer'>
        <Button actionType='secondary' onClick={closeModal}>
          {t('tkModalCancelCTA')}
        </Button>
        {
          selectedUser && (
            <Button actionType='primary' onClick={handleSubmit} disabled={showLoader}>
              {t('tkOk')}
            </Button>
          )
        }
      </ModalFooter>
    </Modal >
  );
};

CloneUserModal.propTypes = {
  closeModal: PropTypes.func,
};

export default CloneUserModal;
