import React, { Component } from 'react';
import {Route, Switch, Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import AppConstants from '../constants/appConstants';
import LoginConstants from '../constants/loginConstants';
import Error from '../containers/Error';
import {updatePageId} from '../actions/page';
import {getBaseRoutePath} from './utils';
import {isTradingAllowedSelector, isInvestmentPolicyAllowedSelector} from '../selectors/user';
import {isSessionExpiredFlagSelector} from '../selectors/app';
import {getAllPermitRoles} from '../selectors/pages/reports';

export class AppRoutes extends Component {
  constructor(props) {
    super(props);
    this.handleBrowserForwardBack = this.handleBrowserForwardBack.bind(this);
  }

  componentWillMount() {
    // Avoid updating pageId for the routes that redirects to the first child route (as it will be updated by child route)
    if (!this.props.skipUpdatePageId) {
      this.props.dispatch(updatePageId(this.props.id));
    }
    window.onpopstate = this.handleBrowserForwardBack;
  }

  handleBrowserForwardBack() {
    if (LoginConstants.LOGIN_PAGES.includes(this.props.id)) {
      this.props.dispatch(updatePageId(this.props.id, true));
    }
  }

  render() {
    const {
      id,
      permissions = [],
      path,
      computedMatch,
      component: Component,
      resourceName,
      restProps,
      isSessionExpired,
      isTradingAllowed,
      isInvestmentPolicyAllowed,
      canShowEmailPreferenceNavItem,
      subRoutes = []
    } = this.props;

    const hasFoundPermittedSubRoute = subRoutes.some(route => permissions.includes(route.resourceName));
    let allowedPage;
    if (id === AppConstants.PREFERENCE_EMAIL_NOTIFICATIONS) {
      allowedPage = canShowEmailPreferenceNavItem;
    } else if (resourceName === AppConstants.TRADING_FEATURE_ENTITLEMENT) {
      allowedPage = isTradingAllowed;
    } else if (resourceName === AppConstants.INVESTMENT_POLICY) {
      allowedPage = isInvestmentPolicyAllowed;
    } else {
      allowedPage = (permissions.find(p => p === resourceName) || (!resourceName && !hasFoundPermittedSubRoute) || hasFoundPermittedSubRoute);
    }

    return (
      <Route
        path={path}
        render={(props) => {
          return path === AppConstants.ERROR_ROUTE || allowedPage
            ? (
              <Component
                {...props}
                {...restProps}
                subRoutes={subRoutes}
                match={computedMatch}
              />
            )
            : (!isSessionExpired && <Error code="403" />);
        }}
      />
    );
  }
}

AppRoutes.propTypes = {
  id: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  permissions: PropTypes.any,
  path: PropTypes.string,
  computedMatch: PropTypes.object,
  component: PropTypes.func,
  resourceName: PropTypes.string,
  restProps: PropTypes.object,
  isSessionExpired: PropTypes.bool,
  subRoutes: PropTypes.array,
  skipUpdatePageId: PropTypes.bool,
  isTradingAllowed: PropTypes.bool,
  isInvestmentPolicyAllowed: PropTypes.bool,
  canShowEmailPreferenceNavItem: PropTypes.bool
};

export const InjectRoutes = ({
   routes, navLinks = [], permissions, isSessionExpired,
   dispatch, redirectRoutes = [], isTradingAllowed,
   canShowEmailPreferenceNavItem, isInvestmentPolicyAllowed,
   isLogin
}) => {
  const BASE_PATH = getBaseRoutePath(navLinks, routes, permissions, isLogin);
  return (
    <Switch>
      {
        redirectRoutes.map(route => (
          <Redirect exact key={route} from={route} to={BASE_PATH || route} />
        ))
      }
      {
        routes.filter(route => route.path).map(route => (
          <AppRoutes
            key={route.path}
            {...route}
            permissions={permissions}
            isTradingAllowed={isTradingAllowed}
            isInvestmentPolicyAllowed={isInvestmentPolicyAllowed}
            canShowEmailPreferenceNavItem={canShowEmailPreferenceNavItem}
            isSessionExpired={isSessionExpired}
            dispatch={dispatch}
          />
        ))
      }
    </Switch>
  );
};

InjectRoutes.propTypes = {
  routes: PropTypes.array,
  permissions: PropTypes.any,
  isSessionExpired: PropTypes.bool,
  navLinks: PropTypes.array,
  dispatch: PropTypes.func,
  redirectRoutes: PropTypes.array,
  isTradingAllowed: PropTypes.bool,
  isInvestmentPolicyAllowed: PropTypes.bool,
  canShowEmailPreferenceNavItem: PropTypes.bool,
  isLogin: PropTypes.bool
};

const mapStateToProps = state => ({
  permissions: getAllPermitRoles(state),
  isSessionExpired: isSessionExpiredFlagSelector(state),
  isTradingAllowed: isTradingAllowedSelector(state),
  isInvestmentPolicyAllowed: isInvestmentPolicyAllowedSelector(state)
});

export default connect(mapStateToProps)(InjectRoutes);
