import React, { useEffect, useState, memo } from 'react';
import cn from 'classnames';
import { Icon } from '@gs-ux-uitoolkit-react/icon-font';
import PropTypes from 'prop-types';
import translator from '../../../../../../../services/translator';
import { INVESTMENT_POLICY_EDIT } from '../../../../constants';

const { translate: t } = translator;
const InvestmentPolicyHeader = ({ permissions, isSingleOrg }) => {
  const [isEditor, setEditor] = useState(false);

  useEffect(() => {
    if (permissions?.includes(INVESTMENT_POLICY_EDIT)) {
      setEditor(true);
    }
  }, [permissions]);

  return (
    <div className={cn('dbcard__investment-policy-header')}>
      <div className='dbcard__investment-policy-header-grouping'>
        <h4 className="dbcard__header">{t('tkInvestmentPolicies')}</h4>
        {!isSingleOrg && (
          <h4 className="dbcard__investment-policy-disabled-dropdown">
            {t('tkRecentlyEdited')}
            <Icon className='dbcard__investment-policy-disabled-dropdown-menu' name='keyboard-arrow-down' type='filed' />
          </h4>
        )}
      </div>
      {isEditor && (
        <div className='dbcard__investment-policy-header-policy_button'>
          <Icon name="add-circle-outline" type="outlined" className='dbcard__add-circle-icon'/>
          <a href={'#'} className='dbcard__add-circle-icon'> {t('tkInvestmentPoliciesAddEdit')}</a>
        </div>
      )}
    </div>
  );
};

InvestmentPolicyHeader.propTypes = {
  permissions: PropTypes.array,
  isSingleOrg: PropTypes.bool
};

export default memo(InvestmentPolicyHeader);
