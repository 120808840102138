import moment from 'moment/moment';
import {REBATE_CONSTANTS} from '../../../../constants/pageConstants';
import {DATE_TIME_FORMATTERS} from '../../../../constants/appConstants';

const {
  REBATE_EXCEEDS_PRECISION, REBATE_NO_ERROR,
  REBATE_NOT_A_NUMBER, REBATE_OUT_OF_RANGE,
  REBATE_OUT_OF_RANGE_AND_EXCEEDS_PRECISION,
  regexForRebateAmount
} = REBATE_CONSTANTS;

export const formatFieldContent = (field, value, labels) => {
  if (!value) {
    return labels.tkNullDataPlaceholder;
  } else if (field === 'paymentDate') {
    return labels.tkPaymentDatePrefix + value + labels.tkPaymentDateSuffix;
  } else if (field === 'startDate') {
    return moment(value, DATE_TIME_FORMATTERS.YYYY_MM_DD).format('DD.MMM.YYYY')
  }
  return value;
}

export const countDecimals = (number) => {
  if (number.includes('.'))
    return number.toString().split('.')[1].length || 0;
  return 0;
};

export const validateRebateAmount = (rebateAmount, rebateExists=false) => {
  let error = REBATE_NO_ERROR;
  if(rebateAmount || ( rebateAmount === '' && !rebateExists)) {
    if (!rebateAmount.match(regexForRebateAmount)) {
      error = REBATE_NOT_A_NUMBER;
    } else if ( rebateAmount < 0 || rebateAmount > 100 ) {
      error = REBATE_OUT_OF_RANGE;
    }
    if (error === 2 && countDecimals(rebateAmount) > 2) {
      error = REBATE_OUT_OF_RANGE_AND_EXCEEDS_PRECISION;
    } else if (countDecimals(rebateAmount) > 2) {
      error = REBATE_EXCEEDS_PRECISION;
    }
  }
  return error;
};
