import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';
import cloneDeep from 'lodash/cloneDeep';
import {Conditional} from '../../../../components/core/Conditional';
import PageHeader from '../../../../components/app/PageHeader';
import Button from '../../../../components/core/Button';
import ThresholdTreeComponent from './TreeComponent/Tree';
import DeleteThresholdModal from '../../../../modules/FundTracker/components/DeleteThresholdModal';
import translator from '../../../../services/translator';
import {analyticsEventName, onPreferencesPageLoadAnalytics} from '../../analytics';
import {pageIdSelector, preferencesSavedSelector, preferencesSuccessSelector} from '../../../../selectors/pages';
import {eventAnalytics} from '../../../../actions/sagas';
import {preferenceNavLinksSelector, preferenceRouteConfigSelector} from '../../../../selectors/global';
import {fundTrackerThresholdsSelector, metricsOptionsSelector} from '../../../../selectors/pages/fundTracker';
import {userGuidSelector} from '../../../../selectors/user';
import {saveFundTrackerThresholds, deleteFundTrackerThresholds, fetchFundTrackerThresholds} from '../../../../actions/page/fundTracker';
import {addAppContext} from '../../../../actions/app';
import history from '../../../../utils/history';
import {getAppRoute} from '../../../../utils/commonUtils';
import AppConstants from '../../../../constants/appConstants';
import './index.scss';

const {translate: t} = translator;

export const FundTrackerThresholds = (props) => {
  const {
    userGuid, saveFundTrackerThresholds, isPreferencesSaved, isPreferencesSuccess,
    fundTrackerThresholds = [], metricOptions, setFundTrackerInitShareClassData
  } = props;
  const [isDirty, setIsDirty] = useState(false);
  const [thresholdList, setThresholdList] = useState([]);
  const [showDeleteThresholdConfirmModal, setShowDeleteThresholdConfirmModal] = useState(false);
  const [threshHoldRecordSelected, setThreshHoldRecordSelected] = useState({});
  const [thresholdItemsToSave, setThresholdItemsToSave] = useState([]);

  useEffect(() => {
    props.onPageLoad();
  }, []);

  useEffect(() => {
    setThresholdList(orderBy(fundTrackerThresholds, ['metricLabel', 'asc']));
  }, [fundTrackerThresholds]);

  const onSaveFundTrackerThresholds = () => {
    const saveThresholdItemsPayload = {
      userGuid,
      thresholds: thresholdItemsToSave
        .map(({metric, shareClassId, lowerThreshold, upperThreshold, emailNotificationEnabled}) => {
          return {
            metric,
            shareClassId,
            lowerThreshold,
            upperThreshold,
            emailNotificationEnabled
          };
        })
    };
    setIsDirty(false);
    saveFundTrackerThresholds(saveThresholdItemsPayload);
  };

  const setModifiedThreshold = (val, isChecked, idProp, parentId) => {
    // Check the selected child checkbox
    let modifiedThresholds = thresholdList.map(item => {
      if (item[idProp] === val || (!parentId && item.nodeId === val)) {
        return {
          ...item,
          emailNotificationEnabled: isChecked,
          isIntermediateChecked: false
        };
      }
      return item;
    });
    // To check the parent checkbox, check if all underlying children are selected
    if (parentId) {
      const isAllChildItemsChecked = modifiedThresholds
        .filter(item => item.parentId === parentId)
        .every(item => item.emailNotificationEnabled);
      const isAllChildItemsUnchecked = modifiedThresholds
        .filter(item => item.parentId === parentId)
        .every(item => !item.emailNotificationEnabled);
      modifiedThresholds = modifiedThresholds.map(item => {
        if (item.nodeId === parentId) {
          return {
            ...item,
            isIntermediateChecked: !(isAllChildItemsChecked || isAllChildItemsUnchecked),
            emailNotificationEnabled: isAllChildItemsChecked
          };
        }
        return item;
      });
    }
    setThresholdList(modifiedThresholds);
  };

  const addOrRemoveItemsToSaveList = (val, isChecked) => {
    const clonedThresholdItemsToSave = cloneDeep(thresholdItemsToSave);
    // Add or change items
    thresholdList
      .filter(item => {
        const parentWithNoChildren = (item.nodeId === val && !item.items.length);
        const childItemForParent = (item.parentId === val);
        return parentWithNoChildren || childItemForParent;
      })
      .map(({
        metric,
        shareClassId,
        userGuid,
        lowerThreshold,
        upperThreshold
      }) => ({
        metric,
        shareClassId,
        userGuid,
        upperThreshold,
        lowerThreshold,
        emailNotificationEnabled: isChecked
      }))
      .forEach(item => {
        const index = thresholdItemsToSave.findIndex(({metric, shareClassId, userGuid}) => {
          return (metric === item.metric && shareClassId === item.shareClassId && userGuid === item.userGuid);
        });
        // If item found update it, else add it to the list
        if (index !== -1) {
          clonedThresholdItemsToSave[index].emailNotificationEnabled = isChecked;
        } else {
          clonedThresholdItemsToSave.push(item);
        }
      });
    const anyChangeToItems = clonedThresholdItemsToSave.some(({
      metric, shareClassId, userGuid, emailNotificationEnabled
    }) => (
      fundTrackerThresholds.some(i => (
        metric === i.metric &&
        shareClassId === i.shareClassId &&
        userGuid === i.userGuid &&
        i.emailNotificationEnabled !== emailNotificationEnabled
      ))
    ));
    setThresholdItemsToSave(anyChangeToItems ? clonedThresholdItemsToSave : []);
    setIsDirty(anyChangeToItems);
  };

  const onToggleSelectThresholdItem = (val, isChecked, parentId) => {
    const idProp = !parentId ? 'parentId' : 'nodeId';
    setModifiedThreshold(val, isChecked, idProp, parentId);
    // Add or Remove items to Save List
    addOrRemoveItemsToSaveList(val, isChecked, idProp, parentId);
  };

  const onExpandCollapseThresholdGroup = (val) => {
    const modifiedThresholds = thresholdList.map(item => {
      const {nodeId, open} = item;
      return (nodeId === val) ? {...item, open: !open} : item;
    });
    setThresholdList(modifiedThresholds);
  };

  const onViewThreshold = (thresholdItem) => {
    const {items, metric, shareClassId} = thresholdItem;
    const primaryProduct = (items.length > 1) ? items[0].shareClassId : shareClassId;
    const primaryMetricEnum = ((items.length > 1) ? items[0].metric : metric).replace(/ADJUSTED_/, '');
    const {routingPathId} = metricOptions.find(({items}) => (
      items.some(({enum: metricEnum}) => (metricEnum === primaryMetricEnum))
    )) || {};
    const fundTrackerRouteToNavigateTo = AppConstants[routingPathId];
    const additionalShareClassIds = items
      .filter(({shareClassId}) => shareClassId !== primaryProduct)
      .map(({shareClassId}) => shareClassId);
    // Set the Fund Tracker Initial Share Class and metric details
    setFundTrackerInitShareClassData({
      fundTrackerInitialShareClassData: {
        primaryShareClassDetails: {
          shareClassId: primaryProduct,
          metric: primaryMetricEnum
        },
        additionalShareClassIds
      }
    });

    // Direct the user to Fund Tracker Wizard screen
    setTimeout(() => {
      history.push(getAppRoute(fundTrackerRouteToNavigateTo));
    }, 100);
  };

  const showConfirmDeleteThresholdModal = (item) => {
    setThreshHoldRecordSelected(item);
    setShowDeleteThresholdConfirmModal(true);
  };

  const handleDeleteThreshold = () => {
    const {items, shareClassId, metric, userGuid} = threshHoldRecordSelected;
    const shareClassIds = (items.length > 0) ? items.map(item => item.shareClassId) : [shareClassId];
    const payload = {
      loadUpdatedThresholds: true,
      metric,
      shareClassIds,
      userGuid
    };
    props.deleteThreshold(payload);

    // Hide the modal
    setShowDeleteThresholdConfirmModal(false);
  };

  const deleteThresholdModalProps = {
    animation: false,
    suppressScrollOnActive: true,
    customModalStyle: {overflow: 'hidden'},
    isModalOpen: showDeleteThresholdConfirmModal,
    threshHoldRecordSelected,
    handleDeleteThreshold,
    handleClose: () => {
      setShowDeleteThresholdConfirmModal(false);
    }
  };

  const thresholdListProps = {
    items: thresholdList,
    indentationPadding: 46,
    paddingWithChildren: 20,
    props: {
      metricOptions,
      onViewThreshold,
      onDeleteThreshold: showConfirmDeleteThresholdModal,
      onToggleSelectThresholdItem,
      onExpandCollapseThresholdGroup
    }
  };

  return (
    <div className='thresholds'>
      <div className='thresholds__header'>
        <PageHeader
          title={t('tkFundTrackerThresholds')}
          description={t('tkFundTrackerThresholdsSubTitle')}
          type='Secondary'
        />
      </div>
      <div className='thresholds__container'>
        <Conditional condition={isEmpty(thresholdList)}>
          <span className="thresholds__nodata">
            {t('tkNoFundTrackerThresholdsMsg')}
          </span>
          <div>
            <div className='thresholds__sub-header'>
              {t('tkEmailMeNotificationsWhen')}
            </div>
            <div className='thresholds__items'>
              <ThresholdTreeComponent
                {...thresholdListProps}
              />
            </div>
          </div>
        </Conditional>
      </div>
      <div className='thresholds__footer'>
        <Conditional condition={!isEmpty(thresholdList)}>
          <Button
            extraProps={{'analytics-attribute': analyticsEventName.SAVE_PREFERENCES}}
            customClass='button-primary'
            label={t('tkSave')}
            clickHandler={onSaveFundTrackerThresholds}
            isDisabled={!isDirty}
          />
        </Conditional>
        <Conditional condition={isPreferencesSaved && !isDirty}>
          <Conditional condition={isPreferencesSuccess}>
            <div className='success-message'>{t('tkPreferencesSaveSuccess')}</div>
            <div className='success-message'>{t('tkPreferencesSaveError')}</div>
          </Conditional>
        </Conditional>
      </div>
      <DeleteThresholdModal {...deleteThresholdModalProps} />
    </div>
  );
};

FundTrackerThresholds.propTypes = {
  userGuid: PropTypes.string,
  metricOptions: PropTypes.array,
  saveFundTrackerThresholds: PropTypes.func,
  isPreferencesSaved: PropTypes.bool,
  isPreferencesSuccess: PropTypes.bool,
  onPageLoad: PropTypes.func,
  fundTrackerThresholds: PropTypes.array,
  deleteThreshold: PropTypes.func,
  setFundTrackerInitShareClassData: PropTypes.func
};

const mapStateToProps = (state) => (
  {
    userGuid: userGuidSelector(state),
    fundTrackerThresholds: fundTrackerThresholdsSelector(state),
    preferencesStepperConfig: preferenceRouteConfigSelector(state),
    isPreferencesSaved: preferencesSavedSelector(state),
    isPreferencesSuccess: preferencesSuccessSelector(state),
    currentPageId: pageIdSelector(state),
    metricOptions: metricsOptionsSelector(state),
    preferenceNavLinks: preferenceNavLinksSelector(state),
  }
);

export const mapDispatchToProps = (dispatch) => (
  {
    saveFundTrackerThresholds: (data) => {
      dispatch(saveFundTrackerThresholds(data));
    },
    onPageLoad: () => {
      dispatch(eventAnalytics(onPreferencesPageLoadAnalytics()));
      dispatch(fetchFundTrackerThresholds());
    },
    deleteThreshold: (data) => dispatch(deleteFundTrackerThresholds(data)),
    setFundTrackerInitShareClassData: (data) => dispatch(addAppContext(data))
  }
);
export default connect(mapStateToProps, mapDispatchToProps)(FundTrackerThresholds);
