import React, { useCallback, useMemo } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import FieldRenderer from './FieldRenderer';
import useConsumeHelpGuide from '../../../../../components/core/HelpGuideTray/useConsumeHelpGuide';
import { CLEARING } from '../../constants';
import translator from '../../../../../services/translator';
import './index.scss';

const { translate: t } = translator;

const FieldRendererWithHelpGuide = props => {
  const { helpGuideActive, setSelectedRowDataById, selectedRowData } = useConsumeHelpGuide();

  const rowLabel = useMemo(
    () => (props.config.dataType === CLEARING ? t(props.config.label, props.config.index) : t(props.config.label)),
    [props.config]
  );

  const handleHelpGuide = useCallback(() => {
    if (helpGuideActive) {
      setSelectedRowDataById(rowLabel);
    }
  }, [helpGuideActive, rowLabel]);

  const helpGuideBg = useMemo(() => {
    return helpGuideActive && selectedRowData && rowLabel === selectedRowData.fieldName;
  }, [helpGuideActive, selectedRowData]);

  return (
    <div onClick={handleHelpGuide} className={cn({ helpGuideBg })}>
      <FieldRenderer {...props} />
    </div>
  );
};

FieldRendererWithHelpGuide.propTypes = {
  config: PropTypes.object,
};

export default FieldRendererWithHelpGuide;
