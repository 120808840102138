import React, { useMemo, useState } from 'react';
import { Menu, MenuCheckboxOption } from '@gs-ux-uitoolkit-react/menu';
import { Button } from '@gs-ux-uitoolkit-react/button';
import { Icon } from '@gs-ux-uitoolkit-react/icon-font';
import PropTypes from 'prop-types';

const MultiSelect = props => {

  const { options, selectedValue: value = [], handleChange } = props;

  const [visible, setVisible] = useState(false);
  const [selectedValue, setSelectedValue] = useState(value);

  const onToggleMenu = () => {
    setVisible(true);
  }

  const handleSelectChange = newValue => {
    setSelectedValue(newValue);
    handleChange({ selectedValue: newValue });
  };

  const hideMenu = () => {
    setVisible(false);
  }

  const id = useMemo(() => `menu-target-${Math.floor((Math.random() * 1000000) + 1)}`, []);

  const label = useMemo(() => {
    return options.filter(option => selectedValue?.includes(option.value))?.map(data => data.label)?.join(", ")
  }, [options, selectedValue])

  return (
    <div className='gs-select-checkbox-menu'>
      <Button id={id} title={label} onClick={onToggleMenu}>
        {label}
        <Icon name={`keyboard-arrow-${visible ? "up" : "down"}`} type="filled" />
      </Button>
      <Menu
        className="gs-select-checkbox-menu--list"
        multiple
        size="md"
        value={selectedValue}
        onChange={handleSelectChange}
        visible={visible}
        target={`#${id}`}
        onBlur={hideMenu}
      >
        {
          options.map((option, i) => (
            <MenuCheckboxOption size='md' key={i} value={option.value}>{option.label}</MenuCheckboxOption>
          ))
        }
      </Menu>
    </div >
  )
};

MultiSelect.propTypes = {
  handleChange: PropTypes.func,
  options: PropTypes.array,
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default MultiSelect;