import moment from 'moment/moment';
import cloneDeep from 'lodash/cloneDeep';
import isArray from 'lodash/isArray';
import {
  fetchShareClassesAndBenchmarkData,
  saveFundTrackerThresholdForUser,
  getFundTrackerThresholdsForUser,
  fetchExportData,
  fetchFundAndBenchmarkPerformance,
  fetchAllChartData
} from './ServiceManager';
import {
  getBenchmarkFieldValue,
  getAllHighChartSeries,
  getUpdateSeriesWithNameAndLegend,
  isChartSeriesDataEmpty,
  getAsOfDate,
  orderShareclassPerformance,
  getShareclassPerformancePayload,
  processAllChartDataResponse,
  updateChartSeries, adjustChartSeriesDashStyle
} from '../utils';
import {
  initialLoad,
  isFetchingChartData,
  isFundOrBenchmarkDataMissing,
  isFundOrBenchmarkSeriesDataMissing as isFundOrBenchmarkSeriesDataMissingAction, isMetricDataMissing,
  isThresholdSaved,
  setExcelExportData,
  setShareClassAndBenchmarkData,
  setShareClassPerformanceData,
  setThresholdList,
  updateChartOptions
} from '../store/fund-tracker-slice';
import { CHART_MIN_START_DATE, DATA_TYPE } from '../constants';

export const fetchUserEntitledShareClassesAndBenchmarks = (run, chartDispatch, userGuid) => {
  run(
    fetchShareClassesAndBenchmarkData({userGuid})
      .then(shareClassAndBenchmarkData => {
        chartDispatch(setShareClassAndBenchmarkData(shareClassAndBenchmarkData));
      })
  );
};

/**
 * Given a date, list of benchmarks and shareclasses currently displayed,
 * we retrieve performance data for the `shareclasses` and the `benchmarks` simultaneously
 */
export const fetchAllPerformanceData = ({ asOfDate, benchmarks = [], shareclasses = [], chartDispatch }) => {
  if (asOfDate && moment(asOfDate, 'YYYY-MM-DD', true).isValid()) {
    const benchmarkPayload = { date: asOfDate, vendorIds: benchmarks.map(({ id }) => id) };
    const { orderedShareclassIds, shareclassPayload } = getShareclassPerformancePayload(shareclasses, asOfDate);

    fetchFundAndBenchmarkPerformance({
      benchmarkPayload,
      shareclassPayload
    }).then(({ benchmarkPerformance, shareclassPerformance }) => {
      const orderedShareclasses = orderShareclassPerformance(shareclassPerformance, orderedShareclassIds, 'id');
      chartDispatch(
        // TODO: Rename to allPerformanceData
        setShareClassPerformanceData({
          results: [...orderedShareclasses, ...benchmarkPerformance],
          asOfDate
        })
      );
    });
  }
};

/**
 * given current newly selected shareclasses and benchmarks, fn will fetch chart series for both product types
 * Once the data is fetched, it also fetches the additional performance data
 */
export const fetchAdditionalSeriesData = async ({
  shareclasses,
  benchmarks,
  shareclassFieldValue,
  chartDispatch,
  chartOptions,
  chartState: { allShareClassData, primaryProduct, isApplyRebates },
  addShareClassData,
  yAxisLabel,
  selectedMetrics,
  allSelectedData
}) => {
  const shareclassIds = shareclasses.map(({ value }) => value);
  const vendorIds = benchmarks.map(({ value }) => value);

  chartDispatch(isFetchingChartData(true));
  const { benchmarkSeries, shareclassSeries } = await fetchAllChartData({
    vendorIds,
    shareclassIds,
    startDate: CHART_MIN_START_DATE,
    endDate: moment().format('YYYY-MM-DD'),
    shareclassFieldValue,
    isApplyRebates
  });
  chartDispatch(isFetchingChartData(false));

  const { asOfDate, shareclasses: sc, processedBenchmarks } = processAllChartDataResponse({
    allShareClassData,
    updatedChartOptions: chartOptions,
    yAxisLabel,
    completeData: [...shareclassSeries, ...benchmarkSeries],
    fieldValue: shareclassFieldValue,
    addShareClassData,
    chartDispatch,
    primaryProduct,
    selectedMetrics,
    selectedFundOrBenchmarks: allSelectedData,
    isApplyRebates
  });

  if (asOfDate && (sc || processedBenchmarks)) {
    fetchAllPerformanceData({
      asOfDate,
      chartDispatch,
      benchmarks: processedBenchmarks,
      shareclasses: sc
    });
  }
};

/**
 * fn will fetch the initial series
 */
export const fetchChartDataForSelectedProducts = ({
  run,
  chartDispatch,
  primaryProduct,
  shareClassField,
  selectedFundsOrBenchmarks,
  isApplyRebates = false
}) => {
  const { BENCHMARK } = DATA_TYPE;
  const { fieldLabel, fieldValue } = shareClassField;
  let shareclassIds = selectedFundsOrBenchmarks
    .filter(({type, id}) => (type !== BENCHMARK && id !== primaryProduct.value))
    .map(({value}) => value);
  let vendorIds = selectedFundsOrBenchmarks
    .filter(({type, id}) => (type === BENCHMARK && id !== primaryProduct.value))
    .map(({value}) => value);
  if (primaryProduct.type !== BENCHMARK) {
    shareclassIds = [primaryProduct.value, ...shareclassIds];
  } else {
    vendorIds = [primaryProduct.value, ...vendorIds];
  }
  chartDispatch(isFetchingChartData(true));
  run(
    fetchAllChartData({
      shareclassFieldValue: fieldValue,
      startDate: CHART_MIN_START_DATE,
      endDate: moment(new Date()).format('YYYY-MM-DD'),
      shareclassIds,
      vendorIds,
      isApplyRebates
    }).then(({benchmarkSeries, shareclassSeries}) => {
      let chartSeriesData = [];
      // if primary product was a fund, no need to affect the order
      let orderedData = shareclassIds
        .map(shareclassId =>
          shareclassSeries && isArray(shareclassSeries) && shareclassSeries.find(d => d.id === shareclassId)
        )
        .filter(obj => obj);

      let primaryProductData = null;
      let nonPrimaryBenchSeries = [];
      nonPrimaryBenchSeries = benchmarkSeries.filter(data => {
        const { id } = data;
        if (id === primaryProduct.value) {
          primaryProductData = data;
        }
        return id !== primaryProduct.value;
      });
      if (primaryProductData) {
        orderedData = [primaryProductData, ...orderedData];
      }

      orderedData.push(...nonPrimaryBenchSeries);
      orderedData.forEach(data => {
        getAllHighChartSeries(data, fieldValue, isApplyRebates, chartSeriesData);
      });
      // Adjust line dashStyle based on Adjusted Series
      chartSeriesData = adjustChartSeriesDashStyle(chartSeriesData);

      chartDispatch(isFetchingChartData(false));
      if (chartSeriesData.length > 0) {
        const asOfDate = getAsOfDate(chartSeriesData[0].data);
        const benchmarks = orderedData.filter(data => data.type === BENCHMARK);
        const shareClassPerformanceData = orderedData
          // remove all without share class
          .filter(({shareclass}) => shareclass != null)
          // return shareclasses
          .map(({shareclass}) => shareclass);

        // Add the chart data to the state
        chartDispatch(
          initialLoad({
            series: chartSeriesData,
            primaryProduct,
            shareClassDetails: orderedData,
            fieldLabel,
            asOfDate
          })
        );
        fetchAllPerformanceData({
          shareclasses: shareClassPerformanceData,
          asOfDate,
          chartDispatch,
          benchmarks
        });
      }

      if (chartSeriesData.length === 0) {
        chartDispatch(
          isFundOrBenchmarkDataMissing({
            isFundOrBenchmarkDataMissing: chartSeriesData.length === 0
          })
        );
      } else {
        const isFundOrBenchmarkSeriesDataMissing = isChartSeriesDataEmpty(chartSeriesData[0]);
        chartDispatch(
          isFundOrBenchmarkSeriesDataMissingAction({
            isFundOrBenchmarkSeriesDataMissing
          })
        );
      }
    })
  );
};

/** Adds the selected metrics to the series for comparision */
export const addMetricToSeries = (
  metrics,
  run,
  chartDispatch,
  updatedChartOptions,
  { primaryProduct, isApplyRebates, allShareClassData },
  selectedMetrics,
  addAxisOpposite = true
) => {
  const { value: metricValue, label: yAxisLabel } = metrics;
  // const yAxisId = `${primaryProduct.value}-${metricValue}`;
  chartDispatch(isFetchingChartData(true));

  run(
    fetchAllChartData({
      startDate: CHART_MIN_START_DATE,
      endDate: moment(new Date()).format('YYYY-MM-DD'),
      shareclassIds: primaryProduct.type === DATA_TYPE.FUND ? [primaryProduct.value] : [],
      vendorIds: primaryProduct.type === DATA_TYPE.BENCHMARK ? [primaryProduct.value] : [],
      shareclassFieldValue: metricValue,
      isApplyRebates
    }).then(({ benchmarkSeries, shareclassSeries }) => {
      const completeData = [...shareclassSeries, ...benchmarkSeries];
      chartDispatch(isFetchingChartData(false));
      completeData.forEach(data => {
        const clonedChartOptions = cloneDeep(updatedChartOptions);
        // const isSecondaryYAxis = clonedChartOptions.yAxis.length >= 1;
        // Add the series
        // const chartSeriesData = getHighChartSeries(data, yAxisId, yAxisLabel, isSecondaryYAxis, isApplyRebates);
        const chartSeriesData = [];
        getAllHighChartSeries(data, metricValue, isApplyRebates, chartSeriesData, yAxisLabel);
        clonedChartOptions.series = [...clonedChartOptions.series, ...chartSeriesData];
        // Add the Y axis
        clonedChartOptions.yAxis = [...clonedChartOptions.yAxis, {
          title: {
            text: yAxisLabel
          },
          opposite: addAxisOpposite,
          showLastLabel: true
        }];
        // Update the name and legend of the series accordingly
        const updateSeries = getUpdateSeriesWithNameAndLegend(
          clonedChartOptions.series,
          allShareClassData,
          primaryProduct,
          selectedMetrics,
          isApplyRebates
        );
        chartDispatch(updateChartOptions({
          chartOptions: {
            ...clonedChartOptions,
            series: updateSeries
          }
        }));
        chartDispatch(isMetricDataMissing({
          isMetricDataMissing: isChartSeriesDataEmpty(chartSeriesData)
        }));
      });
    })
  );
};

export const fetchFundsOrBenchmarksData = (
  run,
  fieldForQuery,
  benchmarkMetricsOptions,
  selectedFundsOrBenchMarks,
  chartDispatch,
  chartOptions,
  chartState,
  addShareClassData,
  yAxisLabel,
  selectedMetrics,
  allSelectedShareclasses
) => {
  const {fieldValue} = fieldForQuery;
  const addedFunds = selectedFundsOrBenchMarks.filter(({type}) => type !== DATA_TYPE.BENCHMARK);
  const addedBenchmarks = selectedFundsOrBenchMarks.filter(({type}) => type === DATA_TYPE.BENCHMARK);
  fetchAdditionalSeriesData({
    addShareClassData,
    allSelectedData: allSelectedShareclasses,
    benchmarkFieldValue: getBenchmarkFieldValue(benchmarkMetricsOptions, fieldValue),
    benchmarks: addedBenchmarks,
    chartDispatch,
    chartOptions,
    chartState,
    run,
    selectedMetrics,
    shareclassFieldValue: fieldValue,
    shareclasses: addedFunds,
    yAxisLabel
  });
};

export const getFundTrackerThresholds = (run, chartDispatch, userGuid) => {
  run(
    getFundTrackerThresholdsForUser(userGuid)
      .then(thresholdList => {
        chartDispatch(setThresholdList({
          thresholdList
        }));
      })
  );
};

export const saveFundTrackerThreshold = (run, chartDispatch, thresholdPayload) => {
  run(
    saveFundTrackerThresholdForUser(thresholdPayload)
      .then(() => {
        chartDispatch(isThresholdSaved(true));
        // Reload the thresholds list
        getFundTrackerThresholds(
          run,
          chartDispatch,
          thresholdPayload.userGuid
        );
      })
  );
};

export const fetchFundTrackerExportData = (
  run,
  chartDispatch,
  startDate,
  endDate,
  shareclassIds,
  fieldValue,
  vendorIds
) => {
  run(
    fetchExportData({
      startDate,
      endDate,
      shareclassIds,
      field: fieldValue,
      vendorIds
    }).then(({ benchmarksExport, fundsExport }) => {
      chartDispatch(
        setExcelExportData({
          fundsExport,
          benchmarksExport
        })
      );
    })
  );
};

export const updateCompareMetricsToChart = async (
  chartDispatch,
  chartOptions,
  chartInstance,
  {primaryProduct, isApplyRebates, allShareClassData},
  selectedMetrics
) => {
  let clonedChartOptions = {...chartOptions};
  const [primaryMetricItem = {}, compareMetricItem] = selectedMetrics;

  const primaryProductValue = primaryProduct?.value;
  const { shareclassSeries: primarySC, benchmarkSeries: primaryBench } = await fetchAllChartData({
    startDate: CHART_MIN_START_DATE,
    endDate: moment(new Date()).format('YYYY-MM-DD'),
    ...(primaryProduct?.type === DATA_TYPE.BENCHMARK ? {vendorIds: [primaryProductValue], shareclassIds: []} : {vendorIds: [], shareclassIds: [primaryProductValue]}),
    isApplyRebates,
    shareclassFieldValue: primaryMetricItem.value
  });

  const { shareclassSeries: compareSC, benchmarkSeries: compareBench } = await fetchAllChartData({
    startDate: CHART_MIN_START_DATE,
    endDate: moment(new Date()).format('YYYY-MM-DD'),
    ...(primaryProduct?.type === DATA_TYPE.BENCHMARK ? {vendorIds: [primaryProductValue], shareclassIds: []} : {vendorIds: [], shareclassIds: [primaryProductValue]}),
    isApplyRebates,
    shareclassFieldValue: compareMetricItem.value
  });

  let primaryMetricData = primarySC;
  let compareMetricData = compareSC;

  if (primaryProduct?.type === DATA_TYPE.BENCHMARK) {
    primaryMetricData = primaryBench;
    compareMetricData = compareBench;
  }
  Promise.all([primaryMetricData, compareMetricData])
    .then(completeData => {
      completeData.forEach(([data], index) => {
        const addAxisOpposite = (index === 0);
        const metricItem = (index === 0) ? primaryMetricItem : compareMetricItem;
        // Remove the series
        clonedChartOptions = updateChartSeries(
          metricItem,
          [],
          {
            chartOptions: clonedChartOptions,
            chartInstance,
            isApplyRebates: true,
            primaryProduct
          }
        );
        const {value: metricValue, label: yAxisLabel} = metricItem;
        const chartSeriesData = [];
        getAllHighChartSeries(
          data,
          metricValue,
          isApplyRebates,
          chartSeriesData,
          yAxisLabel
        );
        const updatedSeries = [...clonedChartOptions.series, ...chartSeriesData];

        // If the yAxis is not Array, make it array
        if (!isArray(clonedChartOptions.yAxis)) {
          clonedChartOptions.yAxis = [];
        }

        // Add the YAxis and update the name and legend of the series accordingly
        clonedChartOptions = {
          ...clonedChartOptions,
          yAxis: [
            ...clonedChartOptions.yAxis,
            {
              title: {
                text: yAxisLabel
              },
              opposite: addAxisOpposite,
              showLastLabel: true
            }
          ],
          series: getUpdateSeriesWithNameAndLegend(
            updatedSeries,
            allShareClassData,
            primaryProduct,
            selectedMetrics,
            isApplyRebates
          )
        };
      });
      chartDispatch(updateChartOptions({
        chartOptions: {...clonedChartOptions}
      }));
    });
};
