/** on fund tracker module, we are dealing with either benchmarks or funds */
export const DATA_TYPE = {
  BENCHMARK: 'benchmark',
  FUND: 'fund'
};

/** all charts will be plotted with a minimum date of jan 1st 2016 */
export const CHART_MIN_START_DATE = '2016-01-01';

/** id used to track the plotline for chart date selected */
export const PLOTLINE_ID = 'myPlotLineId';

/** id for "My top holdings" options in primary search dropdown */
export const MY_TOP_HOLDING_ID = 'myTopHoldings';
export const FUND_TRACKER = {
  BENCHMARKS: 'benchmarks',
  METRICS: 'metrics',
  PERFORMANCE: 'Performance'
};

export const REBATE_ELIGIBLE_METRICS = [
  'yields.yield.amount',
  'yields.curYield7Day.amount',
  'yields.effYield7Day.amount',
  'yields.yield30Day.amount'
];

export const ADJUSTED_PREFIX = 'ADJUSTED_';
