import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import translator from '../../../../services/translator';
import { fundIdentifierPreference } from '../../../../selectors/preferences';
import { getFundDisplay } from '../../utils';
import { getProductDisplayedDetails, getProductName } from '../../utils/helpers';
import { fundTrackerFieldSelector } from '../../../../selectors/pages/fundTracker';

const { translate: t } = translator;

const CompareModalHeader = props => {
  const { fundIdentifier, primaryDisplayedProduct } = props;
  const fundDisplay = getFundDisplay(primaryDisplayedProduct, fundIdentifier);
  const { type, name } = getProductDisplayedDetails(primaryDisplayedProduct);
  const displayedName = getProductName(type, name, fundDisplay);

  if (!primaryDisplayedProduct?.type) return null;

  const heading = `${t('tkCompare')} ${displayedName}`;

  return <div className='compare-modal__heading'>{heading}</div>;
};

const mapStateToProps = state => ({
  fundIdentifier: fundIdentifierPreference(state),
  primaryDisplayedProduct: fundTrackerFieldSelector(state, 'shareclass') // TODO: change shareclass name
});

CompareModalHeader.propTypes = {
  fundIdentifier: PropTypes.string,
  primaryDisplayedProduct: PropTypes.object,

};

export default connect(mapStateToProps)(CompareModalHeader);
