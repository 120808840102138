import React from 'react';
import DOMPurify from 'dompurify';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { combinedStaticDisclaimersSelector } from '../../../../selectors/app';
import { Conditional } from '../../../../components/core/Conditional';
import './index.scss';

class Footer extends React.Component {
  constructor() {
    super();
    this.textDisclaimer = React.createRef();
    this.shortDisclaimer = React.createRef();
    this.state = {
      isLongDisclaimer: true
    };
  }

  render() {
    const {shortDisclaimers, longDisclaimers, copyrightText = '', links, authorized} = this.props;
    const serverDate = document.getElementById('date') && document.getElementById('date').content || (new Date()).getFullYear().toString();
    const copyrightTextYear = copyrightText.replace(/\[YEAR\]/, serverDate);
    const sanitizedLongDisclaimer = DOMPurify.sanitize(shortDisclaimers);
    const sanitizedShortDisclaimer = DOMPurify.sanitize(longDisclaimers);

    return (
      <footer className="fundtracker-disclaimer" id="fundtrackerFooter">
        <div className="fundtracker-disclaimer__content">
          <div
            className="fundtracker-disclaimer__row"
            ref={this.shortDisclaimer}
            dangerouslySetInnerHTML={{__html: sanitizedShortDisclaimer}}
          />
          <Conditional condition={authorized}>
            <div
              className="fundtracker-disclaimer__row"
              ref={this.textDisclaimer}
              style={{
                overflowY: 'hidden',
                top: !this.state.isLongDisclaimer ? -9999 : 0,
                position: !this.state.isLongDisclaimer ? 'absolute' : 'static'
              }}>
              <div
                dangerouslySetInnerHTML={{__html: sanitizedLongDisclaimer}}
              />
              <div style={{
                overflowY: 'hidden',
                top: !this.state.isLongDisclaimer ? -9999 : 0,
                position: !this.state.isLongDisclaimer ? 'absolute' : 'static'
              }}>
                {
                  links && links.map((l, i) => <div key={i} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(l)}} />)
                }
              </div>
            </div>
          </Conditional>
          <div className="fundtracker-disclaimer__footer">
            <div className="fundtracker-disclaimer__copyright" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(copyrightTextYear)}} />
          </div>
        </div>
      </footer>
    );
  }
}

Footer.propTypes = {
  authorized: PropTypes.bool,
  shortDisclaimers: PropTypes.string,
  longDisclaimers: PropTypes.string,
  copyrightText: PropTypes.string,
  links: PropTypes.array
};

const mapStateToProps = state => combinedStaticDisclaimersSelector(state, true);

export default connect(mapStateToProps, null)(Footer);
