import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';

const OrderedListItem = React.memo(({ listItem, handleItemDismissed, disableDismissButton, isUndismissable }) => {
  return (
    <li className='order-list-item'>
      {isUndismissable ? (
        <span>{listItem.value}</span>
      ) : (
        <span className='order-list-item__removable'>
          {listItem.value}
          <span
            onClick={() => handleItemDismissed(listItem.id)}
            className={disableDismissButton ? 'order-list-item__remove-icon__disable' : 'order-list-item__remove-icon'}
          />
        </span>
      )}
    </li>
  );
});

OrderedListItem.propTypes = {
  listItem: PropTypes.object,
  handleItemDismissed: PropTypes.func,
  disableDismissButton: PropTypes.bool,
  isUndismissable: PropTypes.bool
};

export default OrderedListItem;
