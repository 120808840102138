/* eslint-disable max-lines */
import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { DataGrid } from '@gs-ux-uitoolkit-react/datagrid';
import { AllModules } from '@gs-ux-uitoolkit-react/datagrid-modules';
import { Checkbox } from '@gs-ux-uitoolkit-react/checkbox';
import { cloneDeep, isEmpty, isEqual } from 'lodash';
import cn from 'classnames';
import useUserMaintenance from '../../../context';
import useOrganizationAccounts from '../../../hooks/useOrganizationAccounts';
import useGetRecentSearch from '../../../hooks/useGetRecentSearch';
import useSaveRecentSearch from '../../../hooks/useSaveRecentSearch';
import useDidMount from '../../../hooks/useDidMount';
import InnerRenderer from './InnerRenderer';
import AutoComplete from '../../../../../../components/core/AutoComplete';
import SearchForm from './SearchForm';
import SidePanel from './SidePanel';
import UserDetailsModal from '../Modal';
import ExpandCollapseAll from './ExpandCollapseAll';
import { Conditional } from '../../../../../../components/core/Conditional';
import { useAgTree, treeConfig } from './config';
import { customizedAccountsAgTree } from '../../../utils/accountsTree';
import { getSortedStringData } from '../../../../../../utils/sorting';
import AccountTooltip from './AccountTooltip';
import { searchAndCenter, updateOverrideContainerDisplay, flattenTheResponse } from '../../../utils/accountsHelper';
import {
  ACTIVE_ACCOUNTS_TYPE,
  UPDATE_ORGANIZATION_ACCOUNTS,
  OPEN_MODAL,
  UPDATE_ACCOUNT_ENTITLEMENTS,
  EXTERNAL_USER_ACCOUNTS,
  ORIGINAL_ACCOUNTS_TREE,
  SELECTED_ORGANIZATION,
  TAB_SWITCHED,
} from '../../../actions';
import { AVAILABLE_ACCOUNTS, CREATE_USER, ENTITLED_ACCOUNTS, MODAL } from '../../../constants';
import { TABS } from '../TabSwitchBar';
import translator from '../../../../../../services/translator';
import '../../../../../../components/core/Grid/index.scss';
import '../../../../../../components/core/Grid/theme/ag-tree-theme.scss';
import './index.scss';

const { translate: t } = translator;

// eslint-disable-next-line complexity, max-statements
const Accounts = () => {
  const {
    state: {
      isEditable,
      userDetails,
      activeAccountsType,
      modal: [, , allAccountsConfirmation],
      organizationAccounts,
      selectedOrganization,
      externalUserAccounts: { entitled: entitledBackup, available: availableBackup },
      originalAccountsTree,
      savedRecentEdits,
      saveBtnClicked,
      accountEntitlements,
      externalUserAccounts,
      showLoader
    },
    dispatch,
    storeValues: { isInternalUser, pageKey },
  } = useUserMaintenance();

  const { isMosaicInternal, id: userId, accountDetails: accountDetailsData, userGuid, isInternal } = userDetails;

  const allowOrganizationSearch = (pageKey === CREATE_USER ? isInternal : isInternalUser) && isMosaicInternal;

  const formRef = useRef();
  const autoCompleteRef = useRef();
  const tooltipHoverRef = useRef();
  const [showAccountSidePanel, setShowAccountSidePanel] = useState(false);
  const { gridRef, autoGroupColumnDef, ...agTreeProps } = useAgTree(showAccountSidePanel);
  const isMounted = useDidMount();
  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);

  const { getRecentSearch, recentSearchData } = useGetRecentSearch(userGuid, 'ORGANIZATION_SEARCH_USER_EDIT', 7);
  const { saveRecentSearch, success: savedRecentSearch } = useSaveRecentSearch();

  const [searchTerm, setSearchTerm] = useState(null);
  const [showEntitledAccounts, setShowEntitledAccounts] = useState(activeAccountsType === ENTITLED_ACCOUNTS);
  const [dataPassedToGrid, setDataPassedToGrid] = useState(false);
  const [selectedNodes, setSelectedNodes] = useState([]);
  const [overrideContainerDisplay, setOverrideContainerDisplay] = useState(false);

  const { values: recentSearchResult = [] } = recentSearchData || {};

  const recentSearchList = useMemo(() => {
    if (recentSearchResult.length) {
      return [
        {
          title: null,
          values: recentSearchResult.map(data => ({
            ...data,
            isOrganization: true,
            label: data.name,
            labels: [{ name: data.name, isScGreyText: false }],
          })),
        },
      ];
    }
    return recentSearchResult;
  }, [recentSearchResult]);

  useEffect(() => {
    if (pageKey !== CREATE_USER && ((allowOrganizationSearch && savedRecentSearch) || allowOrganizationSearch)) {
      getRecentSearch();
    }
  }, [allowOrganizationSearch, savedRecentSearch, userId, pageKey]);

  const getInitialGridRows = useMemo(() => {
    let initialGridRows;
    if (entitledBackup.length && activeAccountsType === ENTITLED_ACCOUNTS) {
      return entitledBackup;
    }
    if (availableBackup.length && activeAccountsType === AVAILABLE_ACCOUNTS) {
      return availableBackup;
    }
    if (allowOrganizationSearch && isEmpty(selectedOrganization)) {
      initialGridRows = [];
    } else if (accountDetailsData.length) {
      initialGridRows = customizedAccountsAgTree(accountDetailsData);
      !allowOrganizationSearch && isMounted && dispatch({ type: ORIGINAL_ACCOUNTS_TREE, payload: accountDetailsData });
    } else if (!accountDetailsData.length) {
      setDataPassedToGrid(true);
    }
    return initialGridRows || [];
  }, [allowOrganizationSearch, accountDetailsData]);

  const [accountDetails, setAccountDetails] = useState(getInitialGridRows);

  const autoGroupColumn = useMemo(
    () => ({
      ...autoGroupColumnDef,
      cellRendererParams: {
        ...autoGroupColumnDef.cellRendererParams,
        innerRenderer: InnerRenderer,
      },
    }),
    [autoGroupColumnDef]
  );

  const { autoCompleteLoading, fetchAutoCompleteData, autoComplete = {}, loading, fetchOrganizationAccounts, data } = useOrganizationAccounts();

  const { organizations: { values = [] } = {} } = autoComplete || {};

  // Organization Autocomplete Input Change
  const onInputChange = data => {
    data.length >= 3 && fetchAutoCompleteData({ searchString: data, searchGroups: ['organization'], searchMaxResults: 7 });
  };

  // Organization Autocomplete Option Selection
  const onOptionSelection = data => {
    saveRecentSearch({
      requestedUserGuid: userGuid,
      categoryTypeName: 'ORGANIZATION_SEARCH_USER_EDIT',
      values: [
        {
          id: data.id,
        },
      ],
    });
    dispatch({ type: SELECTED_ORGANIZATION, payload: { organizationName: data.name, organizationId: data.id } });
    formRef.current && formRef.current.onClearClick();
    setSearchTerm(null);
    showAccountSidePanel && setShowAccountSidePanel(false);
    const payload = {
      userId,
      organizationId: data.id,
      type: activeAccountsType === ENTITLED_ACCOUNTS ? 'ENTITLED' : 'AVAILABLE',
    };
    if (data.id !== selectedOrganization.organizationId && !(data.id in organizationAccounts)) {
      fetchOrganizationAccounts(payload);
    } else {
      setAccountDetails(organizationAccounts[data.id].accountDetails);
      const { accountDetails, ...restOrganizationData } = organizationAccounts[data.id];
      dispatch({ type: SELECTED_ORGANIZATION, payload: restOrganizationData });
      showAccountSidePanel && setShowAccountSidePanel(false);
      setDataPassedToGrid(true);
      setSearchTerm(null);
      formRef.current && formRef.current.onClearClick();
    }
    autoCompleteRef.current && autoCompleteRef.current.setSearchTerm('');
  };

  const updatedOptions = useMemo(() => {
    if (!autoComplete) return [];
    const result = [];
    if (values.length) {
      const allMatches = autoComplete.organizations.values.map(item => ({
        ...item,
        label: item.name,
        labels: [{ name: item.name, isScGreyText: false }],
      }));
      result.push({ title: null, values: getSortedStringData(allMatches, 'label', true) });
    }
    return result;
  }, [autoComplete]);

  // Tree Search Submit Handler
  const onSubmit = inputValue => {
    // eslint-disable-next-line no-new-wrappers
    setSearchTerm(new String(inputValue.trim()));
  };

  // Running this effect after data fetching (organization accounts)
  useEffect(() => {
    if (data) {
      const newAccountDetails = customizedAccountsAgTree(data.accountDetails);
      setAccountDetails(newAccountDetails);
      dispatch({ type: ORIGINAL_ACCOUNTS_TREE, payload: data.accountDetails });
      setDataPassedToGrid(true);
      setSearchTerm(null);
      formRef.current && formRef.current.onClearClick();
      allowOrganizationSearch &&
        dispatch({ type: SELECTED_ORGANIZATION, payload: { organizationName: data.organizationName, organizationId: data.id } });
    }
  }, [data]);

  const currentOrganizationAccounts = useMemo(() => Object.values(organizationAccounts).reverse(), [organizationAccounts]);

  // Recent/Pending Edit Click Handler - will fetch the latest data if saved, else update the draft state to selected organization
  const selectRecentEdit = id => {
    if (savedRecentEdits) {
      const payload = {
        userId,
        organizationId: id,
        type: activeAccountsType === ENTITLED_ACCOUNTS ? 'ENTITLED' : 'AVAILABLE',
      };
      fetchOrganizationAccounts(payload);
    } else if (id !== selectedOrganization.organizationId) {
      setAccountDetails(organizationAccounts[id].accountDetails);
      const { accountDetails, ...restOrganizationData } = organizationAccounts[id];
      dispatch({ type: SELECTED_ORGANIZATION, payload: restOrganizationData });
      showAccountSidePanel && setShowAccountSidePanel(false);
    }
    setSearchTerm(null);
    formRef.current && formRef.current.onClearClick();
  };

  // On Pending Remove Button Click - will delete the organization draft & update the state to the most recent pending edit
  const removeRecentEdit = organizationId => {
    const updatedOrganizationAccounts = Object.values(organizationAccounts).filter(item => item.organizationId !== organizationId);
    dispatch({
      type: UPDATE_ORGANIZATION_ACCOUNTS,
      payload: { operation: 'DELETE', organizationId },
    });
    dispatch({ type: UPDATE_ACCOUNT_ENTITLEMENTS, payload: { operation: 'DELETE', organizationId } });
    if (updatedOrganizationAccounts.length >= 1) {
      const [mostRecentEdit] = updatedOrganizationAccounts.reverse();
      const { accountDetails, ...restOrganizationData } = mostRecentEdit;
      setAccountDetails(mostRecentEdit.accountDetails);
      dispatch({ type: SELECTED_ORGANIZATION, payload: restOrganizationData });
    } else {
      setAccountDetails([]);
      dispatch({ type: SELECTED_ORGANIZATION, payload: {} });
      setDataPassedToGrid(false);
    }
    setSearchTerm(null);
    formRef.current && formRef.current.onClearClick();
  };

  // On hide entitled accounts checkbox change, In edit mode - opens the modal ; view mode directly updates the checkbox state
  const handleAccountsChange = () => {
    setSearchTerm(null);
    formRef.current && formRef.current.onClearClick();
    if (isEditable && accountEntitlements.length) {
      dispatch({ type: OPEN_MODAL, payload: { modalName: MODAL.allAccountsConfirmation } });
      return;
    }
    setShowEntitledAccounts(prevState => !prevState);
  };

  // Running this effect to update the highlighted search term & remove any error if no match found
  useEffect(() => {
    if (searchTerm && gridRef && gridRef.api) {
      const total = searchAndCenter(gridRef, searchTerm);
      if (!total) {
        formRef.current && formRef.current.setError(true);
      }
    }
  }, [searchTerm]);

  // Running this effect on sidepanel display, setTimeout is for refreshing the grid after sometime
  useEffect(() => {
    setTimeout(() => {
      gridRef && gridRef.api && gridRef.api.onGroupExpandedOrCollapsed();
    }, 0);
  }, [showAccountSidePanel, gridRef]);

  // Running this effect on edit mode change, rollback & update to previous state on cancelling edit mode
  useEffect(() => {
    setShowAccountSidePanel(false);
    setSelectedNodes([]);
    if (!isEditable && !isMounted && !savedRecentEdits) {
      if (!allowOrganizationSearch || (allowOrganizationSearch && selectedOrganization.organizationId))
        setAccountDetails(customizedAccountsAgTree(originalAccountsTree));
      setSearchTerm(null);
      formRef.current && formRef.current.onClearClick();
    }
    gridRef && gridRef.api && gridRef.api.onGroupExpandedOrCollapsed();
  }, [isEditable, gridRef]);

  // Running this effect on hide non entitled entitled checkbox change, data fetching & state updates on active account type.
  useEffect(() => {
    if (!isMounted) {
      if (showEntitledAccounts && allowOrganizationSearch) {
        selectedOrganization.organizationId &&
          fetchOrganizationAccounts({ userId, organizationId: selectedOrganization.organizationId, type: 'ENTITLED' });
      } else if (showEntitledAccounts && !allowOrganizationSearch) {
        setAccountDetails(customizedAccountsAgTree(accountDetailsData));
      } else if (!showEntitledAccounts && !allowOrganizationSearch) {
        dispatch({ type: SELECTED_ORGANIZATION, payload: { organizationName: userDetails.organizationName } });
        userDetails.organizationId && fetchOrganizationAccounts({ userId, organizationId: userDetails.organizationId, type: 'AVAILABLE' });
      } else if (!showEntitledAccounts && allowOrganizationSearch) {
        selectedOrganization.organizationId &&
          fetchOrganizationAccounts({ userId, organizationId: selectedOrganization.organizationId, type: 'AVAILABLE' });
      }
      showAccountSidePanel && setShowAccountSidePanel(false);
      dispatch({ type: ACTIVE_ACCOUNTS_TYPE, payload: { selectedAccountType: showEntitledAccounts ? 'entitled' : 'available' } });

      if (allowOrganizationSearch && !isEmpty(organizationAccounts)) {
        dispatch({ type: UPDATE_ORGANIZATION_ACCOUNTS, payload: { operation: 'RESET' } });
        dispatch({ type: SELECTED_ORGANIZATION, payload: {} });
      }
    }
  }, [showEntitledAccounts, pageKey]);

  // Running this effect on saveBtn click, deleting modified node properties like disabled, orangeBackgroundColour in override... which applied to the node in edit mode
  useEffect(() => {
    if (saveBtnClicked && showAccountSidePanel) {
      selectedNodes.forEach(node => {
        node.setSelected(false);
        const { orangeNode, ...restData } = node.data;
        node.data = { ...restData, disabled: false };
        node.allLeafChildren.forEach(childNode => {
          childNode.setSelected(false);
          const { orangeNode, ...restChildData } = childNode.data;
          childNode.data = { ...restChildData, disabled: false };
        });
      });
    }
    if (saveBtnClicked) {
      setSearchTerm(null);
      formRef.current && formRef.current.onClearClick();
    }
  }, [saveBtnClicked]);

  // Running this effect to fetch available accounts data (if hide non entitled accounts checkbox isn't checked on page load)
  useEffect(() => {
    userId &&
      !allowOrganizationSearch &&
      activeAccountsType === AVAILABLE_ACCOUNTS &&
      !availableBackup.length &&
      userDetails.organizationId &&
      fetchOrganizationAccounts({ userId, organizationId: userDetails.organizationId, type: 'AVAILABLE' });
  }, []);

  // Running this effect to update latest accountDetails fetched after clicking save btn (only updates external user entitled accounts)
  useEffect(() => {
    if (showEntitledAccounts && !allowOrganizationSearch && !isMounted) {
      setSearchTerm(null);
      formRef.current && formRef.current.onClearClick();
      setAccountDetails(customizedAccountsAgTree(accountDetailsData));
    } else if (pageKey === CREATE_USER) {
      // set data for create new user clone with different org
      setAccountDetails(customizedAccountsAgTree(accountDetailsData));
    }
  }, [accountDetailsData, showEntitledAccounts, allowOrganizationSearch]);

  // Running this effect to update latest accountDetails fetched for internal user after clicking save btn
  useEffect(() => {
    allowOrganizationSearch &&
      selectedOrganization.organizationId &&
      organizationAccounts[selectedOrganization.organizationId] &&
      setAccountDetails(organizationAccounts[selectedOrganization.organizationId].accountDetails);
  }, [organizationAccounts]);

  // Running this effect to update latest accountDetails fetched after clicking save btn (only updates external user available accounts)
  useEffect(() => {
    !allowOrganizationSearch &&
      selectedOrganization.organizationName &&
      activeAccountsType === AVAILABLE_ACCOUNTS &&
      setAccountDetails(externalUserAccounts.available);
  }, [externalUserAccounts]);

  /*  On node checkbox selection - compares the selected node capabilities with its children node capabilities -
      If different updates the override container display. Also disables the children nodes.
      unchecked - removes all the properties applied on checkbox selection
  */
  const onRowClicked = params => {
    if (params.event.target.className.includes('checkboxRenderer')) {
      const isSelected = !params.node.isSelected();
      params.node.setSelected(isSelected);
      if (isSelected) {
        params.node.allLeafChildren.forEach(node => {
          node.data = { ...node.data, dynamicIcons: true };
          if (node.data.rowId !== params.node.data.rowId) {
            node.expanded = true;
            node.data = { ...node.data, disabled: true };
            if (node.selected) {
              node.setSelected(false);
            }
            if ('capabilities' in node.data && !isEqual(params.node.data.capabilities, node.data.capabilities)) {
              !overrideContainerDisplay && setOverrideContainerDisplay(true);
              node.data = { ...node.data, orangeNode: true };
            }
          } else {
            node.expanded = true;
          }
        });
        params.node.data = { ...params.node.data, disabled: false, dynamicIcons: true };
      } else {
        const { newCapabilities, dynamicIcons, ...restData } = params.node.data;
        params.node.data = { ...restData };
        params.node.allLeafChildren.forEach(node => {
          const { orangeNode, newCapabilities, dynamicIcons, ...restChildData } = node.data;
          node.data = { ...restChildData, disabled: false };
        });
      }

      const nodes = gridRef.api.getSelectedNodes();
      nodes.forEach(node => {
        node.allLeafChildren = node.allLeafChildren.filter(child => node.data.rowId !== child.data.rowId);
      });
      setSelectedNodes(nodes);
      const overrideValue = updateOverrideContainerDisplay(nodes);
      setOverrideContainerDisplay(overrideValue);
      setShowAccountSidePanel(!!nodes.length);
      gridRef && gridRef.api && gridRef.api.onGroupExpandedOrCollapsed();
    }
  };

  // Sidepanel capabilities change handler, updates the modified capabilities to the selected nodes & its children.
  const onCapabilitiesChange = (updatedCapabilities, override) => {
    const rowIds = new Set(selectedNodes.map(node => node.data.rowId));
    const nodeLevels = [1, 3];
    gridRef.api.forEachNode(node => {
      if (rowIds.has(node.data.rowId)) {
        if (override) {
          if (node.level === 0) {
            node.data = { ...node.data, newCapabilities: updatedCapabilities };
          }
          if (nodeLevels.includes(node.level) && !node.data.allowBranchLevelTradeApproval) {
            const newCapabilities = cloneDeep(node.data.capabilities);
            newCapabilities.canView = updatedCapabilities.canView;
            newCapabilities.trade.canTrade = updatedCapabilities.trade.canTrade;
            node.data = { ...node.data, newCapabilities };
          }
          node.allLeafChildren.forEach(subNode => {
            if (nodeLevels.includes(subNode.level) && !subNode.data.allowBranchLevelTradeApproval) {
              const newCapabilities = cloneDeep(subNode.data.capabilities);
              newCapabilities.canView = updatedCapabilities.canView;
              newCapabilities.trade.canTrade = updatedCapabilities.trade.canTrade;
              newCapabilities.approver.canApprove = false;
              subNode.data = { ...subNode.data, newCapabilities };
            } else {
              subNode.data = { ...subNode.data, newCapabilities: updatedCapabilities };
            }
          });
        } else {
          node.data = { ...node.data, newCapabilities: updatedCapabilities };
          node.allLeafChildren.forEach(subNode => {
            if (subNode.data.orangeNode) {
              subNode.data = { ...subNode.data, newCapabilities: subNode.capabilities };
            } else if (nodeLevels.includes(subNode.level) && !subNode.data.allowBranchLevelTradeApproval) {
              const newCapabilities = cloneDeep(node.data.capabilities);
              newCapabilities.canView = updatedCapabilities.canView;
              newCapabilities.trade.canTrade = updatedCapabilities.trade.canTrade;
              subNode.data = { ...subNode.data, newCapabilities };
            } else {
              subNode.data = { ...subNode.data, newCapabilities: updatedCapabilities };
            }
          });
        }
      }
    });
    gridRef && gridRef.api && gridRef.api.onGroupExpandedOrCollapsed();
  };

  /* On closing sidepanel - either by applying or discarding the capabilties changes. Also finds & updates the nodes to include in the payload.
  Changes are applied to the nodes as per https://confluence.work.gs.com/display/MOSAIC/Test+Case+Review
  */
  const onCloseSidePanel = (isCapabilitiesUpdated, newCapabilities, override) => {
    if (isCapabilitiesUpdated) {
      // Finding Nodes to Include in Payload
      const nodesForPayload = [];
      selectedNodes.forEach(node => {
        if (!overrideContainerDisplay) {
          nodesForPayload.push(node);
        } else {
          if (override) {
            !node.allLeafChildren.length && nodesForPayload.push(node);
            if (node.level === 0) {
              nodesForPayload.push(node);
              const firmCapabilities = node.data.capabilities;
              const branches = node.allLeafChildren.filter(({ level }) => level === 1);
              branches.forEach(branch => {
                const branchCapabilities = branch.data.capabilities;
                if (!isEqual(firmCapabilities, branchCapabilities)) {
                  nodesForPayload.push(branch);
                }
              });
              const taAccounts = node.allLeafChildren.filter(({ level }) => level === 3);
              taAccounts.forEach(taAccount => {
                const taAccountCapabilities = taAccount.data.capabilities;
                branches.forEach(branch => {
                  const branchCapabilities = branch.data.capabilities;
                  if (taAccount.data.orgHierarchy.includes(branch.key) && !isEqual(branchCapabilities, taAccountCapabilities)) {
                    nodesForPayload.push(taAccount);
                  }
                });
              });
            }
            if (node.level === 1) {
              nodesForPayload.push(node);
              const branchCapabilities = node.data.capabilities;
              const taAccounts = node.allLeafChildren.filter(({ level }) => level === 3);
              taAccounts.forEach(taAccount => {
                const taAccountCapabilities = taAccount.data.capabilities;
                if (!isEqual(branchCapabilities, taAccountCapabilities)) {
                  nodesForPayload.push(taAccount);
                }
              });
            }
          } else {
            nodesForPayload.push(node);
          }
        }
      });

      // Generating Payload Account Changes Objects
      const finalAccountsChanges = [];
      nodesForPayload.forEach(item => {
        const responseObject = {};
        const rowIds = selectedNodes.map(node => node.data.rowId);
        if (rowIds.includes(item.data.rowId)) {
          responseObject.capabilities = newCapabilities;
        }
        if (item.level === 0) {
          finalAccountsChanges.push(flattenTheResponse([item.data], responseObject));
        }
        if (item.level === 1) {
          finalAccountsChanges.push(flattenTheResponse([item.data, item.parent.data], responseObject));
        }
        if (item.level === 3) {
          finalAccountsChanges.push(
            flattenTheResponse([item.data, item.parent.data, item.parent.parent.data, item.parent.parent.parent.data], responseObject)
          );
        }
        if (allowOrganizationSearch) {
          responseObject.organizationId = selectedOrganization.organizationId;
        }
      });
      dispatch({ type: UPDATE_ACCOUNT_ENTITLEMENTS, payload: { accountEntitlementChanges: finalAccountsChanges, operation: 'ADD' } });

      // Taking tree backup once changes are applied on OK button click, In case of Tab switch - this backup will restored on the accounts tab.
      const gridBackup = [];

      const nodesToHaveUpdatedCapabilities = new Set();
      selectedNodes.forEach(item => {
        nodesToHaveUpdatedCapabilities.add(item.data.rowId);
        item.allLeafChildren.forEach(childLeaf => {
          if (override || !overrideContainerDisplay) {
            nodesToHaveUpdatedCapabilities.add(childLeaf.data.rowId);
          } else if (isEqual(item.data.capabilities, childLeaf.data.capabilities)) {
            nodesToHaveUpdatedCapabilities.add(childLeaf.data.rowId);
          }
        });
      });

      gridRef.api.forEachNode(node => {
        node.selected && node.setSelected(false);
        const { orangeNode, ...restData } = node.data;
        node.data = { ...restData, disabled: false };
        if (nodesToHaveUpdatedCapabilities.has(node.data.rowId) && 'capabilities' in node.data) {
          if ((node.level === 1 || node.level === 3) && 'allowBranchLevelTradeApproval' in node.data && !node.data.allowBranchLevelTradeApproval) {
            const capabilitiesCpy = cloneDeep(node.data.capabilities);
            capabilitiesCpy.canView = newCapabilities.canView;
            capabilitiesCpy.trade.canTrade = newCapabilities.trade.canTrade;
            capabilitiesCpy.trade.tradeLevel = 'DEFAULT';
            capabilitiesCpy.approver = { canApprove: false, approverLevel: 'L1' };
            node.data = { ...node.data, capabilities: capabilitiesCpy };
          } else {
            node.data = { ...node.data, capabilities: newCapabilities };
          }
        }
        gridBackup.push(node.data);
      });

      gridRef && gridRef.api && gridRef.api.onGroupExpandedOrCollapsed();

      allowOrganizationSearch &&
        dispatch({
          type: UPDATE_ORGANIZATION_ACCOUNTS,
          payload: {
            operation: 'ADD',
            organizationId: selectedOrganization.organizationId,
            organizationName: selectedOrganization.organizationName,
            accountDetails: gridBackup,
          },
        });
      !allowOrganizationSearch && dispatch({ type: EXTERNAL_USER_ACCOUNTS, payload: { key: activeAccountsType, accountDetails: gridBackup } });
      setShowAccountSidePanel(false);
      setSelectedNodes([]);
    } else {
      // On Cancel click of sidepanel
      selectedNodes.forEach(rowNode => {
        rowNode.setSelected(false);
        const { orangeNode, newCapabilities, dynamicIcons, ...restData } = rowNode.data;
        rowNode.data = { ...restData, disabled: false };
        rowNode.allLeafChildren.forEach(node => {
          node.setSelected(false);
          const { orangeNode, newCapabilities, dynamicIcons, ...restData } = node.data;
          node.data = { ...restData, disabled: false };
        });
      });
      gridRef && gridRef.api && gridRef.api.onGroupExpandedOrCollapsed();
      setShowAccountSidePanel(false);
      setSelectedNodes([]);
    }
  };

  const onTooltipClick = useCallback(event => {
    if (event.target.className.includes('account-exclusion')) {
      dispatch({ type: TAB_SWITCHED, payload: TABS.FUNDS });
    }
  }, []);

  return (
    <div className='accounts account-tree ag-tree' data-testid='accounts-container'>
      <Conditional condition={allAccountsConfirmation.display}>
        <UserDetailsModal
          modalName={MODAL.allAccountsConfirmation}
          accountsAlteration={{
            allowOrganizationSearch,
            selectedOrganization,
            sidePanel: { showAccountSidePanel, setShowAccountSidePanel },
            setAccountDetails,
            setShowEntitledAccounts,
          }}
        />
      </Conditional>
      <div className='accounts__controlpanel'>
        <div className='accounts__mainControls'>
          {allowOrganizationSearch && (
            <AutoComplete
              defaultOptions={pageKey === CREATE_USER ? [] : recentSearchList}
              ref={autoCompleteRef}
              placeholderText={t('tkOrganization')}
              searchIconPosition='left'
              onInputChange={onInputChange}
              debounceTime={150}
              minChar={3}
              isLoading={autoCompleteLoading}
              options={updatedOptions}
              onOptionSelection={onOptionSelection}
              defaultSearchTerm={''}
              errorMessage={t('tkUsearSearchErrorMessage')}
            />
          )}
          {!!accountDetails.length && (
            <div className={cn('accounts__searchContainer', { accounts__searchContainerWithOrg: allowOrganizationSearch })}>
              <SearchForm ref={formRef} onSubmit={onSubmit} />
              <span className='accounts__searchIcon' />
            </div>
          )}
        </div>
        {allowOrganizationSearch && !isEmpty(organizationAccounts) && (
          <div className='recentEdits'>
            {!isEmpty(organizationAccounts) && (
              <div className='recentEdits__title'>
                {`
                  ${isEditable && !isEmpty(organizationAccounts) ? t('tkPending') : ''}
                  ${savedRecentEdits && !isEditable && !isEmpty(organizationAccounts) ? t('tkRecent') : ''}
                  ${t('tkEdits')}:
                `}
              </div>
            )}
            <div>
              {currentOrganizationAccounts.map(({ organizationName, organizationId }) => (
                <button
                  key={organizationId}
                  className={cn({ 'recentEdits__btns--select': organizationName === selectedOrganization.organizationName })}>
                  <span title={` ${organizationName}`} onClick={() => selectRecentEdit(organizationId)}>
                    {organizationName}
                  </span>
                  {!savedRecentEdits && isEditable && (
                    <span
                      title={t('tkDiscardOrganizationAccountsDraft')}
                      className='recentEdits__closeIcon'
                      onClick={() => removeRecentEdit(organizationId)}
                    />
                  )}
                </button>
              ))}
            </div>
          </div>
        )}
      </div>
      <div
        className={cn('accounts__entitlementSection', {
          accounts__entitlementSectionWithOrg: allowOrganizationSearch && !isEmpty(selectedOrganization),
        })}>
        <div className='accounts__entitlementHeader'>{t('tkAccountsEntitlements')}</div>
        <div className='accounts__entitlementSubHeader'>
          {isEditable && !!accountDetails.length ? t('tkAccountsEntitlementsEditSubtitle') : t('tkAccountsEntitlementsSubtitle')}
        </div>
        {allowOrganizationSearch && !accountDetails.length && !dataPassedToGrid && (
          <div className='accounts__entitlementOrganizationSelectionMessage'>
            {t(pageKey === CREATE_USER ? 'tkCreateNewUserAccountsOrganizationSearchMsg' : 'tkAccountsOrganizationSelectionMessage')}
          </div>
        )}
        {!!accountDetails.length && !loading && <ExpandCollapseAll gridRef={gridRef} showAccountSidePanel={showAccountSidePanel} />}
        {((allowOrganizationSearch && !isEmpty(selectedOrganization)) || !allowOrganizationSearch) && (
          <div
            className={cn('accounts__organizationContainer', {
              accounts__organizationContainerExternalUser: !selectedOrganization.organizationId && !userDetails.organizationName,
            })}>
            {(selectedOrganization.organizationId || userDetails.organizationName) && (
              <div className='accounts__organizationName'>
                {`${selectedOrganization.organizationName || userDetails.organizationName}.  `}
                <span>{`(${t('tkOrganization')})`}</span>
              </div>
            )}
            {pageKey !== CREATE_USER && (
              <Checkbox
                checked={showEntitledAccounts}
                onChange={handleAccountsChange}
                className={cn({ accounts__noSearchContainer: !accountDetails.length && !allowOrganizationSearch })}>
                {t('tkShowEntitledAccountsOnly')}
              </Checkbox>
            )}
          </div>
        )}
      </div>
      {accountDetails.length && !loading ? (
        <div className='accounts__treeSection' ref={tooltipHoverRef}>
          <div style={gridStyle} className='ag-theme-balham'>
            <DataGrid
              modules={AllModules}
              suppressBrowserResizeObserver={true}
              ref={gridRef}
              rowData={accountDetails}
              onRowClicked={onRowClicked}
              context={{
                searchTerm,
                isEditable,
                sidePanelOpen: showAccountSidePanel,
              }}
              id='UserDetails'
              autoGroupColumnDef={autoGroupColumn}
              {...treeConfig}
              {...agTreeProps}
            />
          </div>
          <AccountTooltip tooltipHoverRef={tooltipHoverRef} onTooltipClick={onTooltipClick} />
          {showAccountSidePanel && (
            <Conditional condition={isEditable}>
              <SidePanel
                onCapabilitiesChange={onCapabilitiesChange}
                selectedNodes={selectedNodes}
                onCloseSidePanel={onCloseSidePanel}
                overrideContainerDisplay={overrideContainerDisplay}
              />
            </Conditional>
          )}
        </div>
      ) : (
        dataPassedToGrid && !loading && !showLoader && <div className='accounts__noContent'>{t('tkNoAccountData')}</div>
      )}
    </div>
  );
};

export default Accounts;
