import { createSelector } from 'reselect/lib/index';
import Constants, { PAGE_NAV_ITEM } from '../../constants/appConstants';
import { TRADDING_NOT_ALLOWED_PAGEID } from '../../constants/tradeBlotter';
import { pageIdSelector } from '../pages/index';

export const userSelector = state => state.user;
export const userIdSelector = state => state.user && state.user.id ? state.user.id : '';
export const firstNameSelector = state => state.user && state.user.firstName ? state.user.firstName : '';
export const lastNameSelector = state => state.user && state.user.lastName ? state.user.lastName : '';
export const permissionSelector = state => state.user && state.user.permissions ? state.user.permissions : {};
export const jurisdictionSelector = state => state.user && state.user.jurisdiction || '';
export const userGuidSelector = state => state.user && state.user.userGuid ? state.user.userGuid : '';
export const isInternalUserSelector = state => state.user && state.user.isInternal;
export const isMosaicInternalUserSelector = state => state.user && state.user.isMosaicInternal;
export const isGsInternalUserSelector = state => state.user && state.user.isGsInternal;
export const homeAwaySelector = state => state.user && state.user.permissions && state.user.permissions.homeAway;
export const isTradingAllowedSelector = createSelector(permissionSelector, userSelector, pageIdSelector,
  (permissions, user, pageId) => {
    const hasPermission = !!(user && permissions && permissions.canTrade && permissions.allowFeatures && permissions.allowFeatures.indexOf(Constants.TRADING_FEATURE_ENTITLEMENT) > -1);
    const allowedRoute = !TRADDING_NOT_ALLOWED_PAGEID.includes(pageId);
    return hasPermission && allowedRoute;
  });

export const allowFeature = (state) => state?.user?.permissions?.allowFeatures || [];

export const allowFeaturesSelector = createSelector(allowFeature, (allowFeatures) => {
  let allowFeaturesList = [...allowFeatures]
  const ssaRoles = ["SELFSERVICEADMINORGVIEW", "SELFSERVICEADMINORGEDIT", "SELFSERVICEADMINUSERVIEW", "SELFSERVICEADMINUSEREDIT"]
  const hasAnySSASubRole = allowFeaturesList.some(role => ssaRoles.includes(role));
  if (hasAnySSASubRole) {
    allowFeaturesList = allowFeaturesList.includes("SELFSERVICEADMIN") ? allowFeaturesList : [...allowFeaturesList, "SELFSERVICEADMIN"]
  } else {
    allowFeaturesList = allowFeaturesList.filter(allowFeature => allowFeature !== "SELFSERVICEADMIN")
  }
  return allowFeaturesList;
});

export const allowShellAccountsSelector = state => state.user && state.user.permissions && state.user.permissions.allowShellAccounts ? state.user.permissions.allowShellAccounts : false;
export const errorSelector = state => state.user && state.user.error || null;
export const getUserTimeZone = state => state.user && state.user.userTimeZone.timeZone;
export const getUserTimeZoneId = state => state.user && state.user.userTimeZone.zoneId;

export const canShowEmailPreferenceNavItemSelector = createSelector(
  allowFeaturesSelector,
  (allowFeatures) => {
    return allowFeatures.includes(PAGE_NAV_ITEM.TRADING) || allowFeatures.includes(PAGE_NAV_ITEM.TRADEAPPROVAL);
  }
);

export const isImportTradesAllowedSelector = createSelector(allowFeaturesSelector, isTradingAllowedSelector, (allowFeatures, isTradingAllowed) => {
  return allowFeatures.includes(PAGE_NAV_ITEM.TRADEIMPORT) && isTradingAllowed;
});

export const isInvestmentPolicyAllowedSelector = createSelector(allowFeaturesSelector, (allowFeatures) => {
  return allowFeatures.includes(PAGE_NAV_ITEM.INVESTMENTPOLICY);
});

export const isClearWaterSSOEnabledSelector = createSelector(userSelector, (user = {}) => {
  return user.userSSOInfos && user.userSSOInfos.some(userSSOInfo => userSSOInfo.ssoClientId === 1 && userSSOInfo.isSSOEnabled)
});

export default {
  userIdSelector,
  firstNameSelector,
  lastNameSelector,
  permissionSelector
};
