import React, { useEffect, useMemo, useState } from 'react';
import { Menu, MenuCheckboxOption } from '@gs-ux-uitoolkit-react/menu';
import { Icon } from '@gs-ux-uitoolkit-react/icon-font';
import PropTypes from 'prop-types';
import translator from '../../../../../services/translator';
import {capitalizeString} from '../../../../../utils/stringUtils';

const { translate: t } = translator;

const DropdownCheckBox = props => {

    const { value = [], config, handleChange } = props;

    const [selectedValue, setSelectedValue] = useState(value);

    const [visible, setVisible] = useState(false);

    const editCapitalizeLabel = capitalizeString(t("tkEdit"));

    const handleSelectChange = newValue => {
        const selected = [...newValue];
        if (selected.includes("Edit") && !selected.includes("View")) {
            selected.push("View")
        }
        handleChange({
            value: selected,
            config,
            errorMessage: false
        });
        setSelectedValue(selected);
    };

    useEffect(() => {
        setSelectedValue(value)
    }, [value])

    const onToggleMenu = () => {
        setVisible(true);
    }

    const hideMenu = () => {
        setVisible(false);
    }


    const id = useMemo(() => {
        return `demo-menu-target-single${Math.floor((Math.random() * 1000000) + 1)}`
    }, []);

    const label = !selectedValue?.length ? capitalizeString(t("tkNo")) : selectedValue?.includes("Edit") ? editCapitalizeLabel : t("tkView")

    return (
        <div className='select-checkbox-menu'>
            <div id={id} onClick={onToggleMenu}>
                <div className='label-container'>
                    <div>{label}</div>
                    <div className={`label-container-icon ${visible ? "open" : "close"}`}> <Icon name="chevron-right" type="filled" /></div>
                </div>
            </div>
            <Menu
                multiple
                value={selectedValue}
                onChange={handleSelectChange}
                visible={visible}
                target={`#${id}`}
                onBlur={hideMenu}
            >
                <MenuCheckboxOption disabled={selectedValue?.includes("Edit")} value="View">{t("tkView")}</MenuCheckboxOption>
                <MenuCheckboxOption value="Edit">{editCapitalizeLabel}</MenuCheckboxOption>
            </Menu>
        </div >
    )
};

DropdownCheckBox.propTypes = {
    handleChange: PropTypes.func,
    config: PropTypes.object,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default DropdownCheckBox;